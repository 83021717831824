// React 
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Nav, Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import SVGsprite from './svgSprite';

//SCSS
import '../../scss/components/main/footer.scss';
import FooterMenu from './menus/FooterMenu'; 
import { isIterableArray } from '../block-slider/utils';
import FooterMenuMobile from './menus/FooterMenuMobile';
import { MenuImages } from './menus/linkUtils';

class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            subscribeStatus: '',
            subscribed: localStorage.getItem('galaxy-subsribed')
        }
        this.FormSubscribe = this.FormSubscribe.bind(this)
        this.changeForm = this.changeForm.bind(this)
        this.submitForm = this.submitForm.bind(this)
        this.emailField = React.createRef();
    }

    changeForm(e) {
        this.setState({
            email: this.emailField.current.value
        })
    }

    submitForm(e) {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_API_BASE}/addsubscriber`, {
            'email': this.state.email
        })
            .then(response => {
                if (response.status === 200) {
                    localStorage.setItem('galaxy-subsribed', true)
                    this.setState({
                        subscribeStatus: 'Вы успешно подписались!',
                        subscribed: localStorage.getItem('galaxy-subsribed')
                    })
                } else {
                    this.setState({
                        subscribeStatus: 'Ошибка! Попробуйте ещё раз'
                    })
                }
            })
            .catch(err => {
                this.setState({
                    subscribeStatus: 'Ошибка! Попробуйте ещё раз'
                })
            })
    }

    FormSubscribe() {
        const footerText = this.props.footerData?.type8
        return (
            <div className='subscribe'>
                <Form.Label>
                    {footerText?.text_under}
                </Form.Label>
                <Form className="subscribe__form" onSubmit={this.submitForm}>
                    <Form.Control
                        type="email"
                        className="form__input"
                        required
                        placeholder={footerText?.text_placeholder}
                        onChange={this.changeForm}
                        value={this.state.value}
                        ref={this.emailField}
                    />
                    <Button variant="primary" type="submit" disabled={this.state.subscribed}>
                        {
                            footerText?.text_button ? 
                            this.state.subscribed ? 'Вы подписаны' : footerText?.text_button
                            :
                            <Spinner animation='border' size={'sm'} />
                        }
                    </Button>
                </Form>
            </div>
        )
    }

    render() {
        return (
            <>
                <footer className='footer'>
                    <Container>


                        <FooterMenu
                            headerData={this.props.headerData}
                            footerData={this.props.footerData}
                            FormSubscribe={this.FormSubscribe}
                        />

                        <FooterMenuMobile
                            footerData={this.props.footerData}
                            headerData={this.props.headerData}
                            FormSubscribe={this.FormSubscribe}
                        />


                        <div className="footer__bottom">

                            <div className="footer__bottom-main">
                                <Link to="/">
                                    <img src="/img/logo.svg" alt="Галактика" />
                                </Link>
                                <p className="copyright">{this.props.footerData?.type8?.text_copyright}</p>
                            </div>

                            <div className="footer__bottom-blocks">
                                {
                                    isIterableArray(this.props.footerData?.type7) &&
                                    <MenuImages items={this.props.footerData?.type7} />
                                }
                            </div>

                        </div>
                    </Container>
                </footer>
                <div id='overlay_main' > {this.props.handleClick}</div >
                <SVGsprite />

                {/* <footer className='footer-desktop mt-5' >
                    <div className="footer-desktop__top">
                        <Container>
                            <div className="flex">
                                <div className="subscribe">
                                    <b>Узнавайте о скидках и новых акциях</b>
                                    <b>{this.state.subscribeStatus}</b>
                                    <Form className="subscribe__form" onSubmit={this.submitForm}>
                                        <Form.Control type="email" className="form__input" required placeholder="Ваш E-mail" onChange={this.changeForm} value={this.state.value} ref={this.emailField} />
                                        <Button variant="primary" type="submit" disabled={this.state.subscribed}>
                                            {this.state.subscribed ? 'Вы подписаны' : 'Подписаться'}
                                        </Button>
                                    </Form>
                                    <div className="social">
                                        <p>Мы в социальных сетях:</p>
                                        <div className="social-list">
                                            <div className="social-list__item">
                                                <Nav.Link href="https://www.instagram.com/galaktika_official_/" target="_blank">
                                                    <img src="/img/i-f-insta.svg" alt="Instagram" />
                                                </Nav.Link>
                                            </div>
                                            <div className="social-list__item">
                                                <Nav.Link href="https://www.youtube.com/channel/UCYe5DCU2M-joE39Zg_QzKog" target="_blank">
                                                    <img src="/img/i-f-youtube.svg" alt="YouTube" />
                                                </Nav.Link>
                                            </div>
                                            <div className="social-list__item">
                                                <Nav.Link href="https://vk.com/galaktika_official" target="_blank">
                                                    <img src="/img/i-f-vk.svg" alt="VKontakte" />
                                                </Nav.Link>
                                            </div>
                                            <div className="social-list__item">
                                                <Nav.Link href="https://www.facebook.com/galaktika.official/" target="_blank">
                                                    <img src="/img/i-f-fb.svg" alt="fb" />
                                                </Nav.Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Container>
                    </div>
                    <div className="footer-desktop__bottom">

                        <Container className="flex">
                            <div className="footer-main">
                                <Link to="/">
                                    <img src="/img/logo-f.svg" alt="Галактика" />
                                </Link>
                                <p className="copyright">© Сеть торговых центров «Галактика»  2015–{(new Date().getFullYear())}. Все права защищены.</p>
                            </div> 
                        </Container>

                    </div>
                </footer> */}
            </>
        )
    }
}

export default Footer;
