import React, { useState, useRef, useEffect } from "react";

import "@aarsteinmedia/dotlottie-player";


const BlocksInfo = ({ info, block_info }) => {

  const animation = block_info?.animation ? JSON.parse(block_info?.animation) : false


  const playerRef = useRef(null)
  const [animationURL, setAnimationURL] = useState(null);

  useEffect(() => {
    if (animation) handleLottieAnimationLoad()
  }, [animation]);

  const handleLottieAnimationLoad = () => {
    const lottieFileURL = animation?.url ? `${'/blocksnew/'}${animation.url}` : null;
    setAnimationURL(lottieFileURL);
  }

  useEffect(() => {
    if (playerRef.current && animationURL) {
      waitForLottieAnimationLoaded().catch((err) => {
        console.log('lottie err', err)
        setAnimationURL(null)
      })
    }
  }, [playerRef.current, animationURL]);

  const waitForLottieAnimationLoaded = () => {
    return new Promise(() => {
      if (!playerRef?.current?.getLottie()) {
        throw new Error('animation error');
      }
    });
  }



  return (
    info ?
      <div
        className="blocks-slider__info"
        style={{
          backgroundColor: block_info.plashka_background
        }}
      >
        {
          // block_info.gif &&
          block_info.gif &&
          <div className="blocks-slider-gif" style={{
            backgroundImage: `url(/blocksnew/${block_info.gif})`
            // backgroundImage: `url(${process.env.REACT_APP_BACKEND}${url_gif}${block_info.gif}})`
          }} />
        }
        <div
          className="blocks-wrapper__animation-layer"
          style={{ zIndex: 2 }}
        >
          {
            animation &&
            <dotlottie-player
              ref={playerRef}
              src={animationURL}
              background="transparent"
              speed={animation.speed}
              direction="1"
              mode="normal"
              loop
              autoplay
            ></dotlottie-player>
          }
        </div>
        <div className="blocks__countdown-content">
          <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: info.text }}></div>
        </div>
      </div>
      :
      ''
  );
};

export default BlocksInfo;
