import React from 'react'
import { isIterableArray } from '../../../block-slider/utils'
import CharListCommon from '../CharListCommon'

const CatalogChartListDesktop = ({ character, handleSelectChar, isLoadingCard, productId }) => {
    return (
        <div className='char-list catalog-desktop'>
            {
                isIterableArray(character) && character.map((char_el, key) => (
                    <CharListCommon
                        handleSelectChar={handleSelectChar}
                        isLoadingCard={isLoadingCard}
                        key={key}
                        char_el={char_el}
                        productId={productId}
                        offsetForHide={45}
                    />
                ))
            }
        </div>
    )
}

export default CatalogChartListDesktop
