import React, { useState, useRef, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { isIterableArray } from '../../block-slider/utils';
import fontColorContrast from 'font-color-contrast';


// offsetForHide - допустимый отспут элемента от верхнего края родителя
// элементы которые находятся ниже 45px - будут скрыты кнопкой ЕЩЕ
// 45px = 2 строки, 65px = 3 строки

const CharListCommon = ({ char_el, openInfoModal, handleSelectChar, isLoadingCard, offsetForHide = 45, productId = 'id' }) => {

    const blocksContainerRef = useRef(null)

    const [isShowMoreButton, setIsShowMoreButton] = useState(true);
    const [isShowAll, setIsShowAll] = useState(true);

    const [lastSelectedIndex, setLastSelectedIndex] = useState(false);

    useEffect(() => {
        if (isIterableArray(char_el.value_arr)) {

            // const selectedIndex = char_el.value_arr.findIndex(item => item.selected === true)
            const selectedElement = document.querySelector(`.char_${productId}_${char_el.filter_id} .selected`)

            if (selectedElement) {
                if (selectedElement.offsetTop > offsetForHide || selectedElement.offsetTop === 0) {
                    setIsShowAll(true)
                    // console.log('open from here', selectedElement)
                }
                else {
                    setIsShowAll(false)
                    // console.log('element not open', selectedElement)
                }
            } else setIsShowAll(false)

        }
    }, [char_el?.value_arr, offsetForHide]);


    useEffect(() => {
        if (blocksContainerRef.current) {

            const ch_items = blocksContainerRef.current.querySelectorAll('.char-list__blocks__item')
            if (isIterableArray(Array.from(ch_items))) {

                const ch_items_arr = Array.from(ch_items)
                const offset_arr = ch_items_arr.map(it => {
                    return it.offsetTop
                })
                const ii = offset_arr?.findIndex(o_index => o_index === offset_arr?.filter(it => it > offsetForHide)?.[0])

                let selectedIndex = ii


                if (selectedIndex > 0) {
                    setIsShowMoreButton(true)
                    setLastSelectedIndex(selectedIndex)
                }
                else {

                    if (offset_arr.findIndex(item => item === 0)) {
                        selectedIndex = offset_arr.findIndex(item => item === 0)
                    }

                    setLastSelectedIndex(selectedIndex)

                    if (selectedIndex > 0) {
                        // console.log('selected index > 0, not open', ch_items_arr)
                        setIsShowMoreButton(true)
                    }
                    else {

                        // console.log('element open from here', ch_items_arr)
                        setIsShowAll(true)
                        setIsShowMoreButton(false)
                    }
                }
            }

        }
    }, [blocksContainerRef.current, offsetForHide]);

    const getSelectedTitle = (char_el) => {
        const sel = char_el.value_arr.find(item => item.selected)
        if (sel) {
            return <>: <span> {sel?.title}</span></>
        }
        else return ''
    }

    return (
        <div className='char-list-container'>
            <div className='char-list__title'>
                {char_el.name}{getSelectedTitle(char_el)}
            </div>

            <div className={`
            overflow-visible
            char-list__blocks char_${productId}_${char_el.filter_id} 
            ${(isShowAll && isShowMoreButton) ? 'show' : 'unshow'}
            ${(!isShowAll && !isShowMoreButton) ? 'linear-items' : ''}
            ${(isShowAll) ? 'show' : ''}
            `}
                ref={blocksContainerRef}>
                {
                    isIterableArray(char_el.value_arr) && char_el.value_arr.map((item, index) => (
                        <Button
                            className={`char-list__blocks__item  
                                ${(lastSelectedIndex > 0 && lastSelectedIndex - 1 < index) ? 'is_show_more' : ''}
                                ${(lastSelectedIndex - 1 === index && !isShowAll) ? 'show_more_btn' : ''}
                                ${!item.available ? 'unavailable' : ''}  
                            ${item.selected ? 'selected' : ''}
                            
                            `}
                            key={index}
                            disabled={item.selected && !(lastSelectedIndex - 1 === index && !isShowAll)}
                            variant='simple'
                            title={(lastSelectedIndex - 1 === index && !isShowAll) ? '' : item.title}
                            onClick={() => {
                                if ((lastSelectedIndex > 0 && lastSelectedIndex - 1 === index && !isShowAll)) {
                                    setIsShowAll(true)
                                }
                                else {
                                    handleSelectChar({
                                        filter_id: char_el.filter_id,
                                        filter_option_id: item.filter_option_id,
                                        title: item.title
                                    })
                                }
                            }
                            }

                            style={{
                                transition: 'opacity .4s ease',
                                maxWidth: item?.text?.length * 8 + 10,
                                minWidth: 27,
                                opacity: isLoadingCard ? 0.75 : 1,
                                // pointerEvents: isLoadingCard ? 'none' : 'auto'
                            }}
                        >
                            {
                                (lastSelectedIndex - 1 === index && !isShowAll) ?
                                    <>
                                        <b> → </b> Ещё ({char_el.value_arr.length - lastSelectedIndex + 1})
                                    </>
                                    :
                                    <>
                                        {item.text}
                                        {
                                            isIterableArray(item.badges) &&
                                            item.badges.map((badge, index) => (
                                                <div
                                                    key={index}
                                                    className='badge'
                                                    style={{
                                                        top: index * 4 - 4,
                                                        zIndex: index + 1,
                                                        backgroundColor: badge.color,
                                                        color: fontColorContrast(badge.color)
                                                    }}>
                                                    {badge.text}
                                                </div>
                                            ))
                                        }
                                    </>
                            }
                        </Button>
                    ))
                }

            </div>
            {
                isShowMoreButton && isShowAll &&
                <div
                    onClick={() => {
                        setIsShowAll(false)
                        setIsShowMoreButton(true)
                    }}
                    className={`char-list__blocksBtn ${isShowAll ? 'show' : ''}`}
                >
                    <b> ↑ </b>&nbsp;  Свернуть
                </div>
            }

            {
                (char_el?.modal?.title && openInfoModal) ?
                    <div className='modal-link' onClick={() => openInfoModal(char_el.modal)}
                        dangerouslySetInnerHTML={{ __html: char_el.modal.title }}
                    >

                    </div>
                    :
                    ''
            }
        </div>
    )
}

export default CharListCommon
