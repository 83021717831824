import React from 'react'
import { Col, Row } from 'react-bootstrap'

// тип 1
// 2 фото
const Type3 = ({ data }) => {
    const imageAside = data.images[1]
    const imageTop = data.images[0]
    return (
        <div className='buildedPage-type_3'>
            <Row>
                <Col md={6} lg={5}>
                    <div className='image-aside'>
                        <img src={process.env.REACT_APP_BACKEND + '/uploads/pages/blocks/' + imageAside.src} alt={imageAside.name} />
                    </div>
                </Col>
                <Col md={6} lg={7}>
                    <img src={process.env.REACT_APP_BACKEND + '/uploads/pages/blocks/' + imageTop.src} alt={imageTop.name} />

                    {data.title && <div className='title' dangerouslySetInnerHTML={{ __html: data.title }} />}
                    {data.text && <div className='text' dangerouslySetInnerHTML={{ __html: data.text }} />}
                </Col>
            </Row >

        </div>
    )
}

export default Type3
