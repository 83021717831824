import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap';
import Slider from 'react-slick';
import { isIterableArray } from '../../../block-slider/utils';

import fontColorContrast from 'font-color-contrast';


const ProductColorChar = ({ character, handleSelectChar, isLoadingCard }) => {

    const blocksContainerRef = useRef(null)


    const [isShowMoreButton, setIsShowMoreButton] = useState(false);
    const [isShowAll, setIsShowAll] = useState(false);
    const [showMoreValue, setShowMoreValue] = useState(0);

    useEffect(() => {
        if (isIterableArray(character?.value_arr)) {
            const selectedIndex = character.value_arr.findIndex(item => item.selected === true)

            const containerWidth = document.querySelector('.js-container-width').clientWidth

            const itemWidth = character.colorType === 'image' ? 74 : 42

            const countInRow = Math.floor(containerWidth / itemWidth)

            // console.log('количество элементов в строке помещается', countInRow)

            if (selectedIndex > countInRow - 1) {
                setIsShowAll(true)
                // console.log('выбранный цвет находится в скрытом, нужно открыть все цвета вручную')
            }
            // else {
            // console.log('нет выбранного или выбранный находится в видимом и не нужно открывать')
            // }

            // проверка если элементов больше чем может поместиться в 2 строки
            // тогда включаем кнопку Показать/Скрыть
            if (character.value_arr.length > countInRow * 2) {
                setIsShowMoreButton(true)
                const showMoreV = character.value_arr.length - countInRow * 2
                setShowMoreValue(showMoreV)
            }
            else {
                setIsShowMoreButton(false)
            }

        }
    }, [character?.value_arr]);


    const getColorStyle = (color_value, colorType) => {

        const imageStyles = { backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }

        const backgroundImage =
            colorType === 'color' ?
                `url(${process.env.REACT_APP_BACKEND + 'uploads/products/categories_filters/' + color_value.image_url})`
                :
                colorType === 'image' ?
                    `url(${process.env.REACT_APP_BACKEND + 'uploads/products/main/' + color_value.image_url})`
                    :
                    'none'

        const borderStyles = colorType === 'image' ?
            {
                border: '1px solid #acacac'
            }
            :
            {
                border: '1px solid',
                borderColor: color_value.border
            }




        if (color_value.multicolor) return ({ backgroundImage: 'url(/img/multicolor.png)', ...imageStyles, ...borderStyles })
        if (color_value.nocolor) return ({ backgroundImage: 'url(/img/nocolor.jpg)', ...imageStyles, ...borderStyles })
        if (color_value.gradient) return ({ backgroundImage: color_value.gradient, ...borderStyles })

        if (color_value.image_url) return ({ backgroundImage: backgroundImage, ...imageStyles, ...borderStyles })

        return ({ backgroundColor: color_value.color, ...borderStyles })
    }



    // если картинка и страница товара - ширина 60, иначе меньше 
    const getButtonSize = () => (
        character.colorType === 'image' ?
            {
                width: 70,
                height: 62,
                containerMaxHeight: 133
            }
            :
            {
                width: 38,
                height: 30,
                containerMaxHeight: 70
            }
    )

    const getSelectedTitle = (character) => {
        const sel = character.value_arr.find(item => item.selected)
        if (sel) {
            return (
                <>
                    :
                    <div
                        className='color-preview'
                        style={{
                            width: '1.2rem',
                            height: '1.2rem',
                            borderRadius: 4,
                            ...getColorStyle(sel, character.colorType)
                        }} />
                    &nbsp;
                    <span>{sel?.title}</span>
                </>
            )
            // if (character.colorType === 'image') {
            //     const backgroundImage = `${process.env.REACT_APP_BACKEND + 'uploads/products/main/' + sel.image_url}`
            //     return (
            //         <>
            //             :
            //             {
            //                 backgroundImage && <img alt={sel.title} src={backgroundImage} />
            //             }
            //             &nbsp;
            //             <span>{sel?.title}</span>
            //         </>
            //     )
            // }
            // else {
            //     return (
            //         <>
            //             :
            //             <div
            //                 className='color-preview'
            //                 style={{
            //                     backgroundColor: sel.color,
            //                     border: '1px solid',
            //                     borderColor: sel.border,
            //                     ...getColorStyle(sel, character.colorType)
            //                 }} />
            //             &nbsp;
            //             <span>{sel?.title}</span>
            //         </>
            //     )
            // }
        }
        else return ''
    }
    return (
        character ?
            <div className='char-list product'>
                <div className='char-list__title'>
                    {character.name}{getSelectedTitle(character)}
                </div>
                {/* <Slider {...sliderSettings}> */}

                <div
                    ref={blocksContainerRef}
                    className={`js-container-width char-list__blocks ${isShowAll ? 'show' : ''}`}
                    style={{
                        maxHeight: getButtonSize().containerMaxHeight
                    }}
                >
                    {
                        isIterableArray(character.value_arr) && character.value_arr.map((item, index) => (
                            <div key={index} style={{
                                width: getButtonSize().width,
                                order: index + 1
                            }}
                                className={`type-${character.colorType}-col`}
                            >
                                {
                                    <Button
                                        variant='simple'
                                        className={`char-list__item-color type-${character.colorType}  ${!item.available ? 'unavailable' : ''} ${item.selected ? 'selected' : ''} `}
                                        title={item.title}
                                        disabled={item.selected}
                                        onClick={() => handleSelectChar({
                                            filter_id: character.filter_id,
                                            filter_option_id: item.filter_option_id,
                                            title: item.title
                                        })}
                                        // onClick={() => handleSelectChar({ [`filter_${character.filter_id}`]: item.filter_option_id })}
                                        style={{
                                            // width: 27,
                                            width: '100%',
                                            height: getButtonSize().height,
                                            // marginRight: 5,
                                            borderRadius: 4,
                                            transition: 'opacity .4s ease',
                                            opacity: isLoadingCard ? 0.5 : 1,
                                            pointerEvents: isLoadingCard ? 'none' : 'auto',

                                            ...getColorStyle(item, character.colorType)
                                        }}
                                    >
                                        {
                                            isIterableArray(item.badges) &&
                                            item.badges.map((badge, index) => (
                                                <div
                                                    key={index}
                                                    className='badge'
                                                    style={{
                                                        top: index * 4 - 4,
                                                        zIndex: index + 1,
                                                        backgroundColor: badge.color,
                                                        color: fontColorContrast(badge.color)
                                                    }}>
                                                    {badge.text}
                                                </div>
                                            ))
                                        }
                                    </Button>

                                }


                            </div>
                        ))
                    }


                    {
                        isShowMoreButton &&
                        <div
                            onClick={() => {
                                setIsShowAll(prev => !prev)
                            }}
                            className={`char-list__blocks-btn-color type-${character.colorType}-col ${isShowAll ? 'show' : ''}`}
                            style={{
                                order: character.colorType === 'color' ? character.value_arr.length - showMoreValue - 2 : character.value_arr.length - showMoreValue - 1,
                                width: getButtonSize().width,
                                height: getButtonSize().height,
                            }}
                        >
                            {
                                isShowAll ?
                                    <><b> ↑ </b>&nbsp; Свернуть</> :
                                    <><b> → </b>&nbsp; Ещё ({showMoreValue})</>

                            }
                        </div>
                    }
                </div>
                {/* </Slider > */}
            </div>
            :
            ''
    )
}

export default ProductColorChar