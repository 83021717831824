// React
import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";

//SCSS
import '../../scss/components/main/mainCatalog.scss';
import '../../img/call-byttech.svg';
import { Button } from 'react-bootstrap';
import axios from "axios";
// import axios from "axios";
// import dekoL from "../../img/temp/deko.png";
// import gcL from "../../img/temp/gc.png";

class HeaderCatalogMobile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      windowHeight: window.innerHeight,
      menuToggleOn: false,
      gc: false,
      items: [],
      stock: []
    };
    this.toggleClass = this.toggleClass.bind(this);
    this.closeMenu = this.closeMenu.bind(this)
  }

  state = {
    active: false, menuToggleOn: false
  }
  toggleClass = (id) => {
    // if (id === 29338 || id === 29272) {
    //   this.setState({ gc: true });
    //   this.closeMenu();
    // } else {
    this.setState({ selectedItemIndex: id });
    //document.body.classList.add('bfix');
    this.setState(prevState => ({
      menuToggleOn: !prevState.menuToggleOn
    }));
    //this.closeMenu();
    // }
  };

  closeMenu() {
    document.getElementById('menu-btn').click();
    document.body.classList.remove('bfix');
  }


  componentWillMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.setState({ windowHeight: window.innerHeight })
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_BASE}/new-stocks/stocks-in-sidebar`)
      .then(response => {
        const data = response.data.data;
        this.setState({
          stock: data
        }, () => {
          // console.log(this.state);
        })
      })
      .catch(error => {
        console.error(error);
      })
  }

  render() {
    return (
      <>
        {/* {this.state.gc && <Redirect to={'/catalog/Podarochnye-karty--29338'} />} */}
        <ul className={`menu-categories ${this.state.menuToggleOn ? 'move' : ""}`}>
          {this.state.stock.length > 0 &&
            <li
              className={`menu-categories__item ${this.state.selectedItemIndex === 'stock' ? 'menu-categories__item_state_hovered' : ""}`}
              onClick={() => this.toggleClass('stock')}>
              <Link className="menu-categories__link" to={`/promo`}>
                <span className="menu-categories__icon">
                  <img
                    src={`${process.env.REACT_APP_BACKEND}/site_image/categories_icons/cat-discount.svg`}
                    alt='' />
                </span>
                <span className="menu-categories__name">Акции</span>
              </Link>
              <div className="menu__hidden-content" style={{ height: this.state.windowHeight }}>
                <div className="menu__hidden-content__header">
                  <Button variant="link" className="hCatalog_m_toggle"
                    onClick={this.menuToggleOn}><i
                      className="i-arrow"></i>Акции</Button>
                </div>
                <div className="menu__main-cats">
                  <div className="menu__main-cats-inner">
                    <div className="menu__hidden-column">
                      <ul className="menu__hidden-list">
                        <li>
                          <Link className="menu__hidden-title font-weight-bold"
                            to={`/promo`}>
                            <span onClick={this.closeMenu}>
                              Все акции
                            </span>
                          </Link>
                        </li>
                        {this.state.stock.map((subcat, index) => (
                          <li key={index}>
                            <span onClick={this.closeMenu}>
                              <Link className="menu__hidden-title"
                                to={`/promo/${subcat.name_slug}`}>{subcat.name}</Link>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          }
          {this.props.items.map((cat, index) => (
            <React.Fragment key={index}>
              {cat.categoryData.redirect === null &&
                (
                  cat.categoryData?.link?.url ?

                    <li
                      key={index}
                      className={`menu-categories__item ${this.state.selectedItemIndex === cat.categoryData.id ? 'menu-categories__item_state_hovered' : ""}`}
                    >
                      <Link
                        to={{ pathname: cat.categoryData?.link?.url }}
                        target="_blank"
                        className={`menu-categories__link ${cat.categoryData.border ? 'deko' : ''}`}
                        style={{
                          pointerEvents: 'auto'
                        }}
                      >
                        <span className="menu-categories__icon">
                          <img alt=''
                            src={`${process.env.REACT_APP_BACKEND}/site_image/categories_icons/${cat.categoryData.icon_url}`}
                          />
                        </span>
                        <span className="menu-categories__name">{cat.categoryData.name}</span>
                      </Link>
                    </li>
                    :
                    <li
                      key={index}
                      onClick={() => this.toggleClass(cat.categoryData.id)}
                      className={`menu-categories__item ${this.state.selectedItemIndex === cat.categoryData.id ? 'menu-categories__item_state_hovered' : ""}`}
                    >
                      <a href="!" className={`menu-categories__link ${cat.categoryData.border ? 'deko' : ''}   `}>
                        <span className="menu-categories__icon">
                          <img alt=''
                            src={`${process.env.REACT_APP_BACKEND}/site_image/categories_icons/${cat.categoryData.icon_url}`}
                          />
                        </span>
                        <span className="menu-categories__name">{cat.categoryData.name}</span>
                      </a>
                      {/* <a href="!" className={`menu-categories__link ${cat.categoryData.border ? 'deko' : ''}   `}>
                      <span className="menu-categories__icon">
                        <img alt=''
                          src={`${process.env.REACT_APP_BACKEND}/site_image/categories_icons/${cat.categoryData.icon_url}`}
                        />
                      </span>
                      <span className="menu-categories__name">{cat.categoryData.name}</span>
                    </a> */}
                      {cat.subCategories && (
                        <div className="menu__hidden-content" style={{ height: this.state.windowHeight }}>
                          <div className="menu__hidden-content__header">
                            <Button variant="link" className="hCatalog_m_toggle"
                              onClick={this.menuToggleOn}><i
                                className="i-arrow"></i>{cat.categoryData.name}</Button>
                          </div>
                          <div className="menu__main-cats">
                            <div className="menu__main-cats-inner">
                              <div className="menu__hidden-column">
                                <ul className="menu__hidden-list">
                                  {cat.subCategories.map((subcat, index) => (
                                    <React.Fragment key={index}>
                                      {subcat.subCategories.redirect === null &&
                                        <li key={index}>
                                          <Link key={index} className="menu__hidden-title"
                                            to={`/${subcat.subSubCategories.length === 0 ? 'catalog' : 'category'}/${subcat.subCategories.name_slug}`}
                                            onClick={this.closeMenu}>{subcat.subCategories.name}</Link>
                                          {/* {subcat.subSubCategories.length && (
                                                              <ul>
                                                                  {subcat.subSubCategories.map((subChild, index) => (
                                                                  <li key={index}><Link className="menu__link" to={`/catalog/${subChild.name_slug}`}
                                                                                        onClick={this.closeMenu}> {subChild.name} </Link></li>
                                                                  ))}
                                                              </ul>
                                                          )} */}
                                        </li>
                                      }
                                    </React.Fragment>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                )
              }
            </React.Fragment>
          ))}

          <li className="menu-categories__item" onClick={this.closeMenu}>
            &nbsp;
          </li>
          {/*<li className="menu-categories__item" style={{display: "none"}}>*/}
          {/*    <a className="menu-categories__link deko" href="/">*/}
          {/*        <span className="menu-categories__icon">*/}
          {/*            <img src={dekoL} alt="" />*/}
          {/*        </span>*/}
          {/*        <span className="menu-categories__name">Посуда Текстиль Подарки</span>*/}
          {/*    </a>*/}
          {/*</li>*/}
          {/*<li className="menu-categories__item" style={{display: "none"}}>*/}
          {/*    <a className="menu-categories__link gc" href="/">*/}
          {/*        <span className="menu-categories__icon">*/}
          {/*            <img src={gcL} alt="" />*/}
          {/*        </span>*/}
          {/*        <span className="menu-categories__name">Подарочные карты</span>*/}
          {/*    </a>*/}
          {/*</li>*/}
        </ul>
      </>
    )
  }
}

export default HeaderCatalogMobile;
