import fontColorContrast from 'font-color-contrast'
import React from 'react'
import { isIterableArray } from '../../block-slider/utils'
import { LinkWrapper } from './Type4'

// тип 5 
// карточки
const Type5 = ({ data }) => {
    return (
        <div className='buildedPage-type_5'>
            {data.title && <div className='title-block' dangerouslySetInnerHTML={{ __html: data.title }} />}
            <div className={`buildedPage-type_5__row perRow-${data.setting.itemsPerRow}`}>
                {
                    isIterableArray(data.items) && data.items.map((item, key) => (
                        <div className='card-item' key={key}>
                            <LinkWrapper link={item.link}>
                                <div className='card-item-inner'>
                                    <img src={process.env.REACT_APP_BACKEND + '/uploads/pages/blocks/' + item.src} alt={item.name} className={item.isFluidImage == 'true' ? 'isFluidImage' : ''} />
                                    <div className='card-item-content'>
                                        <div className='title' dangerouslySetInnerHTML={{ __html: item.title }} />
                                        <div className='text' dangerouslySetInnerHTML={{ __html: item.text }}
                                            style={{
                                                maxHeight: 15 * 1.5 * (item.link.type != 'false' ? 5 : 7),
                                                minHeight: 15 * 1.5 * (item.link.type != 'false' ? 5 : 7)
                                            }}
                                        />

                                        {/* <CardButton item={item} /> */}

                                        {
                                            item.link.type != 'false' &&
                                            <div
                                                className='btn-link'
                                                style={{
                                                    border: `2px solid ${item.button_color}`,
                                                    backgroundColor: item.button_color,
                                                    color: fontColorContrast(item.button_color)
                                                }}

                                            >
                                                <div style={{ color: item.button_color }} dangerouslySetInnerHTML={{ __html: item.button_text }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </LinkWrapper>
                        </div>
                    ))
                }
            </div>
            {data.text && <div className='text-block' dangerouslySetInnerHTML={{ __html: data.text }} />}
        </div >
    )
}

export default Type5
