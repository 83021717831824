import React, { useContext } from 'react'
import CartCtx from '../../CartCtx';

const SelectButton = ({ contact, handleOpenMainModal, onClick }) => {

    const { selectedCenter, tcData } = useContext(CartCtx);

    return (
        <div className='center-select'>
            {
                tcData &&
                <div className='center-select__title' onClick={() => {
                    handleOpenMainModal()
                    if (onClick) onClick()
                }}>
                    <i className='i-map' />
                    <span>
                        {
                            selectedCenter ?
                                selectedCenter.address
                                :
                                "Выбрать ТЦ"
                        }
                    </span>
                </div>
            }

            {
                contact &&
                <div className='header-top-menu__contact'>

                    <div className="header-menu__contact flex">


                        <div className="contact-popup">
                            <div className="contact-popup__btn">
                                {
                                    contact?.icon &&
                                    <img
                                        style={{
                                            width: 14,
                                            height: 14
                                        }}
                                        className="header-top-menu__icon mr-3"
                                        src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + contact.icon}
                                        alt=''
                                    />
                                }
                                {
                                    selectedCenter ?
                                        selectedCenter.phone
                                        :
                                        <div dangerouslySetInnerHTML={{ __html: contact?.name }} />
                                }

                                <svg className='arrow-icon' width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.1334 -5.24537e-08L6.33337 4.8L1.53337 -4.72083e-07L0.333373 1.2L6.33337 7.2L12.3334 1.2L11.1334 -5.24537e-08Z" />
                                </svg>
                            </div>
                            <div className="contact-popup__wrap">
                                <div className="contact-popup__inner">

                                    <ul className="contact-list">
                                        {
                                            contact.subItems.map(item => (
                                                item?.phones?.length > 3 ?
                                                    <li key={item.id} className="item flex">
                                                        <p className="pr-2 mb-0" dangerouslySetInnerHTML={{ __html: item.name }} />

                                                        <a href={`tel:${item.phones}`}>
                                                            {item.phones}
                                                        </a>
                                                    </li>
                                                    :
                                                    <span key={item.id}></span>
                                            ))
                                        }
                                    </ul>

                                    <div className="worktime">
                                        <div className="worktime__wrap">
                                            <div dangerouslySetInnerHTML={{ __html: contact.text }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default SelectButton
