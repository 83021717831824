import React, { useEffect, useRef, useState } from 'react'
import "@aarsteinmedia/dotlottie-player";

const AnimationContainer = (props) => {

    const { animation_background } = props

    const playerRef = useRef(null)
    const [animationURL, setAnimationURL] = useState(null);

    useEffect(() => {
        if (animation_background) handleLottieAnimationLoad()
    }, [animation_background]);

    const handleLottieAnimationLoad = () => {
        const lottieFileURL = animation_background ? `${'/stocks-lottie/'}${animation_background.url}` : null;
        setAnimationURL(lottieFileURL);
    }
    // console.log(animation_background)

    // useEffect(() => {
    //     if (playerRef.current && animationURL) {
    //         waitForLottieAnimationLoaded().catch((err) => {
    //             console.log('lottie err', err)
    //             setAnimationURL(null)
    //         })
    //     }
    // }, [playerRef.current, animationURL]);

    // const waitForLottieAnimationLoaded = () => {
    //     return new Promise(() => {
    //         if (!playerRef?.current?.getLottie()) {
    //             throw new Error('animation error');
    //         }
    //     });
    // }

    return (
        <div>
            <div className="body__animation-layer">
            {/* {'qwewqe'} */}
                {
                    animation_background &&
                    <dotlottie-player
                        ref={playerRef}
                        src={`/stocks-lottie/${animation_background.url}`}
                        // src={'https://admin.galaktika.me/uploads/cloud/1711647073351.lottie'}
                        // src={animationURL}
                        // src={'https://lottie.host/39c4ed1b-7fba-4f28-a17e-34849e6d3220/9swKP3RD0U.json'}
                        background="transparent"
                        speed={animation_background.speed}
                        // speed={.75}
                        direction="1"
                        mode="normal"
                        loop
                        autoplay
                    ></dotlottie-player>
                }
            </div>
            {props.children ? props.children : ''}
        </div>
    )
}

export default AnimationContainer
