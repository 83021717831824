import { Link } from "react-router-dom";
import CartCtx from "../../CartCtx";
import React, { useEffect, useState, useRef, useContext } from "react";
import Badges from "../../main/Badges";
import { Button } from "react-bootstrap";
import "@aarsteinmedia/dotlottie-player";
import axios from "axios";
import CatalogColorChar from "../../categories/character/catalog/CatalogColorChar";
import WishList from "../../main/ModalWishlist";


import logo from '../../../img/logo-grey.svg'
import { isIterableArray } from "../utils";

const ProductItem = ({ product, isProductsHasVariable, animation_product }) => {

  const { selectedCenter } = useContext(CartCtx);
  const playerRef = useRef(null)
  const [animationURL, setAnimationURL] = useState(null);

  useEffect(() => {
    if (animation_product) handleLottieAnimationLoad()
  }, [animation_product]);

  const handleLottieAnimationLoad = () => {
    const lottieFileURL = animation_product ? `${'/blocksnew/'}${animation_product.url}` : null;
    setAnimationURL(lottieFileURL);
  }

  useEffect(() => {
    if (playerRef.current && animationURL) {
      waitForLottieAnimationLoaded().catch((err) => {
        console.log('lottie err', err)
        setAnimationURL(null)
      })
    }
  }, [playerRef.current, animationURL]);

  const waitForLottieAnimationLoaded = () => {
    return new Promise(() => {
      if (!playerRef?.current?.getLottie()) {
        throw new Error('animation error');
      }
    });
  }

  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [productState, setProductState] = useState(product);
  const [productPrice, setProductPrice] = useState({
    price: product.price,
    priceBadgeStock: product.priceBadgeStock
  }); // цена у выбранного тц

  const [productStatus, setProductStatus] = useState(null);


  // если выбран тц - меняем цену 
  useEffect(() => {
    if (selectedCenter?.id) {
      if (isIterableArray(productState.tc)) {

        const selectedPrice = productState.tc.find(item => item.id == selectedCenter.id)

        const selectedStatus = isIterableArray(productState.status) ? productState.status.find(item => item.tc_id == selectedCenter.id) : false
        if (selectedStatus?.tc_id) setProductStatus(selectedStatus)
        else setProductStatus(null)

        setProductPrice({
          price: selectedPrice.price,
          priceBadgeStock: selectedPrice.priceBadgeStock
        })
      }
    }
    else if (productState) { 
      setProductPrice({
        price: productState.price,
        priceBadgeStock: productState.priceBadgeStock
      })
      const selectedStatus = isIterableArray(productState.status) ? productState.status.find(item => item.defaultTc === true) : false
      if (selectedStatus?.tc_id) setProductStatus(selectedStatus)
      else setProductStatus(null)
    }
  }, [selectedCenter?.id, productState]);

  const handleFetchNewChar = (filterSelectedLast) => {
    setIsLoadingCard(true)
    setProductState(prev => {
      return {
        ...prev, photo_url: ''
      }
    })

    const selectedCenterId = selectedCenter ? selectedCenter.id : false

    let postData = {
      filterSelectedLast: filterSelectedLast,
      filtersSelected: [],
      product_id: productState.id,
      tc_id: selectedCenterId
    };
    // console.log('product filter postData', postData, selectedChar)
    axios.post(`${process.env.REACT_APP_API_BASE}/pages/product-filter`, postData)
      .then((response) => {
        console.log(response.status, response)

        if (response.status === 200) {
          if (response.data.data) {


            // const selectedDefaultChar = getChars(response.data.data.char)
            // const selectedDefaultColor = getChars(response.data.data.color)

            // const newChars = [...selectedDefaultChar, ...selectedDefaultColor]

            // saveToStorage(newChars, productState.id, response.data.data.id, response.data.data.name_slug, response.data.data.category_id)

            // setSelectedChar({
            //     product_slug: response.data.data.name_slug,
            //     slug: response.data.data.category_id,
            //     product_id: response.data.data.id,
            //     chars: newChars
            // })
            setProductState(prev => {
              return {
                ...prev, ...response.data.data
              }
            })
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => {
        setIsLoadingCard(false)
      })
    // setProductState(prev => ({ ...prev, ...{ updated: 1 } }))
  }


  const handleSelectChar = new_v => {
    handleFetchNewChar(new_v)
  }



  return (
    <div className="products-dropdown__item" style={{ width: "100%" }}>
      <div
        className="blocks-wrapper__animation-layer"
        style={{ zIndex: 4 }}
      >
        {
          animation_product &&
          <dotlottie-player
            ref={playerRef}
            src={animationURL}
            background="transparent"
            speed={animation_product?.speed || 1}
            direction="1"
            mode="normal"
            loop
            autoplay
          ></dotlottie-player>
        }
      </div>
      <div className="products__wrap">
        <div className="btn-toolbar">
          <WishList productId={productState.id} productCount={productState.count} />
        </div>
        <div className="products-image">
          {
            !isLoadingCard ?
              <Link
                className="products-image__link"
                to={`/product/${productState.name_slug}`}
              >
                <img
                  src={
                    process.env.REACT_APP_BACKEND +
                    "uploads/products/main/" +
                    productState.photo_url
                  }
                  alt={"Изображение товара " + productState.name}
                />
              </Link>
              :
              <div className='logo-loader'>
                <img src={logo} alt="" />
              </div>
          }

        </div>

        <CatalogColorChar
          character={productState.color}
          handleSelectChar={handleSelectChar}
          isLoadingCard={isLoadingCard}
          isProductsHasVariable={isProductsHasVariable}
        />
        {/* <div className="products-colors">
          <div className={"products-colors__wrap " + product.colors}></div>
        </div> */}

        <Link
          className="products-title"
          to={`/product/${productState.name_slug}`}
          title={productState.name}
        >
          {productState.name}
        </Link>

        {productState.gift && (
          <Link to={`/product/${productState.gift.name_slug}`} className="products-gift contents"
            style={{
              color: '#000',
              textDecoration: 'none',
              background: 'rgba(255,255,255,.5)',
              backdropFilter: 'blur(20px)',
              top: '156px !important'
            }}
          >
            <span className="f7 fw500">Подарок</span>
            <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + productState.gift.photo_url} alt="" className="img" />
          </Link>
        )}
        <div
          className={
            "products-price " + productState.sale + " " + productState.stockStatus
          }
        >
          <div className="producst-price__wrap">
            <div className="products-price__old">
              {productPrice.priceBadgeStock.price.discountPrice && (
                <span className="price i-rub d-inline-block">
                  {productPrice.priceBadgeStock.price.price.toLocaleString("ru")}
                </span>
              )}
            </div>
            <div className="products-price__main  d-flex align-items-baseline">
              <span className="price i-rub">
                {productPrice.priceBadgeStock.price.discountPrice &&
                  productPrice.priceBadgeStock.price.discountPrice.toLocaleString(
                    "ru"
                  )}
                {!productPrice.priceBadgeStock.price.discountPrice &&
                  productPrice.price.toLocaleString("ru")}
              </span>
              {
                productState?.unit &&
                <div className='price-unit' style={{ fontSize: 14 }}>
                  <span>
                    /
                  </span>
                  <span>
                    {productState.unit}
                  </span>
                </div>
              }
            </div>
          </div>
          {process.env.REACT_APP_TEST === 1 && (
            <div className="products-cart-sm">
              ?
              {productState.count > 0 && (
                <>
                  {!this.context.isInCart(productState.id) && (
                    <CartCtx.Consumer>
                      {({ cart, addProduct }) => (
                        <Button
                          variant="simple"
                          className="add-to-cart"
                          onClick={() => addProduct(productState.id)}
                        >
                          <i className="i-cart"></i>
                        </Button>
                      )}
                    </CartCtx.Consumer>
                  )}
                  {this.context.isInCart(productState.id) && (
                    <CartCtx.Consumer>
                      {({ cart, addProduct }) => (
                        <Button
                          variant="simple"
                          className="add-to-cart added"
                          disabled
                        >
                          <i className="i-cart"></i>
                        </Button>
                      )}
                    </CartCtx.Consumer>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {
          productStatus ?
            <div className='products-stock d-flex align-items-center mt-1' style={{ gap: 6 }}>
              {
                productStatus.icon ?
                  <img style={{ width: 20 }} src={`${process.env.REACT_APP_BACKEND}/site_image/status_icons/${productStatus.icon}`} alt='' />
                  :
                  ''
              }
              <div dangerouslySetInnerHTML={{ __html: productStatus.name_cat }} />

            </div>
            :
            <div className="products-stock">
              {productState.count > 0 && <p className="stock-in">В наличии</p>}
              {productState.count < 1 && <p className="stock-out">Нет в наличии</p>}
            </div>
        }
        {productPrice.priceBadgeStock.bages.length > 0 && (
          <Badges items={productPrice.priceBadgeStock} />
        )}
      </div>
    </div>
  );
};

export default ProductItem;
