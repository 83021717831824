import React, { Component } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import Badges from "../main/Badges";
// import WishList from "../main/ModalWishlist";

import { Button, Modal } from 'react-bootstrap';
import CartCtx from '../CartCtx'
import ProductCard from '../categories/ProductCard';
import { isIterableArray } from '../block-slider/utils';
// import TinySlider from 'tiny-slider-react';

export default class SearchResults extends Component {
    constructor(props) {
        super(props);

        this.hideInfoModal = this.hideInfoModal.bind(this);
        this.openInfoModal = this.openInfoModal.bind(this);

        this.state = {
            search: queryString.parse(window.location.search).q || '',
            page: this.props.match.params.page,
            products: null,
            pagination: null,
            message: '',
            infoCharModal: false
        };
        document.title = 'Результаты поиска | Галактика';
    }

    static contextType = CartCtx;

    changePage(num) {
        this.setState({
            page: num
        })
        setTimeout(() => {
            this.fetchProducts()
        }, 300)
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.search !== this.state.search) {
            this.setState({
                search: queryString.parse(window.location.search).q || '',
                page: this.props.match.params.page,
            });
            this.fetchProducts()
        }
    }
    componentDidMount() {
        this.fetchProducts()
    }

    fetchProducts() { 
        const selectedCenterId = this.context.selectedCenter ? this.context.selectedCenter.id : false

        axios.post(`${process.env.REACT_APP_API_BASE}/pages/category-search`, {
            'str': this.state.search,
            'page': this.props.match.params.page,
            tc_id: selectedCenterId
        })
            .then(response => {

                const listWithVariable = response.data.data[0].filter(item => (item?.char?.length > 0 || item.color !== null))

                this.setState({
                    isProductsHasVariable: isIterableArray(listWithVariable),
                    products: response.data.data[0] || null,
                    pagination: response.data.data[1] || null
                })
                if (this.state.products !== null) {
                    this.setState({
                        message: ``
                    })
                } else {
                    this.setState({
                        message: `По запросу "${this.state.search}" ничего не найдено`
                    })
                }
            })
            .catch(err => {
                console.error(err);

            })
    }


    hideInfoModal() {
        this.setState({
            infoCharModal: false
        })
    }

    openInfoModal(data) {
        this.setState({
            infoCharModal: {
                title: data.title,
                body: data.value
            }
        })
    }

    render() {
        return (
            <>
                {
                    this.state.infoCharModal ?
                        <Modal
                            show={!!this.state.infoCharModal}
                            onHide={this.hideInfoModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            dialogClassName="modal-xl"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.infoCharModal.title }} />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='p-0'>
                                <div dangerouslySetInnerHTML={{ __html: this.state.infoCharModal.body }} />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="link"
                                    onClick={this.hideInfoModal}
                                    className="btn-secondary"
                                >
                                    Закрыть
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        :
                        ''
                }
                <div className="container catalog">
                    {this.state.products === null &&
                        <>
                            <h2 style={{ textAlign: 'center', display: 'block', color: '#999', marginTop: 30 }}>
                                Результаты поиска
                            </h2>
                            <h4>
                                {this.state.message}
                            </h4>
                        </>
                    }

                    {this.state.products !== null &&
                        <h2 style={{ textAlign: 'center', display: 'block', color: '#999', marginTop: 30 }}>
                            Результаты поиска: {this.state.search}
                        </h2>
                    }
                    {this.state.products !== null &&
                        <div className="catalog__wrap" style={{ paddingTop: 30, minHeight: 540 }}>
                            <div className="catalog-list" style={{ width: '100%' }}>
                                <div className="catalog-list__wrap">

                                    {this.state.products.map((product, index) => (
                                        <ProductCard
                                            key={index}
                                            product={product}
                                            context={this.context}
                                            isProductsHasVariable={this.state.isProductsHasVariable}
                                        // openInfoModal={this.openInfoModal}
                                        />
                                        // <React.Fragment key={index}>
                                        //     {product.redirect === null &&

                                        //         <div className="catalog-list__item mb-3">
                                        //             <div className="products__wrap">
                                        //                 {/*<WishList />*/}
                                        //                 <div className="products-image">
                                        //                     <img
                                        //                         src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + product.photo_url}
                                        //                         alt={("Изображение товара " + product.name)} />
                                        //                 </div>

                                        //                 <Link className="products-title"
                                        //                     to={`/product/${product.name_slug}`} title={product.name}>
                                        //                     {product.name}
                                        //                 </Link>

                                        //                 <div
                                        //                     className={("products-price " + product.sale + " " + product.stockStatus)}>
                                        //                     <div className="producst-price__wrap">
                                        //                         <div className="products-price__old">
                                        //                             {product.priceBadgeStock.price.discountPrice &&
                                        //                                 <span
                                        //                                     className="price i-rub d-inline-block">{product.priceBadgeStock.price.price.toLocaleString('ru')}</span>
                                        //                             }
                                        //                         </div>
                                        //                         <div className="products-price__main">
                                        //                             {product.priceBadgeStock.price.discountPrice &&
                                        //                                 <span className="price i-rub" style={{ color: '#d81137' }}>
                                        //                                     {product.priceBadgeStock.price.discountPrice.toLocaleString('ru')}
                                        //                                 </span>
                                        //                             }
                                        //                             {!product.priceBadgeStock.price.discountPrice &&
                                        //                                 <span className="price i-rub">
                                        //                                     {product.price.toLocaleString('ru')}
                                        //                                 </span>
                                        //                             }
                                        //                         </div>
                                        //                     </div>
                                        //                     <div className="products-cart-sm">
                                        //                         {product.count > 0 &&
                                        //                             <>
                                        //                                 {!this.context.isInCart(product.id) &&
                                        //                                     <CartCtx.Consumer>
                                        //                                         {({ cart, addProduct }) => (
                                        //                                             <Button variant="simple" className="add-to-cart"
                                        //                                                 onClick={() => addProduct(product.id)}><i
                                        //                                                     className="i-cart"></i></Button>
                                        //                                         )}
                                        //                                     </CartCtx.Consumer>
                                        //                                 }
                                        //                                 {this.context.isInCart(product.id) &&
                                        //                                     <CartCtx.Consumer>
                                        //                                         {({ cart, addProduct }) => (
                                        //                                             <Button variant="simple"
                                        //                                                 className="add-to-cart added" disabled><i
                                        //                                                     className="i-cart"></i></Button>
                                        //                                         )}
                                        //                                     </CartCtx.Consumer>
                                        //                                 }
                                        //                             </>
                                        //                         }
                                        //                     </div>
                                        //                 </div>

                                        //                 <div className="products-stock">
                                        //                     {product.count > 0 &&
                                        //                         <p className='stock-in'>
                                        //                             В наличии
                                        //                         </p>
                                        //                     }
                                        //                     {product.count < 1 &&
                                        //                         <p className='stock-out'>
                                        //                             Нет в наличии
                                        //                         </p>
                                        //                     }
                                        //                 </div>

                                        //                 {
                                        //                     product.priceBadgeStock.bages.length > 0 &&
                                        //                     <Badges items={product.priceBadgeStock} />
                                        //                 }
                                        //                 {/*<div className="products-gift"></div>*/}
                                        //                 <div className="products__footer">
                                        //                     {product.count > 0 &&
                                        //                         <>
                                        //                             {!this.context.isInCart(product.id) &&
                                        //                                 <CartCtx.Consumer>
                                        //                                     {({ cart, addProduct }) => (
                                        //                                         <div className="cart-btn">
                                        //                                             <Button className="btn btn-primary"
                                        //                                                 onClick={() => addProduct(product.id)}>В
                                        //                                                 корзину</Button>
                                        //                                         </div>
                                        //                                     )}
                                        //                                 </CartCtx.Consumer>
                                        //                             }
                                        //                             {this.context.isInCart(product.id) &&
                                        //                                 <CartCtx.Consumer>
                                        //                                     {({ cart, addProduct }) => (
                                        //                                         <div className="cart-btn">
                                        //                                             <Button className="btn btn-primary added"
                                        //                                                 onClick={() => addProduct(product.id)}>Уже
                                        //                                                 в корзине</Button>
                                        //                                         </div>
                                        //                                     )}
                                        //                                 </CartCtx.Consumer>
                                        //                             }
                                        //                         </>
                                        //                     }
                                        //                     {/*<div className="short-bages">
                                        //         <ul className="short-bages__wrap">
                                        //             <li className="bage">
                                        //                 <img src="img/temp/products/b01.png" alt="" />
                                        //             </li>
                                        //         </ul>
                                        //     </div>*/}
                                        //                     <div className="short-desc">
                                        //                         <p dangerouslySetInnerHTML={{ __html: product.short_discription !== 'null' && product.short_discription }}></p>
                                        //                         <p>
                                        //                             {product.character.map((item, index) => {
                                        //                                 return (
                                        //                                     <span key={index}>
                                        //                                         {item.nameChar}: {item.valueChar}; <br />
                                        //                                     </span>
                                        //                                 )
                                        //                             })}
                                        //                         </p>
                                        //                     </div>
                                        //                 </div>
                                        //             </div>
                                        //         </div>
                                        //     }
                                        // </React.Fragment>
                                    ))}
                                </div>
                                {this.state.pagination !== null &&
                                    <div className="catalog-list__navigation">
                                        {this.state.pagination.lastPage > 1 &&
                                            <div className="pagination">
                                                <ul className="pagination__wrap">
                                                    <li className="pagination__item">
                                                        <Link className="link" to={`/search/${this.state.page}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page > 1 ? this.state.page - 1 : 1) }}>
                                                            <i className="i-arrow prev"><span></span></i>
                                                        </Link>
                                                    </li>

                                                    {this.state.page > 2 && this.state.pagination.lastPage > 3 &&
                                                        <li className="pagination__item">
                                                            <Link className="link" to={`/search/1?q=${this.state.search}`} onClick={() => { this.changePage(1) }}>
                                                                <b>1</b>
                                                            </Link>
                                                        </li>
                                                    }

                                                    {this.state.page > 3 && this.state.pagination.lastPage > 4 &&
                                                        <li className="pagination__item"><b>...</b></li>
                                                    }



                                                    {this.state.page === this.state.pagination.lastPage && this.state.pagination.lastPage > 2 &&
                                                        <li className="pagination__item">
                                                            <Link className="link" to={`/search/${this.state.page * 1 - 2}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page - 2) }}>
                                                                <b>{this.state.page * 1 - 2}</b>
                                                            </Link>
                                                        </li>
                                                    }
                                                    {this.state.page > 1 &&
                                                        <li className="pagination__item">
                                                            <Link className="link" to={`/search/${this.state.page * 1 - 1}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page * 1 - 1) }}>
                                                                <b>{this.state.page * 1 - 1}</b>
                                                            </Link>
                                                        </li>
                                                    }
                                                    <li className="pagination__item active">
                                                        <span className="link">
                                                            <b>{this.state.page}</b>
                                                        </span>
                                                    </li>
                                                    {this.state.page < this.state.pagination.lastPage &&
                                                        <li className="pagination__item">
                                                            <Link className="link" to={`/search/${this.state.page * 1 + 1}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page * 1 + 1) }}>
                                                                <b>{this.state.page * 1 + 1}</b>
                                                            </Link>
                                                        </li>
                                                    }
                                                    {this.state.page === 1 && this.state.pagination.lastPage > 2 &&
                                                        <li className="pagination__item">
                                                            <Link className="link" to={`/search/${this.state.page * 1 + 2}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page * 1 + 2) }}>
                                                                <b>{this.state.page * 1 + 2}</b>
                                                            </Link>
                                                        </li>
                                                    }

                                                    {this.state.page < this.state.pagination.lastPage * 1 - 2 && this.state.pagination.lastPage > 4 &&
                                                        <li className="pagination__item"><b>...</b></li>
                                                    }

                                                    {this.state.page < this.state.pagination.lastPage * 1 - 1 && this.state.pagination.lastPage > 3 &&
                                                        <li className="pagination__item">
                                                            <Link className="link" to={`/search/${this.state.pagination.lastPage}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.pagination.lastPage) }}>
                                                                <b>{this.state.pagination.lastPage}</b>
                                                            </Link>
                                                        </li>
                                                    }
                                                    <li className="pagination__item">
                                                        <Link className="link" to={`/search/${this.state.page < this.state.pagination.lastPage ? this.state.page * 1 + 1 : this.state.pagination.lastPage}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page < this.state.pagination.lastPage ? this.state.page * 1 + 1 : this.state.pagination.lastPage) }}>
                                                            <i className="i-arrow next"><span></span></i>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </>
        );
    }
}
