// React 
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";

class HeaderPromoLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topBanner: null,
    }
  }

  componentDidMount() {

    axios.get(`${process.env.REACT_APP_API_BASE}/new-stocks/stock-index`)
      .then(response => {
        this.setState({
          topBanner: response.data.data.baner
        }, () => {
          // console.log(this.state.topBanner)
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
  getLink(linkObj) {

    if (typeof linkObj === 'string') {
      if (linkObj.indexOf("https://galaktika.me") !== -1)
        return linkObj.replace("https://galaktika.me/", '/')
      if (linkObj.indexOf("https://avoidrandomusers99.galaktika.me/") !== -1)
        return linkObj.replace("https://avoidrandomusers99.galaktika.me/", '/')
      return '/' + linkObj
    }

    if (!linkObj || (linkObj.type !== 'prod' && linkObj.type !== 'cat')) return '/'

    if (linkObj.type === 'prod') return '/product/' + linkObj.name_slug
    if (linkObj.type === 'cat' && linkObj.type_cat === 'have_cat') return '/category/' + linkObj.name_slug
    if (linkObj.type === 'cat' && linkObj.type_cat === 'no_have_cat') return '/catalog/' + linkObj.name_slug
  }

  render() {

    return (
      <>
        {
          this.state.topBanner && (
            <div className="header-promo pr-test">
              <Link to={this.getLink(this.state.topBanner.link)} title="Акция">
                <img src={`${process.env.REACT_APP_BACKEND}/uploads/sp/banners/${this.state.topBanner.baner_url}`} alt="" />
              </Link>
            </div>
          )
        }
      </>
    )
  }
}

export default HeaderPromoLine;