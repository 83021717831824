export const isIterableArray = array => Array.isArray(array) && !!array.length;

export const pathToLottieAnimation = '/sliders/animations/'

const storageKey = 'galaktika-articule-product-of-day-key'

const defaultInterval = 8000
export const slidersIntervals = {
    autoscroll: defaultInterval,
    innerScroll: defaultInterval / 2,
    fixedInnerScroll: defaultInterval * 0.6666,
}
export const getSelectedProduct = products => {

    if (!isIterableArray(products)) return null

    const shownProducts = localStorage.getItem(storageKey) ? JSON.parse(localStorage.getItem(storageKey)) : []

    const articules = products.map(item => item.articule)

    const articulesClered = articules.filter(function (el) {
        return el != undefined;
    });

    let filtered = articulesClered.filter(artc => shownProducts.every(product_artc => !artc.includes(product_artc)));

    const selectedArticule = filtered[0]

    if (selectedArticule) {
        // console.log('нужно добавить артикул ')
        localStorage.setItem(storageKey, JSON.stringify([...shownProducts, selectedArticule]))
        return products.filter(item => item.articule === selectedArticule)[0]
    }
    else {
        // console.log('нужно добавить только артикул первого товара ')
        localStorage.setItem(storageKey, JSON.stringify([products[0].articule]))
        return products[0]
    }

}

export const isNotNullText = (text) => {
    if (!text) return false
    const cleared = text
        .replaceAll('br', '')
        .replaceAll('<', '')
        .replaceAll('>', '')
        .replaceAll('/', '')
        .replaceAll(' ', '')

    return cleared.length > 0
}