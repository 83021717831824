import React from 'react'
import { isIterableArray } from '../../block-slider/utils'
import TabsAccordion from './TabsAccordion'

import Type1 from './Type1'
import Type2 from './Type2'
import Type3 from './Type3'
import Type4 from './Type4'
import Type5 from './Type5'
import Type6 from './Type6'
import TypeForm from './TypeForm'
// import Type9 from './Type9' 
// import Type12 from './Type12'
const RenderBlocks = ({ data }) => {
    return (
        isIterableArray(data) ?
            <div>
                {
                    data.map((item, key) => {
                        const block = item.blocks
                        switch (block?.type) {
                            case 1: return <Type1 data={block} key={key} />
                            case 2: return <Type2 data={block} key={key} />
                            case 3: return <Type3 data={block} key={key} />
                            case 4: return <Type4 data={block} key={key} />
                            case 5: return <Type5 data={block} key={key} />
                            case 6: return <Type6 data={block} key={key} />
                            case 7: return <TabsAccordion data={block} key={key} />
                            case 8: return <TypeForm data={block} key={key} />

                            default: return ''
                        }
                    })
                }
            </div>
            :
            ''
    )
}

export default RenderBlocks
