import React, { memo, useState, useEffect } from 'react'
import moment from 'moment'

const Timer = memo(({ dateEndStock }) => {

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);


    let interval = null;

    let end = moment(dateEndStock);
    const tick = () => {
        let now = moment();
        let d = end.diff(now, "days");
        let h = end.diff(now, "hours") - d * 24;
        let m = end.diff(now, "minutes") - d * 24 * 60 - h * 60;
        let s = end.diff(now, "seconds") - m * 60 - h * 3600 - d * 24 * 60 * 60;

        setDays(d);
        setHours(h < 10 ? `0${h}` : h);
        setMinutes(m < 10 ? `0${m}` : m);
        setSeconds(s < 10 ? `0${s}` : s);
    };

    useEffect(() => {
        interval = setInterval(tick, 1000);

        return () => clearInterval(interval);
    });

    const isEnded = moment(dateEndStock).isSameOrAfter(moment())

    return (
        isEnded ?
            <div className="timer">
                <div className="timer-block">
                    <div className="timer-num">{days}</div>
                    <div className="timer-text">дней</div>
                </div>
                <div className="timer-block colon">
                    <div className="timer-num">:</div>
                    <div className="timer-text"></div>
                </div>
                <div className="timer-block">
                    <div className="timer-num">{hours}</div>
                    <div className="timer-text">часов</div>
                </div>
                <div className="timer-block colon">
                    <div className="timer-num">:</div>
                    <div className="timer-text"></div>
                </div>
                <div className="timer-block">
                    <div className="timer-num">{minutes}</div>
                    <div className="timer-text">минут</div>
                </div>
                <div className="timer-block colon">
                    <div className="timer-num">:</div>
                    <div className="timer-text"></div>
                </div>
                <div className="timer-block">
                    <div className="timer-num">{seconds}</div>
                    <div className="timer-text">секунд</div>
                </div>
            </div>
            :
            ''
    )
})

export default Timer