import moment from "moment/moment";
import { Modal } from "react-bootstrap";

import React, { useState, useEffect, useRef } from "react";
import Timer from "../Timer";
import "@aarsteinmedia/dotlottie-player";
import fontColorContrast from 'font-color-contrast'

const BlocksCountdown = ({ info, block_info, url_gif }) => {

  const startDate = moment(info.date_start_stock).format("DD.MM.YYYY");
  const endDate = moment(info["date_end_stock"]).format("DD.MM.YYYY");

  const [modal, setModal] = useState(false);

  const showModal = () => setModal(true);
  const hideModal = () => setModal(false);

  const animation = block_info?.animation ? JSON.parse(block_info?.animation) : false


  const playerRef = useRef(null)
  const [animationURL, setAnimationURL] = useState(null);

  useEffect(() => {
    if (animation) handleLottieAnimationLoad()
  }, [animation]);

  const handleLottieAnimationLoad = () => {
    const lottieFileURL = animation?.url ? `${'/blocksnew/'}${animation.url}` : null;
    setAnimationURL(lottieFileURL);
  }

  useEffect(() => {
    if (playerRef.current && animationURL) {
      waitForLottieAnimationLoaded().catch((err) => {
        console.log('lottie err', err)
        setAnimationURL(null)
      })
    }
  }, [playerRef.current, animationURL]);

  const waitForLottieAnimationLoaded = () => {
    return new Promise(() => {
      if (!playerRef?.current?.getLottie()) {
        throw new Error('animation error');
      }
    });
  }

  const isEnded = moment("2024-05-31 11:42:00", 'YYYY-MM-DD').isSameOrAfter(moment())


  return (
    info ?
      <div
        className="blocks-slider__countdown"
        style={{
          backgroundColor: block_info.plashka_background
        }}
      >
        {
          // block_info.gif &&
          // block_info.gif &&
          <div className="blocks-slider-gif" style={{
            backgroundImage: `url(/blocksnew/${block_info.gif})`
            // backgroundImage: `url(${process.env.REACT_APP_BACKEND}${url_gif}${block_info.gif}})`
          }} />
        }
        <div
          className="blocks-wrapper__animation-layer"
          style={{ zIndex: 2 }}
        >
          {
            animation &&
            <dotlottie-player
              ref={playerRef}
              src={animationURL}
              background="transparent"
              speed={animation.speed}
              direction="1"
              mode="normal"
              loop
              autoplay
            ></dotlottie-player>
          }
        </div>
        <div className='blocks__countdown-content'>
          <div dangerouslySetInnerHTML={{ __html: info.name_stock }}></div>
          <div className="blocks__countdown-dates">
            {startDate} - {endDate}
          </div>
          <div dangerouslySetInnerHTML={{ __html: info.text_above_counter }}></div>

          <Timer dateEndStock={info["date_end_stock"]} />


          <div className="d-flex justify-content-center align-items-center mt-2" style={{ gap: 8 }}>
            {info.button_text && (
              <button className="btn" onClick={showModal} style={{
                backgroundColor: info.button_color,
                color: fontColorContrast(info.button_color)
              }}>
                <div dangerouslySetInnerHTML={{ __html: info.button_text }} />
              </button>
            )}
            {info.text_link && (
              <div dangerouslySetInnerHTML={{ __html: info.text_link }} />
            )}
          </div>
        </div>
        <Modal show={modal} onHide={hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              &nbsp;
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={{ __html: info.text_for_modal }} />
          </Modal.Body>
        </Modal>
      </div>
      :
      ''
  );
};

export default BlocksCountdown;
