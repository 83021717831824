import React from 'react'

const SkeletonMenu = () => {
  return (
    <div className='buildedPage-sidemenu buildedPage__skeleton-menu'>
      <div />
      <p />
      <hr />
      <div />
      <p />
      <p />
      <span /> 
      <span />
      <span />
      <hr />
      <div />
      <span />
      <span />
      <p />
    </div>
  )
}

export default SkeletonMenu
