//React
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Badges from "./Badges";
import WishList from "../main/ModalWishlist";

import { Button } from 'react-bootstrap';
import CartCtx from '../CartCtx'
import { isIterableArray } from '../block-slider/utils';
import ProductCard from '../categories/ProductCard';
import ProductItem from '../block-slider/blocks-type2/ProductItem';

class SliderProductsLastView extends Component {
    static contextType = CartCtx;
    render() {

        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 5,
            },
            desktop: {
                breakpoint: { max: 3000, min: 1200 },
                items: 6,
            },
            tablet: {
                breakpoint: { max: 1200, min: 768 },
                items: 4,
            },
            mobile: {
                breakpoint: { max: 767, min: 0 },
                items: 2,
            },
        };

        const listWithVariable = this.props.items.prod.filter(item => (isIterableArray(item?.char) || isIterableArray(item.color)))
        const isProductsHasVariable = isIterableArray(listWithVariable)

        return (
            <div className="products-slider">
                <h4 className="products-slider__title">
                    <img src={`${process.env.REACT_APP_BACKEND}/site_image/blocks_icons/${this.props.items.icon_url}`} alt="" />
                    {this.props.items.name}
                </h4>
                <div className="products-slider__wrap">
                    <Carousel
                        responsive={responsive}>
                        {this.props.items.prod.map((product, index) => (
                            <ProductItem
                                key={index}
                                product={product}
                                isProductsHasVariable={isProductsHasVariable}
                            />
                            // <div className="products__wrap" key={index}>
                            //     <div className="btn-toolbar">
                            //         <WishList productId={product.id} />
                            //     </div>
                            //     <div className="products-image">
                            //         <Link className="products-image__link" to={`/product/${product.name_slug}`}>
                            //             <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + product.photo_url} alt={("Изображение товара " + product.name)} />
                            //         </Link>
                            //     </div>

                            //     <div className="products-colors">
                            //         <div className={("products-colors__wrap " + product.colors)}>
                            //             {/*        <Link to="#" className="products-colors__item active">*/}
                            //             {/*            <span style={{ background: '#3A50AE' }}></span>*/}
                            //             {/*        </Link>*/}
                            //             {/*        <Link to="#" className="products-colors__item">*/}
                            //             {/*            <span style={{ background: '#000' }}></span>*/}
                            //             {/*        </Link>*/}
                            //             {/*        <Link to="#" className="products-colors__item">*/}
                            //             {/*            <span style={{ background: '#ddd' }}></span>*/}
                            //             {/*        </Link>*/}
                            //             {/*        <Link to="#" className="products-colors__item">*/}
                            //             {/*            <span style={{ background: '#E51414' }}></span>*/}
                            //             {/*        </Link>*/}
                            //         </div>
                            //     </div>

                            //     <Link className="products-title" to={`/product/${product.name_slug}`} title={product.name}>
                            //         {product.name}
                            //     </Link>

                            //     {/*<Link to="/SingleProduct" className="products-reviews">*/}
                            //     {/*    /!* Классы no-rating / no-reviews для products-reviews, для добавления иконки, когда нет звездочек *!/*/}
                            //     {/*    <StarRatings*/}
                            //     {/*        rating={product.rating}*/}
                            //     {/*        starDimension="13px"*/}
                            //     {/*        starRatedColor="#FFB800"*/}
                            //     {/*        starSpacing="1px"*/}
                            //     {/*    />*/}
                            //     {/*    <i className="i-reviews"></i>*/}
                            //     {/*    <p className="products-reviews__link">{product.reviews}</p>*/}
                            //     {/*</Link>*/}

                            //     <div className={("products-price " + product.sale + " " + product.stockStatus)}>
                            //         <div className="producst-price__wrap">
                            //             <div className="products-price__old">
                            //                 {product.priceBadgeStock.price.discountPrice &&
                            //                     <span className="price i-rub d-inline-block">{product.priceBadgeStock.price.price.toLocaleString('ru')}</span>
                            //                 }
                            //             </div>
                            //             <div className="products-price__main">
                            //                 <span className="price i-rub">
                            //                     {product.priceBadgeStock.price.discountPrice &&
                            //                         product.priceBadgeStock.price.discountPrice.toLocaleString('ru')
                            //                     }
                            //                     {!product.priceBadgeStock.price.discountPrice &&
                            //                         product.price.toLocaleString('ru')
                            //                     }

                            //                 </span>
                            //             </div>
                            //         </div>
                            //         {process.env.REACT_APP_TEST === 1 &&
                            //             <div className="products-cart-sm">
                            //                 {product.count > 0 &&
                            //                     <>
                            //                         {!this.context.isInCart(product.id) &&
                            //                             <CartCtx.Consumer>
                            //                                 {({ cart, addProduct }) => (
                            //                                     <Button variant="simple" className="add-to-cart" onClick={() => addProduct(product.id)}><i className="i-cart"></i></Button>
                            //                                 )}
                            //                             </CartCtx.Consumer>
                            //                         }
                            //                         {this.context.isInCart(product.id) &&
                            //                             <CartCtx.Consumer>
                            //                                 {({ cart, addProduct }) => (
                            //                                     <Button variant="simple" className="add-to-cart added" disabled><i className="i-cart"></i></Button>
                            //                                 )}
                            //                             </CartCtx.Consumer>
                            //                         }
                            //                     </>
                            //                 }
                            //             </div>
                            //         }
                            //     </div>

                            //     <div className="products-stock">
                            //         {product.count > 0 &&
                            //             <p className='stock-in'>
                            //                 В наличии
                            //             </p>
                            //         }
                            //         {product.count < 1 &&
                            //             <p className='stock-out'>
                            //                 Нет в наличии
                            //             </p>
                            //         }
                            //     </div>
                            //     {
                            //         product.priceBadgeStock.bages.length > 0 &&
                            //         <Badges items={product.priceBadgeStock} />
                            //     }
                            //     {product.gift && (
                            //         <Link to={`/product/${product.gift.name_slug}`} className="products-gift contents"
                            //             style={{
                            //                 color: '#000',
                            //                 textDecoration: 'none',
                            //                 background: 'rgba(255,255,255,.5)',
                            //                 backdropFilter: 'blur(20px)'
                            //             }}
                            //         >
                            //             <span className="f7 fw500">Подарок</span>
                            //             <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + product.gift.photo_url} alt="" className="img" />
                            //         </Link>
                            //     )}

                            // </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        );
    }
}

export default SliderProductsLastView;
