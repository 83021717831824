import React from 'react'
import Type1Gallery from './Type1Gallery'
import Type1Slider from './Type1Slider'

const Type1 = ({ data }) => {
  return (
    <div>
      {
        data.setting.slider == 'true' ?
          <Type1Slider data={data} />
          :
          <Type1Gallery data={data} />
      }

    </div>
  )
}

export default Type1
