import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { isIterableArray } from '../block-slider/utils'
import Accordion from 'react-bootstrap/Accordion';
// import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { AccordionContext, Button, Card, useAccordionToggle } from 'react-bootstrap';
import { getIsFollow, getLinkByPageType } from '../main/menus/linkUtils';


// ссыска для элементов меню сайдбар. используется так же в хлебных крошках
export const SideMenuLinkWrapper = ({ pageType, link, pageSlug, children, onClick = () => { } }) => (
    pageSlug ?
        <Link
            onClick={onClick}
            to={getLinkByPageType(pageType, `/info/${pageSlug}`)}
        >
            {children}
        </Link>
        :
        link ?
            <Link
                onClick={onClick}
                {...getIsFollow(link?.follow)}
                target={link?.notGalaxy ? '_blank' : "_self"}
                to={{ pathname: link?.url }}
            >
                {children}
            </Link>
            :
            children
)

const SideMenu = ({ menuData }) => {

    const isActive = (item) => {
        // console.log(item)
        // // console.log(location)

        // if("/" + item.pageSlug === pathname) return 'active'

        // return 'activeSideMenuItem'
        return item.active ? 'active' : ''
    }


    const [count, setCount] = useState(0);

    const [isOpen, setIsOpen] = useState(false);

    const toggleAside = () => {
        setIsOpen(prev => !prev)
    }

    // ==========


    function CustomToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <div
                onClick={decoratedOnClick}
                className={isCurrentEventKey ? "" : 'rotated'}
            >
                {children}
            </div>
        );
    }


    const RenderSubItems = ({ items, keyTarget }) => (
        items.map((s_item, kkey) => (
            <Accordion key={keyTarget + '-' + kkey} defaultActiveKey={keyTarget + '-' + kkey}>
                <div
                    className={`buildedPage-sidemenu__item-chidren`}
                >
                    {/* ${s_item?.active ? 'active' : ''}  */}
                    <div
                        className={`buildedPage-sidemenu__item-chidren-title ${isActive(s_item)}`}
                    >
                        <SideMenuLinkWrapper onClick={toggleAside} pageType={s_item.pageType} link={s_item.link} pageSlug={s_item.pageSlug}>
                            {
                                s_item.icon &&
                                <img className="sidemenu__icon" src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + s_item.icon} />
                            }

                            <div dangerouslySetInnerHTML={{ __html: s_item.name }}></div>

                            {/* key {keyTarget + '-' + kkey} */}

                            {
                                s_item.isArrow ?
                                    <div className='arrow-icon icon-is-arrow'><i className="i-arrow next" /></div>
                                    :
                                    ''
                            }
                        </SideMenuLinkWrapper>
                        {
                            isIterableArray(s_item.subItems) &&

                            <CustomToggle eventKey={keyTarget + '-' + kkey}>
                                <div className='arrow-icon'><i className="i-arrow next" /></div>
                            </CustomToggle>

                        }

                    </div>
                    {
                        isIterableArray(s_item.subItems) &&
                        <Accordion.Collapse eventKey={keyTarget + '-' + kkey}>
                            <div>
                                <RenderSubItems items={s_item.subItems} keyTarget={keyTarget + '-' + kkey} />
                            </div>
                        </Accordion.Collapse>
                    }
                </div>
            </Accordion>

        ))
    )

    const RenderItems = ({ items }) => (
        items.map((item, key) => (
            <div key={key} className='buildedPage-sidemenu__item'>
                {/* ${item?.active ? 'active' : ''} */}
                <div className={`buildedPage-sidemenu__item-parent ${isActive(item)}`} >

                    <SideMenuLinkWrapper onClick={toggleAside} pageType={item.pageType} link={item.link} pageSlug={item.pageSlug}>
                        {
                            item.icon &&
                            <img className="sidemenu__icon" src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + item.icon} />
                        }
                        <div dangerouslySetInnerHTML={{ __html: item.name }}></div>

                    </SideMenuLinkWrapper>
                    {/* {
                        isIterableArray(item.subItems) && <div className='arrow-icon'><i className="i-arrow next" /></div>
                    } */}
                </div>
                {
                    isIterableArray(item.subItems) && <RenderSubItems items={item.subItems} keyTarget={key} />
                }
            </div>
        ))

    )
    return (
        <div className='buildedPage-sidemenu'>
            <div className={`buildedPage-aside ${isOpen ? 'open' : ''}`}>
                <div className="promo-aside_header">
                    <h5 className='mb-0'>Навигация по страницам</h5>
                    <Button onClick={toggleAside} variant="simple" className="btn_close">
                        <span aria-hidden="true">×</span>
                    </Button>
                </div>
                {
                    menuData?.information &&
                    <RenderItems items={[menuData?.information]} />
                }
                {
                    isIterableArray(menuData?.items) ? <RenderItems items={menuData?.items} />
                        :
                        ''
                }
            </div>
            {
                <div onClick={toggleAside} className={`buildedPage-aside-wrapper ${isOpen ? 'open' : ''}`} />
            }

            <Button onClick={toggleAside} variant='outline-primary' className="buildedPage-aside__btn d-none" >
                <div className='navbar-toggler-icon'>
                    <span />
                    <span />
                    <span />
                </div>
                Навигация по страницам
            </Button>
        </div>
    )
}

export default SideMenu
