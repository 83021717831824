import React from 'react'

const Skeleton = ({ isLoading = true }) => {
    return (
        <div className="skeleton__wrap"
            style={{
                transition: 'opacity .4s ease-out',
                opacity: isLoading ? 1 : 0,
            }}
        >
            <div className="catalog__wrap">
                <div className="catalog-list">

                    <div className='skeleton-card' >
                        <img src="/img/skeleton-card-5.jpg" />
                    </div>
                    <div className='skeleton-card' >
                        <img src="/img/skeleton-card-5.jpg" />
                    </div>
                    <div className='skeleton-card' >
                        <img src="/img/skeleton-card-5.jpg" />
                    </div>
                    <div className='skeleton-card' >
                        <img src="/img/skeleton-card-5.jpg" />
                    </div>
                    <div className='skeleton-card' >
                        <img src="/img/skeleton-card-5.jpg" />
                    </div>
                    <div className='skeleton-card' >
                        <img src="/img/skeleton-card-5.jpg" />
                    </div>
                    <div className='skeleton-card' >
                        <img src="/img/skeleton-card-5.jpg" />
                    </div>
                    <div className='skeleton-card' >
                        <img src="/img/skeleton-card-5.jpg" />
                    </div>
                    <div className='skeleton-card' >
                        <img src="/img/skeleton-card-5.jpg" />
                    </div>
                    <div className='skeleton-card' >
                        <img src="/img/skeleton-card-5.jpg" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skeleton