import React from "react";
import { Link } from "react-router-dom";

const BlockItem9 = ({ url_list, data }) => {
  // жду name_link, name, plashka, rel для линка
  return (
    <li className={`glide__slide slider block type-${data.type}`}>

      {
        data.video?.name ?
          data.video?.name_link ?
            <Link
              className={`video-block-name ${data.video.plashka ? 'with-bg' : ''}`}
              to={{ pathname: data.video.name_link }}
              target='_blank'
            >
              <h3  >
                {data.video.name}
              </h3>
            </Link>
            :
            <h3 className={`video-block-name ${data.video.plashka ? 'with-bg' : ''}`} >
              {data.video.name}
            </h3>
          :
          ''
      }
      <div className="video">
        <video
          poster={url_list.video + data.video.poster}
          src={url_list.video + data?.video?.url}
          autoPlay={data.video.autoplay ? true : false}
          muted={data.video.muted ? true : false}
          loop={data.video.loop ? true : false}
          controls={data.video.controls ? true : false}

          playsInline //чтобы видео не открывалось автоматически на айфоне
        />
      </div>
    </li>
  );
};

export default BlockItem9;
