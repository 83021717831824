import React from 'react'
import { Link } from 'react-router-dom'
import { isIterableArray } from '../../block-slider/utils'
import { getIsFollow, getLinkByPageType } from '../../main/menus/linkUtils'


// используется в тип 4 (здесь) и в типе 5 (карточки)
export const LinkWrapper = ({ link, children }) => {
    if (link.type === 'false' || !link) return children
    if (link.type === 'link') {
        if (link.link_val?.url) {
            return (
                <Link
                    {...getIsFollow(link?.link_val?.follow)}
                    target={link?.link_val?.notGalaxy ? '_blank' : "_self"}
                    // to={{ pathname: itemLink.link?.notGalaxy ? itemLink?.link?.url : `/${itemLink?.link?.url}` }}
                    to={{ pathname: '/info/' + link?.link_val?.url }}
                >
                    {children}
                </Link>
            )
        }
        else return (
            <Link
                target={link?.link_val.startsWith('http') ? '_blank' : '_self'}
                to={{ pathname: link?.link_val }}
            >
                {children}
            </Link>
        )
    }
    if (link.type === 'page') return (
        <Link
            to={{ pathname: getLinkByPageType(link.pageType, '/info/' + link.pageSlug) }}
        >
            {children}
        </Link>
    )
    return (
        <Link to='/'>
            {children}
        </Link>
    )
}

// тип 4 
// триггер
const Type4 = ({ data }) => {

    return (
        <div className='buildedPage-type_4'>
            {data.title && <div className='title' dangerouslySetInnerHTML={{ __html: data.title }} />}
            <div className={`buildedPage-type_4__row`}>
                {
                    isIterableArray(data.items) && data.items.map((item, key) => (
                        <div className='card-item' key={key} style={{ width: `calc(100% / ${data.setting.itemsPerRow || 4})` }}>
                            <LinkWrapper link={item.link}>
                                <img src={process.env.REACT_APP_BACKEND + '/uploads/pages/blocks/' + item.src} alt={item.name} />
                                <div className='title' dangerouslySetInnerHTML={{ __html: item.title }} />
                                <div className='text' dangerouslySetInnerHTML={{ __html: item.text }} />
                            </LinkWrapper>
                        </div>
                    ))
                }
            </div>
            {data.text && <div className='text' dangerouslySetInnerHTML={{ __html: data.text }} />}
        </div >
    )
}

export default Type4
