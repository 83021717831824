import React from 'react'
import { Container, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { isIterableArray } from '../../block-slider/utils'
import HeaderContact from '../HeaderContact'

// import iconDiscount from '../../../img/slider-discount.svg'
import { ReactComponent as IconDiscount } from '../../../img/slider-discount.svg'
import { ReactComponent as IconClock } from '../../../img/i-clock-white.svg'
import { getIsFollow, HeaderTopMenuLink } from './linkUtils'
import SelectButton from '../centerSelect/SelectButton'

const HeaderTopMenu = ({ data, handleOpenMainModal }) => {

    return (
        <div className='header-top-menu desktop'>
            <div className='header-top-menu__container'>

                <SelectButton
                    contact={data?.contact}
                    handleOpenMainModal={handleOpenMainModal}
                />

                {/* АКЦИИ */}
                <div className='header-top-menu__content'>
                    {
                        data?.stock &&
                        <div className="menu-item mr-4">
                            <Link to="/promo" className="nav-link stock-link">
                                <div className="menu-item__btn">
                                    <IconDiscount className='header-top-menu__icon' />
                                    {
                                        data.stock?.item?.name
                                    }
                                    <svg className='arrow-icon' width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.1334 -5.24537e-08L6.33337 4.8L1.53337 -4.72083e-07L0.333373 1.2L6.33337 7.2L12.3334 1.2L11.1334 -5.24537e-08Z" />
                                    </svg>
                                </div>
                            </Link>
                            {
                                isIterableArray(data.stock.subItems) &&
                                <div className="menu-item__wrap">
                                    <div className="menu-item__inner">
                                        {
                                            data.stock.subItems.map(item => (
                                                <Link
                                                    {...getIsFollow(item.link.follow)}
                                                    key={item.id}
                                                    target={item.link.notGalaxy ? "_blank" : '_self'}
                                                    to={{ pathname: item.link.url }}
                                                >
                                                    {
                                                        item.icon &&
                                                        <img className="header-top-menu__icon" src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + item.icon} />
                                                    }
                                                    {item.name}
                                                </Link>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                    }
                    {
                        isIterableArray(data?.items) &&
                        data.items.map(item => (
                            <div className="menu-item" key={item.id}>
                                <div className="menu-item__btn">
                                    {
                                        item.name
                                    }
                                    <svg className='arrow-icon' viewBox="0 0 13 8" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.1334 -5.24537e-08L6.33337 4.8L1.53337 -4.72083e-07L0.333373 1.2L6.33337 7.2L12.3334 1.2L11.1334 -5.24537e-08Z" />
                                    </svg>
                                </div>
                                {
                                    isIterableArray(item.subItems) &&
                                    <div className="menu-item__wrap">
                                        <div className="menu-item__inner">
                                            {
                                                item.subItems.map(item => (
                                                    <HeaderTopMenuLink key={item.id} itemLink={item} />
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default HeaderTopMenu
