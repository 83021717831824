import React from 'react'
import { Col, Nav, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { isIterableArray } from '../../block-slider/utils'

import iconDiscount from '../../../img/slider-discount.svg'
import { getIsFollow, HeaderTopMenuLink, MenuImages } from './linkUtils'

const FooterMenu = ({ FormSubscribe, footerData, headerData }) => {
    return (
        <Row className='d-none d-md-flex justify-content-center'>
            <Col md={4} lg={4} xl={'auto'} style={{ flexGrow: 1, maxWidth: 300 }}>

                {
                    footerData?.stock &&
                    <div className="menu-item mr-4">
                        <div className="menu-item__btn">
                            {/* <img className="header-top-menu__icon" src={iconDiscount} /> */}
                            <Link to="/promo" className="nav-link">
                                {
                                    footerData.stock?.item?.name
                                }
                            </Link>
                        </div>
                        <div className="menu-item__wrap">
                            <div className="menu-item__inner">
                                {
                                    isIterableArray(footerData.stock.subItems) && footerData.stock.subItems.map(item => (
                                        <Link
                                            key={item.id}
                                            {...getIsFollow(item.link.follow)}
                                            target={item.link.notGalaxy ? "_blank" : '_self'}
                                            to={{ pathname: item.link.url }}
                                        >
                                            {
                                                item.icon &&
                                                <img className="header-top-menu__icon" src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + item.icon} />
                                            }
                                            {item.name}
                                        </Link>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                }

                {
                    isIterableArray(footerData?.type2) &&
                    <MenuImages items={footerData?.type2} />
                }
            </Col>
            <Col md={8} lg={8} xl={'auto'} style={{ flexGrow: 2 }}>
                <Row >


                    {
                        isIterableArray(footerData?.items) && footerData.items.map(item => (
                            <Col key={item.id} md={6} lg={4}>
                                <div className="menu-item" key={item.id}>
                                    <div className="menu-item__btn">
                                        <HeaderTopMenuLink itemLink={item} />
                                    </div>
                                    <div className="menu-item__inner">
                                        {
                                            isIterableArray(item.subItems) && item.subItems.map(item => (
                                                <HeaderTopMenuLink key={item.id} itemLink={item} />
                                            ))
                                        }
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </Col>
            <Col lg={6} xl={'auto'}>

                <FormSubscribe />

                <div className="social">
                    <p>
                        {
                            footerData?.type8?.text_socials
                        }
                    </p>
                    <div className="social-list">
                        {
                            isIterableArray(footerData?.type8?.socials) &&
                            footerData.type8.socials.map((item, key) => (
                                <div className="social-list__item" key={key}>
                                    <Nav.Link href={item.url} target="_blank">
                                        <img src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + item.icon} alt={item.alt} />
                                    </Nav.Link>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="menu-item mt-4">
                    <div className="menu-item__btn" dangerouslySetInnerHTML={{ __html: headerData?.contact?.name }} />
                    <div className="menu-item__inner">
                        {
                            isIterableArray(headerData?.contact?.subItems) && headerData.contact.subItems.map(item => (
                                item?.phones?.length > 3 ?
                                    <div key={item.id} className='menu-contact-item'>
                                        <span>{item.name}</span>
                                        {' — '}
                                        <a href={`tel:${item.phones}`}>
                                            {item.phones}
                                        </a>
                                    </div>
                                    :
                                    <span key={item.id} />
                            ))
                        }
                    </div>
                </div>

            </Col>
        </Row>


    )
}

export default FooterMenu
