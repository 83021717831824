import React from 'react'
import { Link } from 'react-router-dom'
import { isIterableArray } from '../../block-slider/utils'
import { getLinkByPageType } from '../../main/menus/linkUtils'


// тип 6
// меню ссылки
const Type6 = ({ data }) => {

    const LinkWrapper = ({ item, children, styleLink, classNameLink }) => {
        if (item?.type === 'link' && item.pageSlug) {
            return (
                <Link
                    style={styleLink}
                    className={classNameLink}
                    to={{ pathname: '/info/' + item.pageSlug }}

                >
                    {children}
                </Link>
            )
        }
        if (item?.type === 'menu' && item?.link?.pageSlug) return (
            <Link
                style={styleLink}
                className={classNameLink}
                // to={{ pathname: '/info/' + item.link.pageSlug }}
                to={{ pathname: getLinkByPageType(item.link.pageType, '/info/' + item.link.pageSlug) }}
            >
                {children}
            </Link>
        )
        if (item?.type === 'page' && item?.pageSlug) return (
            <Link
                style={styleLink}
                className={classNameLink}
                to={{ pathname: '/info/' + item.pageSlug }}
            >
                {children}
            </Link>
        )
        if (item?.type === 'link') return (
            <Link
                style={styleLink}
                className={classNameLink}
                target={item.val.startsWith('http') ? '_blank' : '_self'}
                to={{ pathname: item.val }}
            >
                {children}
            </Link>
        )
        return children

    }


    return (
        <div className='buildedPage-type_6'>
            {data.title && <div className='title' dangerouslySetInnerHTML={{ __html: data.title }} />}

            <div className='buildedPage-type_6_row'>
                {
                    isIterableArray(data.items) &&
                    data.items.map((item, key) => (
                        // если 1 элемент то весь блок ссылка
                        item.items.length === 1 ?
                            <LinkWrapper
                                item={item.items[0]}
                                key={key}
                                classNameLink={`block-link `}
                                styleLink={{ width: data.setting.itemsPerRow ? `calc(100% / ${data.setting.itemsPerRow || 4})` : 'auto' }}
                            >
                                <div className={`buildedPage-type_6_row__item ${data.setting.cards ? 'card-item' : ''}`}>
                                    <div className='item-title' dangerouslySetInnerHTML={{ __html: item.title }} />
                                    <div className='item-link' dangerouslySetInnerHTML={{ __html: item.items[0].title }} />
                                </div>
                            </LinkWrapper>
                            :
                            <div key={key}
                                className='type_6-col'
                                style={{ width: data.setting.itemsPerRow ? `calc(100% / ${data.setting.itemsPerRow || 4})` : 'auto' }}
                            >
                                <div className={`buildedPage-type_6_row__item ${data.setting.cards ? 'card-item' : ''}`}>
                                    <div className='item-title' dangerouslySetInnerHTML={{ __html: item.title }} />

                                    {
                                        isIterableArray(item.items) &&
                                        item.items.map((inner_item, inner_key) => (
                                            <LinkWrapper key={inner_key} item={inner_item}>
                                                <div className='item-link' dangerouslySetInnerHTML={{ __html: inner_item.title }} />
                                            </LinkWrapper>
                                        ))
                                    }
                                </div>
                            </div>
                    ))
                }
            </div>
            {data.text && <div className='text' dangerouslySetInnerHTML={{ __html: data.text }} />}
        </div >
    )
}

export default Type6
