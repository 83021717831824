import React, { useEffect, useRef, useState } from "react";
import "@aarsteinmedia/dotlottie-player";
import Carousel from "react-bootstrap/Carousel";
import SmallBlock from "./SmallBlock";
import SmallBlockType8 from "./SmallBlockType8";
import { isIterableArray, pathToLottieAnimation, slidersIntervals } from "../utils";


const BlockItem8 = ({ url_list, data, isFixed }) => {

  const animationBg = data.animation ? data.animation.background : 0; // 0  
  const carousel_interval = isFixed ? slidersIntervals.fixedInnerScroll : slidersIntervals.innerScroll


  const playerRef = useRef(null)
  const [animationURL, setAnimationURL] = useState(null);

  useEffect(() => {
    if (data?.animation) handleLottieAnimationLoad()
  }, [data?.animation]);

  const handleLottieAnimationLoad = () => {
    // const lottieFileURL = data?.animation ? `/lottie/${data.animation.url}` : null;
    const lottieFileURL = data?.animation ? `${pathToLottieAnimation}${data.animation.url}` : null;
    setAnimationURL(lottieFileURL);
  }

  useEffect(() => {
    if (playerRef.current) {
      waitForLottieAnimationLoaded().catch((err) => {
        setAnimationURL(null)
      })
    }
  }, [playerRef.current]);

  const waitForLottieAnimationLoaded = () => {
    return new Promise(() => {
      if (!playerRef?.current?.getLottie()) {
        throw new Error('animation error');
      }
    });
  }


  return (
    <li className={`glide__slide slider block type-${data.type}`}>
      <div className={`block7-animation  ${animationBg === 0 ? "fg" : "bg"}`}>
        {
          data.animation && animationURL !== null &&
          <dotlottie-player
            ref={playerRef}
            src={animationURL}
            background="transparent"
            speed={data.animation.speed}
            direction="1"
            mode="normal"
            loop
            autoplay
          ></dotlottie-player>
        }
      </div>
      <div className="grid-8">
        <Carousel id="sliderMain" controls={false} interval={carousel_interval}>
          {
            isIterableArray(data.slider_prod) &&
            data.slider_prod.map((item, index) => {
              return (
                <Carousel.Item key={index}>
                  <SmallBlockType8 url_list={url_list} data={item} />
                </Carousel.Item>
              );
            })
          }
        </Carousel>
        <SmallBlock url_list={url_list} data={data.block1} />
        <SmallBlock url_list={url_list} data={data.block2} />
      </div>
    </li>
  );
};

export default BlockItem8;
