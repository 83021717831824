import React, { useEffect, useState } from "react";
import BlockItem from "./blocks-type1/BlockItem";
import BlockItem7 from "./blocks-type1/BlockItem7";
import BlockItem8 from "./blocks-type1/BlockItem8";
import BlockItem9 from "./blocks-type1/BlockItem9";
import { isIterableArray, slidersIntervals } from "./utils";
import Slider from "react-slick";
import { useRef } from "react";
import { Link } from "react-router-dom";

const BlockSliderType1 = ({ data }) => {

  const url_list = {
    animation: data.url_animation ? process.env.REACT_APP_BACKEND + data.url_animation : '',
    gif: data.url_gif ? process.env.REACT_APP_BACKEND + data.url_gif : '',
    icon: data.url_icon ? process.env.REACT_APP_BACKEND + data.url_icon : '',
    picture: data.url_picture ? process.env.REACT_APP_BACKEND + data.url_picture : '',
    video: data.url_video ? process.env.REACT_APP_BACKEND + data.url_video : '',
  }

  const settings = {
    infinite: true,
    dots: false,
    arrows: false,
    // autoplay: false,
    autoplay: data.autoscroll,
    autoplaySpeed: data.autoscroll ? slidersIntervals.autoscroll : false, // e.g. 5000
    slidesToShow: data.block_first ? 2 : 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: data.block_first ? 1 : 2,
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  const sliderRef = useRef(null)

  const [blocksArray, setBlocksArray] = useState([]);

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = (e) => {

    // если нет фикс блока ничего не делаем
    if (!data.block_first) {
      setBlocksArray(data.blocks_info)
      return
    }

    const viewport_w = window.innerWidth

    // если большой экран - обрезаем первый элемент  
    if (viewport_w > 1110) {
      setBlocksArray(data.blocks_info?.slice(1, data.blocks_info.length))
    }
    else {
      // console.log('mobile', data.blocks_info)
      // если маленький экран то возвражаем все блоки в слайдер
      setBlocksArray(data.blocks_info)
    }

  }

  const RenderCurrentBlock = ({ block, isFixed }) => (
    <React.Fragment>
      {block.type < 7 && <BlockItem url_list={url_list} data={block} />}
      {block.type === 7 && <BlockItem7 url_list={url_list} data={block} />}
      {block.type === 8 && <BlockItem8 isFixed={isFixed} url_list={url_list} data={block} />}
      {block.type === 9 && <BlockItem9 url_list={url_list} data={block} />}
    </React.Fragment>
  )

  const handlePrev = () => {
    if (sliderRef.current) sliderRef.current.slickPrev();
  }
  const handleNext = () => {
    if (sliderRef.current) sliderRef.current.slickNext();
  }

  return (
    <div className="block-slider">
      <div >
        <div className="glide__arrows">
          <h2 className="products-slider__title">
            <Link
              to={data.link ? data.link.replace("https://galaktika.me", "") : '#'}
              rel={data.nofollow ? 'follow' : 'nofollow'}
            >
              <img
                src={process.env.REACT_APP_BACKEND + data.url_icon + data.icon}
                alt=""
              />{" "}
              {data.name}
            </Link>
          </h2>
          <button
            className="glide__arrow glide__arrow--prev"
            onClick={handlePrev}
          >
            <i className="icon-arrow-left2"></i>
          </button>
          <button
            className="glide__arrow glide__arrow--next"
            onClick={handleNext}
          >
            <i className="icon-arrow-right2"></i>
          </button>
        </div>


        {
          isIterableArray(blocksArray) ?
            data.block_first ?
              // вариант с закрепленным первым блоком
              <div className="row" style={{ margin: ' 0 -4px 0 0 ' }}>
                <div className="col-md-6 col-xl-4  pl-0 d-none d-md-block" style={{ paddingRight: 8 }}>
                  <RenderCurrentBlock block={data.blocks_info[0]} isFixed={true} />
                </div>
                <div className="col-12 col-md-6 col-xl-8 p-0">
                  <Slider ref={sliderRef} {...settings}>
                    {
                      // isIterableArray(blocksArray) &&
                      blocksArray.map((item, index) => (
                        <RenderCurrentBlock key={index} block={item} />
                      ))
                    }
                  </Slider>
                </div>
              </div>
              :
              // все блоки в слайдере
              <Slider ref={sliderRef} {...settings}>
                {
                  // isIterableArray(blocksArray) &&
                  blocksArray.map((item, index) => (
                    <RenderCurrentBlock key={index} block={item} />
                  ))
                }
              </Slider>
            :
            ''
        }

      </div>
    </div>
  );
};

export default BlockSliderType1;
