import React from 'react'
import { Link } from "react-router-dom"
import { isIterableArray } from "../../block-slider/utils"

// followthere
export const getIsFollow = (value) => {
    if (value === 'yes') return {
        rel: 'follow'
    }
    if (value === 'no') return {
        rel: 'nofollow'
    }
    return {}
}

// для страниц с типом 2 и 3 ( вакансии и контакты )   
export const getLinkByPageType = (type, link) => {
    if (type === 2) return '/vacancy'
    if (type === 3) return '/contact'
    if (type === 5) return `/contact/${link.replace('/info/', '')}` // тип 5 - ссылка на страницу одного тц 
    if (link === '/info/info') return '/info'
    return link
}

// HeaderTopMenuLink - ссылка для меню вверху на десктопе , мобильном меню слева и футтере
export const HeaderTopMenuLink = ({ itemLink, onClick = () => { return false } }) => {
    if (itemLink.link) return (
        <Link
            {...getIsFollow(itemLink.link?.follow)}
            target={itemLink.link?.notGalaxy ? '_blank' : "_self"}
            // to={{ pathname: itemLink.link?.notGalaxy ? itemLink?.link?.url : `/${itemLink?.link?.url}` }}
            to={{ pathname: itemLink?.link?.url }}
            onClick={onClick}
        >
            {
                itemLink.icon &&
                <img className="header-top-menu__icon" src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + itemLink.icon} />
            }
            <span dangerouslySetInnerHTML={{ __html: itemLink.name }} />
        </Link>
    )
    if (itemLink.pageSlug) return (
        <Link
            to={getLinkByPageType(itemLink.pageType, `/info/${itemLink.pageSlug}`)}
            onClick={onClick}
        >
            {
                itemLink.icon &&
                <img className="header-top-menu__icon" src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + itemLink.icon} />
            }
            <span dangerouslySetInnerHTML={{ __html: itemLink.name }} />
        </Link>
    )
    if (itemLink?.name) return <span onClick={onClick} dangerouslySetInnerHTML={{ __html: itemLink.name }} />
    else return ''
}


export const MenuImages = ({ items }) => {
    return (
        items.map(item => (
            <div key={item.id} className='menu-item'>
                {
                    item.name &&
                    <div className="menu-item__btn pb-0">
                        {item.name}
                    </div>
                }
                {
                    isIterableArray(item.images) &&
                    <div className={`menu-item__images ${item?.name ? '' : 'big-images'}`}>
                        {
                            item.images.map((img, key) => (
                                img?.link ?
                                    <Link
                                        key={key}
                                        {...getIsFollow(img?.follow)}
                                        target={img?.notGalaxy ? '_blank' : "_self"}
                                        // to={{ pathname: itemLink.link?.notGalaxy ? itemLink?.link?.url : `/${itemLink?.link?.url}` }}
                                        to={{ pathname: img?.link ? img?.link : '#' }}
                                        className={'img-item'}
                                    >
                                        <img src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + img.url} alt='' />
                                    </Link>
                                    :
                                    <div key={key} className={'img-item'}>
                                        <img src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + img.url} alt='' />
                                    </div>
                            ))
                        }
                    </div>
                }
            </div>
        ))
    )
}
