import React, { useRef, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { isIterableArray } from '../../block-slider/utils'
import PageCategoryFilters from '../filters/PageCategoryFilters'
import ProductCard from '../ProductCard'
import Skeleton from '../Skeleton'

const Catalog = (props) => {

    const {
        state,

        handleClick, //3
        resetFilters, //2
        filterProducts, //1
        changePageAndHandle, //2

        toggleFilter,
        brandCheck,
        onLowerBoundChange,
        onUpperBoundChange,
        onSliderChange,
        filterCheck,
        rangeCheck,
        setSelectedRanges,

        changePage,

        context

    } = props

    // const filterRef = useRef(null)
    // const listRef = useRef(null)

    const params = useParams()

    // useEffect(() => {
    //     window.addEventListener('wheel', (e) => handleScroll(e, isStickyFilter))
    //     return () => {
    //         window.removeEventListener('wheel', handleScroll)
    //     }
    // }, [isStickyFilter]);

    // const handleScroll = (event, isStickyFilter) => {
    //     console.log(event, isStickyFilter)

    //     // console.log(listRef.current.clientY)

    //     if (event.wheelDelta > 0 && isStickyFilter) {

    //         const listCord = listRef.current.getBoundingClientRect()
    //         const filterCord = filterRef.current.getBoundingClientRect()
    //         console.log(listCord)
    //         setIsStickyFilter(false)
    //         setFilterStyle({
    //             position: 'relative',
    //             top: -filterCord.top
    //         })
    //         return
    //     }
    //     const windowInnerHeight = window.innerHeight

    //     console.log(filterRef.current.getBoundingClientRect())

    //     const filterCord = filterRef.current.getBoundingClientRect()
    //     const listCord = listRef.current.getBoundingClientRect()

    //     if (filterCord.bottom <= windowInnerHeight) {
    //         console.log('sticky to bottom')
    //         setFilterStyle({
    //             position: 'sticky',
    //             bottom: 0,
    //             alignSelf: 'flex-end'
    //         })
    //         setIsStickyFilter(true)
    //     }

    //     if (listCord.top === filterCord.top) {
    //         console.log('sticky to top')
    //         console.log('CORD EQUAL')
    //     }

    //     //   const valv = document.querySelector('.catalog-filter').getBoundingClientRect()

    // }

    return (
        <div className={`skeleton-container ${state.isLoading ? 'min-h' : ''}`}>
            <div className="catalog">
                <div className="catalog__settings flex">
                    <div className="filter-selection flex">
                        <div className="filter-mobile">
                            <button className="btn btn-primary" disabled={!isIterableArray(state.filters)} onClick={handleClick}>Фильтры</button>
                        </div>

                        <div className="filter-selection__label">
                            <p>Выбрано товаров: <span>{state.pagination.total}</span></p>
                        </div>
                        <div className='filter-selection__list'>
                            <div className='filter-selection__item'>
                                <button className="filter-selection__btn reset" onClick={resetFilters}>Сбросить</button>
                            </div>
                        </div>
                    </div>
                    <div className="catalog-view">
                        <div className="catalog-view__wrap">
                            <div className="catalog-view__sort">
                                <select
                                    disabled={state.isLoading}
                                    value={state.order_by}
                                    id='order'
                                    className="select-custom"
                                    onChange={() => {
                                        filterProducts()
                                    }}
                                >
                                    <option value='1'> По умолчанию</option>
                                    <option value='2'> От дешевых к дорогим</option>
                                    <option value='3'> От дорогих к дешевым</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="catalog__wrap">
                    <div
                        className={`catalog-filter ${state.isToggleOn ? '' : 'show'}`}
                        style={{
                            transition: 'opacity .4s ease',
                            opacity: state.isLoading ? 0.5 : 1,
                            pointerEvents: state.isLoading ? 'none' : 'auto',
                            // ...filterStyle
                        }}
                    >
                        <div className="catalog-filter__overlay" onClick={handleClick} />

                        <div className="catalog-filter__wrap">
                            <div className="flex catalog-filter__header">
                                <Button variant="link" className="filter_m_toggle" onClick={handleClick}>Фильтры</Button>
                                <div className="filter-selection__label">
                                    <p>Выбрано товаров: <span>{state.pagination.total}</span></p>
                                </div>
                            </div>

                            {/* фильтры  */}
                            <PageCategoryFilters
                                changePageAndHandle={changePageAndHandle}
                                toggleFilter={toggleFilter}
                                brandCheck={brandCheck}
                                onLowerBoundChange={onLowerBoundChange}
                                onUpperBoundChange={onUpperBoundChange}
                                onSliderChange={onSliderChange}
                                filterCheck={filterCheck}
                                rangeCheck={rangeCheck}

                                setSelectedRanges={setSelectedRanges}
                                // 

                                selectedManufacturers={state.selectedManufacturers}
                                selectedFilters={state.selectedFilters}
                                selectedRanges={state.selectedRanges}


                                manufacturers={state.manufacturers}
                                filterToggleOn={state.filterToggleOn}
                                price_to={state.price_to}
                                price_from={state.price_from}
                                min={state.min}
                                max={state.max}
                                filters={state.filters}
                            />
                            {/* фильтры  */}


                            <div className="catalog-filter__footer flex">
                                <div className="filter-selection__label">
                                    <p>Выбрано товаров: <span>{state.pagination.total}</span></p>
                                </div>
                                <Button className="filter-selection__btn reset" onClick={resetFilters}>Сбросить</Button>
                                <Button className="filter-set" onClick={changePageAndHandle}>Показать</Button>
                            </div>
                        </div>
                    </div>
                    <div className="catalog-list"  >
                        {
                            state.isLoading &&
                            <Skeleton isLoading={state.isLoading} />
                        }
                        <div className="catalog-list__wrap" style={{
                            transition: 'opacity .4s ease-in',
                            opacity: state.isLoading ? 0 : 1,
                            pointerEvents: state.isLoading ? 'none' : 'auto'
                        }}>


                            {state.products.map((product, index) => (
                                <ProductCard
                                    key={index}
                                    product={product}
                                    context={context}
                                    isProductsHasVariable={state.isProductsHasVariable}
                                />
                            ))}



                            {
                                state.pagination.lastPage === 0 &&
                                <div className="catalog-list__empty"><p>По данному запросу товаров не найдено!</p></div>
                            }
                        </div>
                        <div className="catalog-list__navigation" style={{
                            transition: 'opacity .4s ease',
                            opacity: state.isLoading ? 0 : 1,
                            pointerEvents: state.isLoading ? 'none' : 'auto'
                        }}>
                            {
                                state.pagination.lastPage > 1 &&
                                <div className="pagination">
                                    <ul className="pagination__wrap">
                                        <ul className="pag_arrows">
                                            <li className="pagination__item pag_arrows__item bt_pprev">
                                                {state.page === 1 &&
                                                    <span style={{ opacity: .15 }}>
                                                        <i className="i-arrow prev" style={{
                                                            display: 'inline-block',
                                                            transform: 'rotate(90deg) translateY(-14px)'
                                                        }}></i>
                                                        <i className="i-arrow prev"
                                                            style={{ display: 'inline-block' }}></i>
                                                    </span>
                                                }
                                                {state.page !== 1 &&
                                                    <Link className={`link`} to={`/catalog/${params.slug}`} onClick={() => {
                                                        changePage(1)
                                                    }}>
                                                        <i className="i-arrow prev"
                                                            style={{ display: 'inline-block', transform: 'rotate(90deg) translateY(-14px)' }}></i>
                                                        <i className="i-arrow prev" style={{ display: 'inline-block' }}></i>
                                                    </Link>
                                                }
                                            </li>
                                            <li className="pagination__item pag_arrows__item bt_prev">
                                                {state.page === 1 &&
                                                    <span style={{ opacity: .15 }}>
                                                        <i className="i-arrow prev"><span></span></i>
                                                    </span>
                                                }
                                                {state.page !== 1 &&
                                                    <Link className={`link`} to={`/catalog/${params.slug}`} onClick={() => {
                                                        changePage(state.page > 1 ? state.page - 1 : 1)
                                                    }}>
                                                        <i className="i-arrow prev"><span></span></i>
                                                    </Link>
                                                }
                                            </li>
                                        </ul>
                                        <ul className="pag_list">


                                            {state.page > 2 && state.pagination.lastPage > 3 &&
                                                <li className="pagination__item">
                                                    <Link className="link" to={`/catalog/${params.slug}`} onClick={() => {
                                                        changePage(1)
                                                    }}>
                                                        <b>1</b>
                                                    </Link>
                                                </li>
                                            }

                                            {state.page > 3 && state.pagination.lastPage > 4 &&
                                                <li className="pagination__item"><b>...</b></li>
                                            }


                                            {state.page === state.pagination.lastPage && state.pagination.lastPage > 2 &&
                                                <li className="pagination__item">
                                                    <Link className="link" to={`/catalog/${params.slug}`} onClick={() => {
                                                        changePage(state.page - 2)
                                                    }}>
                                                        <b>{state.page * 1 - 2}</b>
                                                    </Link>
                                                </li>
                                            }
                                            {state.page > 1 &&
                                                <li className="pagination__item">
                                                    <Link className="link" to={`/catalog/${params.slug}`} onClick={() => {
                                                        changePage(state.page * 1 - 1)
                                                    }}>
                                                        <b>{state.page * 1 - 1}</b>
                                                    </Link>
                                                </li>
                                            }
                                            <li className="pagination__item active">
                                                <span className="link">
                                                    <b>{state.page}</b>
                                                </span>
                                            </li>
                                            {state.page < state.pagination.lastPage &&
                                                <li className="pagination__item">
                                                    <Link className="link" to={`/catalog/${params.slug}`} onClick={() => {
                                                        changePage(state.page * 1 + 1)
                                                    }}>
                                                        <b>{state.page * 1 + 1}</b>
                                                    </Link>
                                                </li>
                                            }
                                            {state.page === 1 && state.pagination.lastPage > 2 &&
                                                <li className="pagination__item">
                                                    <Link className="link" to={`/catalog/${params.slug}`} onClick={() => {
                                                        changePage(state.page * 1 + 2)
                                                    }}>
                                                        <b>{state.page * 1 + 2}</b>
                                                    </Link>
                                                </li>
                                            }

                                            {state.page < state.pagination.lastPage * 1 - 2 && state.pagination.lastPage > 4 &&
                                                <li className="pagination__item"><b>...</b></li>
                                            }

                                            {state.page < state.pagination.lastPage * 1 - 1 && state.pagination.lastPage > 3 &&
                                                <li className="pagination__item">
                                                    <Link className="link" to={`/catalog/${params.slug}`} onClick={() => {
                                                        changePage(state.pagination.lastPage)
                                                    }}>
                                                        <b>{state.pagination.lastPage}</b>
                                                    </Link>
                                                </li>
                                            }

                                        </ul>
                                        <ul className="pag_arrows">
                                            <li className="pagination__item pag_arrows__item bt_next">
                                                {state.page !== state.pagination.lastPage &&
                                                    <Link className={`link`} to={`/catalog/${params.slug}`} onClick={() => {
                                                        changePage(state.page < state.pagination.lastPage ? state.page * 1 + 1 : state.pagination.lastPage)
                                                    }}>
                                                        <i className="i-arrow next"><span></span></i>
                                                    </Link>
                                                }
                                                {state.page === state.pagination.lastPage &&
                                                    <span className={`link`} style={{ opacity: .15 }}>
                                                        <i className="i-arrow next"><span></span></i>
                                                    </span>
                                                }
                                            </li>
                                            <li className="pagination__item pag_arrows__item bt_nnext">
                                                {state.page !== state.pagination.lastPage &&
                                                    <Link className={`link`} to={`/catalog/${params.slug}`} onClick={() => {
                                                        changePage(state.pagination.lastPage)
                                                    }}>
                                                        <i className="i-arrow next" style={{ display: 'inline-block' }}></i>
                                                        <i className="i-arrow next"
                                                            style={{ display: 'inline-block', transform: 'rotate(-90deg) translateY(-14px)' }}></i>
                                                    </Link>
                                                }
                                                {state.page === state.pagination.lastPage &&
                                                    <span className={`link`} style={{ opacity: .15 }}>
                                                        <i className="i-arrow next"
                                                            style={{ display: 'inline-block' }}></i>
                                                        <i className="i-arrow next" style={{
                                                            display: 'inline-block',
                                                            transform: 'rotate(-90deg) translateY(-14px)'
                                                        }}></i>
                                                    </span>
                                                }
                                            </li>
                                        </ul>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Catalog
