import React, { useContext, useEffect, useState } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { isIterableArray } from '../../block-slider/utils'
import CartCtx from '../../CartCtx';

const ModalMain = ({ isModalMain, closeMainModal, handleSelectCenter }) => {

    const { selectedCenter, tcData } = useContext(CartCtx);

    const [selectedCityCentres, setSelectedCityCentres] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);

    useEffect(() => {

        const findActiveCenter = () => {
            if (isModalMain) {
                if (selectedCenter) {
                    // console.log('findActiveCenter', selectedCityCentres)
                    // tcData
                    const finded = tcData.list.reduce((acc, item) => {
                        if (item.ts_list.find(item => item.id == selectedCenter.id)) {
                            acc = item
                        }
                        return acc
                    })

                    console.log(finded)
                    setSelectedCity(finded.id)
                    setSelectedCityCentres(finded.ts_list)
                }
            }
            else {
                setSelectedCityCentres([])
            }

        }
        findActiveCenter()

        return () => {
            setSelectedCity(null)
            setSelectedCityCentres([])
        }
    }, [isModalMain, selectedCenter]);

    return (
        <Modal
            show={isModalMain}
            onHide={closeMainModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="center-select__modalMain"
        >
            <Modal.Header closeButton className='justify-content-start'>
                <div className='center-select__modalMain-header'>
                    <h5> {tcData?.title}</h5>
                    <p> {tcData?.text}</p>
                </div>
            </Modal.Header>
            <Modal.Body className='px-0'>
                <Row noGutters>
                    <Col xs={5}>
                        <div className="center-select__modalMain--select">
                            <h6>Город</h6>
                            <div className='center-select__modalMain--select-city'>
                                {
                                    isIterableArray(tcData?.list) &&
                                    tcData.list.map((item, key) => (
                                        <div
                                            key={key}
                                            className={`item-city ${item.id == selectedCity ? 'active' : ''}`}
                                            onClick={() => {
                                                setSelectedCityCentres(item.ts_list)
                                                setSelectedCity(item.id)
                                            }}
                                        >
                                            {item.title}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </Col>
                    <Col xs={7}>
                        <div className="center-select__modalMain--select ">
                            <h6 className='d-none d-sm-block'>Адрес Торгового Центра</h6>
                            <h6 className='d-sm-none'>Адрес ТЦ</h6>
                            {
                                isIterableArray(selectedCityCentres) ?
                                    selectedCityCentres.map((item, key) => (
                                        <div
                                            key={key}
                                            className={`item-address ${item.id == selectedCenter?.id ? 'active' : ''}`}
                                            onClick={() => handleSelectCenter(item)}
                                        >
                                            {item.id == selectedCenter?.id ? <i className='i-check' /> : ''}
                                            {item.address}
                                        </div>
                                    ))
                                    :
                                    <small className='text-muted'>Выберите город</small>
                            }
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalMain
