import React, { Component } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
// import Badges from "../../main/Badges";
// import WishList from "../../main/ModalWishlist";

// import { Button } from 'react-bootstrap';
import CartCtx from '../../CartCtx'
import Product from "./Product";
// import TinySlider from 'tiny-slider-react';

export default class SearchPromo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: queryString.parse(window.location.search).q || '',
            slug_stock: queryString.parse(window.location.search).s || '',
            page: this.props.match.params.page,
            products: null,
            pagination: null,
            message: ''
        };
        document.title = 'Поиск акционных товаров | Галактика';
    }
    static contextType = CartCtx;

    changePage(num) {
        this.setState({
            page: num
        })
        setTimeout(() => {
            this.fetchProducts()
        }, 300)
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.search !== this.state.search) {
            this.setState({
                search: queryString.parse(window.location.search).q || '',
                slug_stock: queryString.parse(window.location.search).s || '',
                page: this.props.match.params.page,
            });
            this.fetchProducts()
        }
    }
    componentDidMount() {
        this.fetchProducts()
    }

    fetchProducts() {
        const selectedCenterId = this.context.selectedCenter ? this.context.selectedCenter.id : false
        const postData = {
            'str': this.state.search,
            'page': this.props.match.params.page,
            'slug_stock': this.state.slug_stock,
            tc_id: selectedCenterId
        }
        // console.log(postData)
        axios.post(`${process.env.REACT_APP_API_BASE}/new-stocks/stock-search-product`, postData)
            .then(response => {
                this.setState({
                    products: response.data.data[0] || null,
                    pagination: response.data.data[1] || null
                })
                if (this.state.products !== null) {
                    this.setState({
                        message: ``
                    })
                } else {
                    this.setState({
                        message: `По запросу "${this.state.search}" ничего не найдено`
                    })
                }
            })
            .catch(err => {
                console.error(err);

            })
    }

    static contextType = CartCtx;
    render() {
        return (
            <>
                <div className="container catalog">
                    {this.state.products === null &&
                        <>
                            <h2 style={{ textAlign: 'center', display: 'block', color: '#999' }}>
                                Акционных товаров по запросу не найдено
                            </h2>
                            <h4>
                                {this.state.message}
                            </h4>
                        </>
                    }

                    {this.state.products !== null &&
                        <h2 style={{ textAlign: 'center', display: 'block', color: '#999', marginTop: 30 }}>
                            Поиск акционных товаров по запросу: {this.state.search}
                        </h2>
                    }
                    {this.state.products !== null &&
                        <div className="catalog__wrap" style={{ paddingTop: 30, minHeight: 540 }}>
                            <div className="catalog-list" style={{ width: '100%' }}>
                                <div className="catalog-list__wrap">

                                    {this.state.products.map((p, i) => (
                                        <Product product={p} key={i} />
                                    ))}
                                </div>
                                {this.state.pagination !== null &&
                                    <div className="catalog-list__navigation">
                                        {this.state.pagination.lastPage > 1 &&
                                            <div className="pagination">
                                                <ul className="pagination__wrap">
                                                    <li className="pagination__item">
                                                        <Link className="link" to={`/search/${this.state.page}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page > 1 ? this.state.page - 1 : 1) }}>
                                                            <i className="i-arrow prev"><span></span></i>
                                                        </Link>
                                                    </li>

                                                    {this.state.page > 2 && this.state.pagination.lastPage > 3 &&
                                                        <li className="pagination__item">
                                                            <Link className="link" to={`/search/1?q=${this.state.search}`} onClick={() => { this.changePage(1) }}>
                                                                <b>1</b>
                                                            </Link>
                                                        </li>
                                                    }

                                                    {this.state.page > 3 && this.state.pagination.lastPage > 4 &&
                                                        <li className="pagination__item"><b>...</b></li>
                                                    }



                                                    {this.state.page === this.state.pagination.lastPage && this.state.pagination.lastPage > 2 &&
                                                        <li className="pagination__item">
                                                            <Link className="link" to={`/search/${this.state.page * 1 - 2}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page - 2) }}>
                                                                <b>{this.state.page * 1 - 2}</b>
                                                            </Link>
                                                        </li>
                                                    }
                                                    {this.state.page > 1 &&
                                                        <li className="pagination__item">
                                                            <Link className="link" to={`/search/${this.state.page * 1 - 1}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page * 1 - 1) }}>
                                                                <b>{this.state.page * 1 - 1}</b>
                                                            </Link>
                                                        </li>
                                                    }
                                                    <li className="pagination__item active">
                                                        <span className="link">
                                                            <b>{this.state.page}</b>
                                                        </span>
                                                    </li>
                                                    {this.state.page < this.state.pagination.lastPage &&
                                                        <li className="pagination__item">
                                                            <Link className="link" to={`/search/${this.state.page * 1 + 1}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page * 1 + 1) }}>
                                                                <b>{this.state.page * 1 + 1}</b>
                                                            </Link>
                                                        </li>
                                                    }
                                                    {this.state.page === 1 && this.state.pagination.lastPage > 2 &&
                                                        <li className="pagination__item">
                                                            <Link className="link" to={`/search/${this.state.page * 1 + 2}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page * 1 + 2) }}>
                                                                <b>{this.state.page * 1 + 2}</b>
                                                            </Link>
                                                        </li>
                                                    }

                                                    {this.state.page < this.state.pagination.lastPage * 1 - 2 && this.state.pagination.lastPage > 4 &&
                                                        <li className="pagination__item"><b>...</b></li>
                                                    }

                                                    {this.state.page < this.state.pagination.lastPage * 1 - 1 && this.state.pagination.lastPage > 3 &&
                                                        <li className="pagination__item">
                                                            <Link className="link" to={`/search/${this.state.pagination.lastPage}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.pagination.lastPage) }}>
                                                                <b>{this.state.pagination.lastPage}</b>
                                                            </Link>
                                                        </li>
                                                    }
                                                    <li className="pagination__item">
                                                        <Link className="link" to={`/search/${this.state.page < this.state.pagination.lastPage ? this.state.page * 1 + 1 : this.state.pagination.lastPage}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page < this.state.pagination.lastPage ? this.state.page * 1 + 1 : this.state.pagination.lastPage) }}>
                                                            <i className="i-arrow next"><span></span></i>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </>
        );
    }
}
