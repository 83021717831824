

import React, { useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap';
import Slider from 'react-slick';
import { isIterableArray } from '../../../block-slider/utils';

import fontColorContrast from 'font-color-contrast';


const CatalogColorChar = ({ character, handleSelectChar, isLoadingCard, isProductsHasVariable }) => {

    let sliderRef = useRef(null);

    const sliderSettings = {
        infinite: false,
        dots: false,
        arrows: true,
        autoplay: false,
        draggable: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        // variableWidth: true,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
        ]
    };

    useEffect(() => {
        if (isIterableArray(character?.value_arr)) {
            const selectedIndex = character.value_arr.findIndex(item => item.selected === true)
            if (selectedIndex > 4) sliderRef.slickGoTo(selectedIndex)
        }
    }, [character]);


    const getColorStyle = (color_value, colorType) => {

        const imageStyles = { backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }

        const backgroundImage =
            colorType === 'color' ?
                `url(${process.env.REACT_APP_BACKEND + 'uploads/products/categories_filters/' + color_value.image_url})`
                :
                colorType === 'image' ?
                    `url(${process.env.REACT_APP_BACKEND + 'uploads/products/main/' + color_value.image_url})`
                    :
                    'none'

        const borderStyles = colorType === 'image' ?
            {
                border: '1px solid #acacac'
            }
            :
            {
                border: '1px solid',
                borderColor: color_value.border
            }




        if (color_value.multicolor) return ({ backgroundImage: 'url(/img/multicolor.png)', ...imageStyles, ...borderStyles })
        if (color_value.nocolor) return ({ backgroundImage: 'url(/img/nocolor.jpg)', ...imageStyles, ...borderStyles })
        if (color_value.gradient) return ({ backgroundImage: color_value.gradient, ...borderStyles })

        if (color_value.image_url) return ({ backgroundImage: backgroundImage, ...imageStyles, ...borderStyles })

        return ({ backgroundColor: color_value.color, ...borderStyles })
    }


    // если картинка и страница товара - ширина 60, иначе меньше 
    const getButtonSize = {
        width: 38,
        height: 30
    }

    return (
        character ?
            <div className='char-list catalog'>

                <Slider ref={slider => {
                    sliderRef = slider;
                }}
                    {...sliderSettings}>
                    {
                        isIterableArray(character.value_arr) && character.value_arr.map((item, index) => (
                            <div key={index} style={{
                                width: getButtonSize.width
                            }}
                                className={`type-${character.colorType}`}
                            >
                                {
                                    <Button
                                        variant='simple'
                                        className={`char-list__item-color type-${character.colorType}  ${!item.available ? 'unavailable' : ''} ${item.selected ? 'selected' : ''} `}
                                        title={item.title}
                                        disabled={item.selected}
                                        onClick={() => handleSelectChar({
                                            filter_id: character.filter_id,
                                            filter_option_id: item.filter_option_id,
                                            title: item.title
                                        })}
                                        // onClick={() => handleSelectChar({ [`filter_${character.filter_id}`]: item.filter_option_id })}
                                        style={{
                                            // width: 27,
                                            width: '100%',
                                            height: getButtonSize.height,
                                            // marginRight: 5,
                                            borderRadius: 4,
                                            transition: 'opacity .4s ease',
                                            opacity: isLoadingCard ? 0.5 : 1,
                                            pointerEvents: isLoadingCard ? 'none' : 'auto',

                                            ...getColorStyle(item, character.colorType)
                                        }}
                                    >
                                        {
                                            isIterableArray(item.badges) &&
                                            item.badges.map((badge, index) => (
                                                <div
                                                    key={index}
                                                    className='badge'
                                                    style={{
                                                        top: index * 4 - 4,
                                                        zIndex: index + 1,
                                                        backgroundColor: badge.color,
                                                        color: fontColorContrast(badge.color)
                                                    }}>
                                                    {badge.text}
                                                </div>
                                            ))
                                        }
                                    </Button>

                                }

                            </div>
                        ))
                    }
                </Slider >

            </div>
            :
            // <div className={isProductsHasVariable ? 'no-variant-but-height 1' : 'no-variant-but-height 2'} />
            <div className={isProductsHasVariable ? 'no-variant-but-height' : ''} />
        // <div className={'no-variant-but-height'} />
    )
}

export default CatalogColorChar