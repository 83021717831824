import React from 'react'
import { Carousel } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { isIterableArray } from '../../block-slider/utils'

// тип 1 
// слайдер или 1 картинка
const Type1 = ({ data }) => {

    const interval = data.autoplay ? 4000 : null
    const nextIcon = <span className="arrow-icon next" />
    const prevIcon = <span className="arrow-icon prev" />

    return (
        <div className='buildedPage-type_1 type-carousel'>
            {data.title && <div className='title' dangerouslySetInnerHTML={{ __html: data.title }} />}
            {
                isIterableArray(data.images) ?
                    data.images.length === 1 ?
                        <div className='once-image'>
                            {
                                data.images[0]?.url ?
                                    <Link
                                        target={data.images[0].src.startsWith('http') ? '_blank' : '_self'}
                                        to={{ pathname: data.images[0].url }}
                                    >
                                        <img
                                            src={process.env.REACT_APP_BACKEND + '/uploads/pages/blocks/' + data.images[0].src}
                                            alt={data.images[0].name}
                                        />
                                    </Link>
                                    :
                                    <img
                                        src={process.env.REACT_APP_BACKEND + '/uploads/pages/blocks/' + data.images[0].src}
                                        alt={data.images[0].name}
                                    />
                            }
                        </div>
                        :
                        <Carousel
                            className='buildedPage-type_1__carousel'
                            style={{ maxWidth: data.setting.widthSlider ? `${data.setting.widthSlider}px` : 'none' }}
                            nextIcon={nextIcon}
                            prevIcon={prevIcon}
                            interval={interval}
                        >
                            {
                                isIterableArray(data.images) && data.images.map((banner, index) => {
                                    return (
                                        <Carousel.Item key={index}>
                                            {
                                                banner?.url ?
                                                    <Link
                                                        target={banner.src.startsWith('http') ? '_blank' : '_self'}
                                                        to={{ pathname: banner.url }}
                                                    >
                                                        <img
                                                            src={process.env.REACT_APP_BACKEND + '/uploads/pages/blocks/' + banner.src}
                                                            alt={banner.name}
                                                        />
                                                    </Link>
                                                    :
                                                    <img
                                                        src={process.env.REACT_APP_BACKEND + '/uploads/pages/blocks/' + banner.src}
                                                        alt={banner.name}
                                                    />
                                            }
                                        </Carousel.Item>
                                    )
                                })
                            }
                        </Carousel>
                    :
                    ''
            }
            {data.text && <div className='text' dangerouslySetInnerHTML={{ __html: data.text }} />}
        </div>
    )
}

export default Type1
