import React from 'react'

const SkeletonVacancy = () => {
    return (
        <div className='skeleton'>
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
        </div>
    )
}

export default SkeletonVacancy
