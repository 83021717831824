//React
import React, { Component } from "react";
import { Container, Button /*, Row, Col, Nav, Button*/ } from "react-bootstrap";

//My Components
import Slider from "./Slider";
import DropdownProductsNew from "../main/DropdownProductsNew";
import HomepageCatalog from "../main/HomepageCatalog";

import MoreProducts2 from "../main/ReadMore2";
import Badges from "../main/Badges";
import WishList from "../main/ModalWishlist";

// import SliderLine1 from '../home2/SliderLine1';
// import PromoLine2 from '../home2/PromoLine2';
import PromoLine3 from "../home2/PromoLine3";
import PromoLine4 from "../home2/PromoLine4";

//SCSS
import "../../scss/components/pages/home/__pageHome.scss";
import "../../scss/components/pages/home/dropdownProducts.scss";
import "../../scss/components/pages/home2/__home2.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import CartCtx from "../CartCtx";

// import SliderWrapper from "../block-slider/SliderWrapper.jsx";
// import { isIterableArray } from "../block-slider/utils";
import SliderFetchWrapper from "../block-slider/SliderFetchWrapper";

class PageHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOnHover: true,
      more: false,
      banners: [],
      banner2: null,
      banner3: null,
      products: [],
      productsNP: {},
      productsHN: {},
      productsOG: {},
      isSale: false,
      saleData: {
        info: {},
        banners: [],
        banners_top: [],
        banners_mid_l: [],
        banners_mid_rt: [],
        banners_mid_rb: [],
        banners_bottom: [],
        products: [],
        pages: {},
      },
      stockButtons: [],
      // slidersData: [],
      isSliderFetched: false,
    };
    this.onHover = this.onHover.bind(this);
    this.onOut = this.onOut.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  mouseInsideTimeout = null;

  showMenu() {
    this.setState((prevState) => ({
      isToggleOnHover: !prevState.isToggleOnHover,
    }));
    //this.catalogBtn.click()
    document.getElementById("catalog-btn").click();
  }

  onHover() {
    this.mouseInsideTimeout = setTimeout(this.showMenu, 300);
  }
  onOut() {
    clearTimeout(this.mouseInsideTimeout);
  }
  loadMore() {
    let newPage = this.state.saleData.pages.page + 1;
    if (newPage <= this.state.saleData.pages.lastPage) {
      document.querySelector("#sale-dd>.readmore").removeAttribute("style");
      axios
        .post(`${process.env.REACT_APP_API_BASE}/pages/stock`, {
          page: newPage,
        })
        .then((response) => {
          let arr = this.state.saleData.products;
          arr = arr.concat(response.data.data[0].products);
          let obj = {
            ...this.state.saleData,
            ...{
              pages: response.data.data[0].pages,
              products: arr,
            },
          };
          this.setState({
            saleData: obj,
          });
        })
        .catch(error => {
          console.log(error)
        });
    }
  }

  componentDidMount() {
    document.querySelectorAll('meta[name="robots"]').forEach((el) => {
      document.querySelector("head").removeChild(el);
    });
    axios
      .get(`${process.env.REACT_APP_API_BASE}/new-stocks/stock-index`)
      .then((response) => {
        this.setState(
          {
            stockButtons: response.data.data.buttons,
          },
          () => {
            // console.log(this.state.stockButtons)
          }
        );
      })
      .catch(error => {
        console.log(error)
      });
    document.title = "Галактика | Главная";
    axios
      .get(`${process.env.REACT_APP_API_BASE}/pages`)
      .then((response) => {
        // console.log('asdasdsadasd asdasd a', response)
        if (response.data.data.stockBlockAll.length !== 0) {
          this.setState({
            banners: response.data.data.stockBlockAll[4],
            banner2: response.data.data.stockBlockAll[5][0],
            banner3: response.data.data.stockBlockAll[6][0],
            // products: response.data.data.indexAll.productBlock[0],
            // productsNP: response.data.data.indexAll.productBlock[0][0],
            // productsOG: response.data.data.indexAll.productBlock[0][1],
            // productsHN: response.data.data.indexAll.productBlock[0][2],
          });
          this.setState({
            isSale: true,
            saleData: {
              info: response.data.data.stockBlockAll[0],
              products: response.data.data.stockBlockAll[2].products,
              pages: response.data.data.stockBlockAll[2].pages,
              banners_top: response.data.data.stockBlockAll[1].filter(
                (banner) => {
                  return banner.baner_type === 1;
                }
              ),
              banners_mid_l: response.data.data.stockBlockAll[1].filter(
                (banner) => {
                  return banner.baner_type === 2;
                }
              ),
              banners_mid_rt: response.data.data.stockBlockAll[1].filter(
                (banner) => {
                  return banner.baner_type === 3;
                }
              ),
              banners_mid_rb: response.data.data.stockBlockAll[1].filter(
                (banner) => {
                  return banner.baner_type === 4;
                }
              ),
              banners_bottom: response.data.data.stockBlockAll[1].filter(
                (banner) => {
                  return banner.baner_type === 5;
                }
              ),
            },
          });
        } else {
          this.setState(
            {
              banners: response.data.data.indexAll.banerList,
              banner2: response.data.data.indexAll.banerList2[0],
              banner3: response.data.data.indexAll.banerList3[0],
              products: response.data.data.indexAll.productBlock[0],
              productsNP: response.data.data.indexAll.productBlock[0][0],
              productsOG: response.data.data.indexAll.productBlock[0][1],
              productsHN: response.data.data.indexAll.productBlock[0][2],
            },
            () => {
              // console.log(this.state.productsNP)
            }
          );
        }

        // document.querySelectorAll('.will-animate').forEach( (el, i) => {
        //     el.classList.add('fadeInBlock')
        // })
      })
      .catch((error) => {
        console.error(error);
      });


    // const selectedCenterId = this.context.selectedCenter ? this.context.selectedCenter.id : false
    // axios
    //   .get(`${process.env.REACT_APP_API_BASE}/pages/page-slider/1/${selectedCenterId}`)
    //   .then((response) => {
    //     console.log(response.data);
    //     if (isIterableArray(response.data.data)) {
    //       this.setState({
    //         slidersData: response.data.data,
    //       });
    //     }
    //   })
    //   .catch((err) => console.log(err))
    //   .finally(() => {
    //     // акции появляются после слайдера
    //     this.setState({
    //       isSliderFetched: true,
    //     });
    //   });
  }

  getLink(linkObj) {
    if (typeof linkObj === "string") {
      if (linkObj.indexOf("https://galaktika.me") !== -1)
        return linkObj.replace("https://galaktika.me/", "/");
      if (linkObj.indexOf("https://avoidrandomusers99.galaktika.me/") !== -1)
        return linkObj.replace("https://avoidrandomusers99.galaktika.me/", "/");
      return "/" + linkObj;
    }

    if (linkObj.type === 'link') return linkObj.name_slug.replace("https://galaktika.me", "")

    if (!linkObj || (linkObj.type !== "prod" && linkObj.type !== "cat"))
      return "/";

    if (linkObj.type === "prod") return "/product/" + linkObj.name_slug;
    if (linkObj.type === "cat" && linkObj.type_cat === "have_cat")
      return "/category/" + linkObj.name_slug;
    if (linkObj.type === "cat" && linkObj.type_cat === "no_have_cat")
      return "/catalog/" + linkObj.name_slug;
  }

  getDays(endString) {
    let now = new Date();
    let end = new Date(endString);
    return end > now ? Math.floor((end - now) / (24 * 3600 * 1000)) : 0;
  }

  getDaysWord(w) {
    if (w === 1 || (w % 10 === 1 && w !== 11)) return "День";
    else if ((w > 0 && w < 5) || (w % 10 > 1 && w % 10 < 5 && w > 20))
      return "Дня";
    else return "Дней";
  }

  getRestWord(w) {
    if (w === 1 || (w % 10 === 1 && w !== 11)) return "Остался";
    else return "Осталось";
  }

  static contextType = CartCtx;

  render() {
    // const clickBtn = this.state.isToggleOn;
    return (
      <>
        <main className="home">
          <div>
            <div className="catalog-banner">
              <div
                className={`homepage-catalog `}
                onMouseEnter={this.onHover}
                onMouseLeave={this.onOut}
              >
                <HomepageCatalog />
              </div>
              <div className="banners">
                <div className="banners-plash">
                  <div className="banners-plash_wrap f fw">
                    {this.state.stockButtons.length > 0 &&
                      this.state.stockButtons.map((b, i) => (
                        <Link
                          to={`${this.getLink(b.link)}`}
                          key={i}
                          className="banners-plash_item f gap10"
                          style={{
                            background: b.background_color,
                          }}
                        >
                          <img
                            src={`${process.env.REACT_APP_BACKEND}/uploads/sp/index_blocks/${b.image_url}`}
                            alt=""
                            className="wh30 r8 cover"
                          />
                          <p
                            dangerouslySetInnerHTML={{ __html: b.text_link }}
                            style={{
                              color: b.text_link_color,
                            }}
                          />
                          {b.date_end_offer && (
                            <div className="leftime tac">
                              {this.getRestWord(this.getDays(b.date_end_offer))}
                              <div className="h3" style={{ color: b.color_day }}>
                                {this.getDays(b.date_end_offer)}
                              </div>
                              {this.getDaysWord(this.getDays(b.date_end_offer))}
                            </div>
                          )}
                          {!b.date_end_offer && (
                            <div className="leftime tac" style={{ opacity: 0 }}>
                              Осталось
                              <div className="h3">-</div>
                              дней
                            </div>
                          )}
                        </Link>
                      ))}
                  </div>
                </div>
                {this.state.banners.length > 0 && (
                  <Slider items={this.state.banners} />
                )}
                <div className="banners-bottom">
                  {this.state.banner2 && this.state.banner2 !== null && (
                    <Link
                      to={this.getLink(this.state.banner2.baner_link)}
                      className="banners-bottom__item"
                    >
                      <img
                        src={
                          process.env.REACT_APP_BACKEND +
                          "uploads/home_banners/" +
                          this.state.banner2.baner_url
                        }
                        alt={this.state.banner2.name}
                      />
                    </Link>
                  )}
                  {this.state.banner3 && (
                    <Link
                      to={this.getLink(this.state.banner3.baner_link)}
                      className="banners-bottom__item"
                    >
                      <img
                        src={
                          process.env.REACT_APP_BACKEND +
                          "uploads/home_banners/" +
                          this.state.banner3.baner_url
                        }
                        alt={this.state.banner3.name}
                      />
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <SliderFetchWrapper
              urlFetch={`${process.env.REACT_APP_API_BASE}/pages/page-slider/1`}
              onSliderFetch={() => {
                this.setState({
                  isSliderFetched: true,
                });
              }}
            />

            {!this.state.isSale && this.state.isSliderFetched && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                {this.state.products.length > 0 &&
                  this.state.products.map((block, i) => {
                    return <DropdownProductsNew items={block} key={i} />;
                  })}
              </div>
            )}
            {this.state.isSale && this.state.isSliderFetched && (
              <main className="promo">
                <Container>
                  {/* {this.state.saleData.banners_top.length > 0 &&
                                        <SliderLine1 items={this.state.saleData.banners_top} />
                                    }
                                    {this.state.saleData.banners_mid_l.length > 0 && this.state.saleData.banners_mid_rt.length > 0 && this.state.saleData.banners_mid_rb.length > 0 &&
                                        <PromoLine2 items={this.state.saleData.banners_mid_l} rightTop={this.state.saleData.banners_mid_rt} rightBottom={this.state.saleData.banners_mid_rb} />
                                    } */}

                  {this.state.saleData.info &&
                    this.state.saleData.products.length > 0 && (
                      <PromoLine3
                        products={this.state.saleData.products.slice(0, 20)}
                        name={this.state.saleData.info.name}
                        startDate={this.state.saleData.info.date_start}
                        endDate={this.state.saleData.info.date_end}
                      />
                    )}

                  <div className="all-products">
                    <div className="products-dropdown" id="sale-dd">
                      {this.state.saleData.products.length > 0 && (
                        <MoreProducts2
                          initialHeight={370}
                          overhangSize={220}
                          readMore={(props) => (
                            <div className="products-dropdown__btn">
                              <div
                                className={props.open ? "open" : ""}
                                onClick={props.onClick}
                              >
                                {props.open ? "Свернуть" : "Показать больше"}
                                <i className="i-arrow" />
                              </div>
                            </div>
                          )}
                        >
                          <div className="products-dropdown__wrap">
                            {this.state.saleData.products.map(
                              (product, index) => {
                                if (index > 19)
                                  return (
                                    <div
                                      className="products-dropdown__item"
                                      key={index}
                                    >
                                      <div className="products__wrap">
                                        <div className="btn-toolbar">
                                          <WishList
                                            productId={product.id}
                                            productCount={product.count}
                                          />
                                        </div>
                                        <div className="products-image">
                                          <Link
                                            className="products-image__link"
                                            to={`/product/${product.name_slug}`}
                                          >
                                            <img
                                              src={
                                                process.env.REACT_APP_BACKEND +
                                                "uploads/products/main/" +
                                                product.photo_url
                                              }
                                              alt={
                                                "Изображение товара " +
                                                product.name
                                              }
                                            />
                                          </Link>
                                        </div>

                                        <div className="products-colors">
                                          <div
                                            className={
                                              "products-colors__wrap " +
                                              product.colors
                                            }
                                          ></div>
                                        </div>

                                        <Link
                                          className="products-title"
                                          to={`/product/${product.name_slug}`}
                                          title={product.name}
                                        >
                                          {product.name}
                                        </Link>

                                        <div
                                          className={
                                            "products-price " +
                                            product.sale +
                                            " " +
                                            product.stockStatus
                                          }
                                        >
                                          <div className="producst-price__wrap">
                                            <div className="products-price__old">
                                              {product.priceBadgeStock.price
                                                .discountPrice && (
                                                  <span className="price i-rub d-inline-block">
                                                    {product.priceBadgeStock.price.price.toLocaleString(
                                                      "ru"
                                                    )}
                                                  </span>
                                                )}
                                            </div>
                                            <div className="products-price__main">
                                              <span className="price i-rub">
                                                {product.priceBadgeStock.price
                                                  .discountPrice &&
                                                  product.priceBadgeStock.price.discountPrice.toLocaleString(
                                                    "ru"
                                                  )}
                                                {!product.priceBadgeStock.price
                                                  .discountPrice &&
                                                  product.price.toLocaleString(
                                                    "ru"
                                                  )}
                                              </span>
                                            </div>
                                          </div>
                                          {process.env.REACT_APP_TEST === 1 && (
                                            <div className="products-cart-sm">
                                              {product.count > 0 && (
                                                <>
                                                  {!this.context.isInCart(
                                                    product.id
                                                  ) && (
                                                      <CartCtx.Consumer>
                                                        {({
                                                          cart,
                                                          addProduct,
                                                        }) => (
                                                          <Button
                                                            variant="simple"
                                                            className="add-to-cart"
                                                            onClick={() =>
                                                              addProduct(
                                                                product.id
                                                              )
                                                            }
                                                          >
                                                            <i className="i-cart"></i>
                                                          </Button>
                                                        )}
                                                      </CartCtx.Consumer>
                                                    )}
                                                  {this.context.isInCart(
                                                    product.id
                                                  ) && (
                                                      <CartCtx.Consumer>
                                                        {({
                                                          cart,
                                                          addProduct,
                                                        }) => (
                                                          <Button
                                                            variant="simple"
                                                            className="add-to-cart added"
                                                            disabled
                                                          >
                                                            <i className="i-cart"></i>
                                                          </Button>
                                                        )}
                                                      </CartCtx.Consumer>
                                                    )}
                                                </>
                                              )}
                                            </div>
                                          )}
                                        </div>

                                        <div className="products-stock">
                                          {product.count > 0 && (
                                            <p className="stock-in">
                                              В наличии
                                            </p>
                                          )}
                                          {product.count < 1 && (
                                            <p className="stock-out">
                                              Нет в наличии
                                            </p>
                                          )}
                                        </div>
                                        {product.priceBadgeStock.bages.length >
                                          0 && (
                                            <Badges
                                              items={product.priceBadgeStock}
                                            />
                                          )}
                                      </div>
                                    </div>
                                  );
                                else return false;
                              }
                            )}
                          </div>
                          {this.state.saleData.pages.page !==
                            this.state.saleData.pages.lastPage && (
                              <div className="products-dropdown__btn">
                                <div className="" onClick={this.loadMore}>
                                  Загрузить ещё<i className="i-arrow"></i>
                                </div>
                              </div>
                            )}
                        </MoreProducts2>
                      )}
                    </div>
                  </div>
                  {this.state.saleData.info.condition && (
                    <PromoLine4
                      info={this.state.saleData.info}
                      banners={this.state.saleData.banners_bottom}
                    />
                  )}
                  <br />
                  <br />
                  <br />
                </Container>
              </main>
            )}
          </div>
        </main>
      </>
    );
  }
}

export default PageHome;
