import React from "react";
import SmallBlock from "./SmallBlock";

import "@aarsteinmedia/dotlottie-player";

const BlockItem = ({ data, url_list }) => {

  const keys = data.type < 5 ? ['block1', 'block2', 'block3'] : ['block1', 'block2', 'block3', 'block4'];

  return (
    <li className={`glide__slide slider block type-${data.type}`}>

      {
        keys.map(key => {
          if (data[key]) return <SmallBlock url_list={url_list} data={data[key]} key={key} />
        })
      }
    </li>
  );
};

export default BlockItem;
