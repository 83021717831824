import React from 'react'

const OnceVacancy = ({ data }) => {
    return (
        data ?
            <div className='onceVacancy-content'>
                <div className='vacancy-info'>
                    {/* {
                        data.important ?
                            <div className='vacancy-info_important'>
                                <img
                                    src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + data.important_icon}
                                    alt=''
                                />
                                {data.important_text}
                            </div>
                            :
                            ''
                    } */}

                    <h1>{data.name}</h1>

                    <div className='vacancy-info_city'>
                        {data.address}
                        {
                            data.date ?
                                <>, от {data.date}</>
                                :
                                ''
                        }
                    </div>

                    <div
                        className='vacancy-info_text'
                        dangerouslySetInnerHTML={{ __html: data.text }}
                    />
                </div>
            </div>
            :
            ''
    )
}

export default OnceVacancy
