// React
import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel'

import { Link } from 'react-router-dom';
class SliderPromo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interval: 4000,
            nextIcon: <span className="arrow-icon next" />,
            prevIcon: <span className="arrow-icon prev" />
        }
    }

    handleSelect = (selectedIndex, e) => {
        this.setState({
            index: selectedIndex,
            direction: e.direction
        });
    }

    getLink(linkObj) {

        if (typeof linkObj === 'string') {
            if (linkObj.indexOf("https://galaktika.me") !== -1)
                return linkObj.replace("https://galaktika.me/", '/')
            if (linkObj.indexOf("https://avoidrandomusers99.galaktika.me/") !== -1)
                return linkObj.replace("https://avoidrandomusers99.galaktika.me/", '/')
            return '/' + linkObj
        }

        if (!linkObj || (linkObj.type !== 'prod' && linkObj.type !== 'cat')) return '/'

        if (linkObj.type === 'prod') return '/product/' + linkObj.name_slug
        if (linkObj.type === 'cat' && linkObj.type_cat === 'have_cat') return '/category/' + linkObj.name_slug
        if (linkObj.type === 'cat' && linkObj.type_cat === 'no_have_cat') return '/catalog/' + linkObj.name_slug
    }

    getRelTag(tags) {
        if (tags?.follow === 'yes') return {
            rel: 'follow'
        }
        if (tags?.follow === 'no') return {
            rel: 'nofollow'
        }
        if (tags?.follow === 'null') return {}
    }

    render() {
        const { nextIcon, prevIcon, interval } = this.state;
        return (
            <Carousel id='sliderMain' nextIcon={nextIcon} prevIcon={prevIcon} interval={interval} >
                {
                    this.props.items.map((banner, index) => {
                        return (
                            <Carousel.Item key={index}>
                                <Link
                                    {...this.getRelTag(banner.tags)}
                                    to={this.getLink(banner.link)}
                                >
                                    <img src={process.env.REACT_APP_BACKEND + 'uploads/sp/banners/' + banner.baner_url} alt={banner.name} />
                                </Link>
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel >
        )
    }
}

export default SliderPromo;
