
import React from 'react'

import fontColorContrast from 'font-color-contrast';

import { Button } from 'react-bootstrap'
import { isIterableArray } from '../../../block-slider/utils'

const CatalogChartListMobile = ({ character, handleSelectChar, isLoadingCard }) => {

    const getSelectedTitle = (char_el) => {
        const sel = char_el.value_arr.find(item => item.selected)
        if (sel) {
            return <>: <span> {sel?.title}</span></>
        }
        else return ''
    }
    return (
        <div>
            <div className='char-list catalog'>
                {
                    isIterableArray(character) && character.map((char_el, key) => (
                        <div key={key} className='char-list-container'>
                            <div className='char-list__title'>
                                {char_el.name}{getSelectedTitle(char_el)}
                            </div>
                            <div className='char-list__blocks'>
                                {
                                    isIterableArray(char_el.value_arr) && char_el.value_arr.map((item, index) => (
                                        <Button
                                            className={`char-list__item ${!item.available ? 'unavailable' : ''}  
                                                ${item.selected ? 'selected' : ''}`}
                                            key={index}
                                            disabled={item.selected}
                                            variant='simple'
                                            title={item.title}
                                            onClick={() => handleSelectChar({
                                                filter_id: char_el.filter_id,
                                                filter_option_id: item.filter_option_id,
                                                title: item.title
                                                // [`filter_${char_el.filter_id}`]: item.filter_option_id
                                            })
                                            }

                                            style={{
                                                transition: 'opacity .4s ease',
                                                opacity: isLoadingCard ? 0.5 : 1,
                                                pointerEvents: isLoadingCard ? 'none' : 'auto'
                                            }}
                                        >
                                            {item.text}
                                            {
                                                isIterableArray(item.badges) &&
                                                item.badges.map((badge, index) => (
                                                    <div
                                                        key={index}
                                                        className='badge'
                                                        style={{
                                                            top: index * 4 - 4,
                                                            zIndex: index + 1,
                                                            backgroundColor: badge.color,
                                                            color: fontColorContrast(badge.color)
                                                        }}>
                                                        {badge.text}
                                                    </div>
                                                ))
                                            }
                                        </Button>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default CatalogChartListMobile
