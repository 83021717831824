import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { isIterableArray } from '../../block-slider/utils'

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
// тип 1 
// галерея
const Type1Gallery = ({ data }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const openLightbox = index => {
        setIsOpen(true)
        setPhotoIndex(index)
    }

    const [computedItemsPerRow, setComputedItemsPerRow] = useState(data.setting.itemsPerRow);

    useEffect(() => {
        computeWidth()
        window.addEventListener('resize', computeWidth)
        return () => {
            window.removeEventListener('resize', computeWidth)
        };
    }, []);
    // data.setting.itemsPerRow || 1

    // desk - mobile 
    // 1 - 1
    // 2,3 - 2
    // 4,5 - 3
    // 6 - 4
    // 7 - 5
    // 9 - 7
    const computeWidth = () => {
        const viewport_w = window.innerWidth
        if (viewport_w > 992) {
            // desktop
            setComputedItemsPerRow(data.setting.itemsPerRow || 1)
        }
        else {
            const perRow = Number(data.setting.itemsPerRow)
            if (!perRow || perRow === 1) setComputedItemsPerRow(1)
            if (perRow === 2 || perRow === 3) setComputedItemsPerRow(2)
            if (perRow === 4 || perRow === 5) setComputedItemsPerRow(3)
            if (perRow > 5) setComputedItemsPerRow(perRow - 2)
        }
    }

    return (
        <div className='buildedPage-type_1'>
            {isOpen && (
                <Lightbox
                    imageTitle={data.images[photoIndex].name}
                    mainSrc={process.env.REACT_APP_BACKEND + '/uploads/pages/blocks/' + data.images[photoIndex].src}
                    nextSrc={process.env.REACT_APP_BACKEND + '/uploads/pages/blocks/' + data.images[(photoIndex + 1) % data.images.length].src}
                    prevSrc={process.env.REACT_APP_BACKEND + '/uploads/pages/blocks/' + data.images[(photoIndex + data.images.length - 1) % data.images.length].src}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => {
                        setPhotoIndex((photoIndex + data.images.length - 1) % data.images.length)
                    }
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % data.images.length)
                    }
                />
            )}
            {
                data.setting.galaryPosition === 'bottom' ?
                    <div className='position-bottom'>
                        {data.title && <div className='title' dangerouslySetInnerHTML={{ __html: data.title }} />}
                        <div className={`image-container `} >
                            {
                                isIterableArray(data.images) &&
                                data.images.map((item, key) => (
                                    <div
                                        key={key}
                                        className={`image-container_item ${data.setting.noGutters ? '' : 'with-padding'}`}
                                        style={{
                                            width: `calc(100% /${computedItemsPerRow})`
                                        }}
                                    >
                                        <img onClick={() => openLightbox(key)} src={process.env.REACT_APP_BACKEND + '/uploads/pages/blocks/' + item.src} alt={item.name} />
                                    </div>
                                ))
                            }
                        </div>
                        {data.text && <div className='text' dangerouslySetInnerHTML={{ __html: data.text }} />}
                    </div>
                    :
                    <>
                        {data.title && <div className='title' dangerouslySetInnerHTML={{ __html: data.title }} />}
                        <Row>
                            <Col lg={6} style={{ order: 2 }} className='images-col'>
                                <div className={`image-container`} >
                                    {
                                        isIterableArray(data.images) &&
                                        data.images.map((item, key) => (
                                            <div
                                                key={key}
                                                className={`image-container_item ${data.setting.noGutters ? '' : 'with-padding'}`}
                                                style={{
                                                    width: `calc(100% /${data.setting.itemsPerRow || 1})`
                                                }}
                                            >
                                                <img onClick={() => openLightbox(key)} src={process.env.REACT_APP_BACKEND + '/uploads/pages/blocks/' + item.src} alt={item.name} />
                                            </div>
                                        ))
                                    }
                                </div>
                            </Col>
                            <Col lg={6} style={{
                                order: data.setting.galaryPosition === 'left' ? 3 : 1
                            }}>
                                {data.text && <div className='text' dangerouslySetInnerHTML={{ __html: data.text }} />}
                            </Col>
                        </Row>
                    </>
            }
        </div>
    )
}

export default Type1Gallery
