import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Row, Col, Container, Breadcrumb, Carousel } from "react-bootstrap";

import {
    YMaps,
    Map,
    Placemark,
    ZoomControl,
    FullscreenControl,
    GeolocationControl,
    RouteButton,
    TrafficControl,
    TypeSelector,
} from "react-yandex-maps";
import SideMenu from '../builderPage/SideMenu';
import axios from 'axios';
import RenderBlocks from '../builderPage/blocks/RenderBlocks';
import SkeletonMenu from '../builderPage/SkeletonMenu';
import { setMetaTags } from '../builderPage/BuilderPage';



const OnceContactsPage = () => {

    const { tc_slug } = useParams()

    const mapData = {
        center: [47.943273, 37.771683],
        zoom: 15,
        controls: [],
    };
    const coordinates = [[47.943273, 37.771683]];

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [menuData, setMenuData] = useState(null);
    const [blocksData, setBlocksData] = useState(null);

    useEffect(() => {
        fetchData()
    }, [tc_slug]);

    const fetchData = () => {

        setData(null)
        setIsLoading(true)

        axios.get(`${process.env.REACT_APP_API_BASE}/pages/page/${tc_slug}`)
            .then((response) => {
                console.log('once contact page data response', response)

                if (response.data.meta_tags) setMetaTags(response.data.meta_tags)

                setData(response.data.centers)
                setMenuData(response.data.sidebar)

                // if (isIterableArray(response?.data?.sidebar?.items)) {
                //     const crumbs = createCrumbs(response.data.sidebar.items)
                //     setCrumbs(crumbs)
                // }
                // document.title = response.data.name
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }


    return (
        <main className='page-contact'>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="/"><i className="i-home"></i></Breadcrumb.Item>
                    <Breadcrumb.Item href="/info">
                        {'Информация'}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/contact">
                        Контакты
                    </Breadcrumb.Item>
                    {
                        data &&
                        <li className='breadcrumb-item'>
                            {data.page_name}
                        </li>
                    }
                </Breadcrumb>
                <div className=' buildedPage-container'>
                    {
                        menuData ?
                            <SideMenu menuData={menuData} />
                            :
                            <SkeletonMenu />
                    }
                    {
                        data &&
                        <div className=' buildedPage-content'>
                            <div className="contact">
                                <div className="contact__wrap">
                                    <div className="contact__title">
                                        <h1>{data.page_name}</h1>
                                        <address>{data.address}</address>
                                    </div>
                                    <div className="contact__main">
                                        <Row>
                                            <Col sm={12} md={5} xl={4}>

                                                <div dangerouslySetInnerHTML={{ __html: data.worktime }} />
                                                <div dangerouslySetInnerHTML={{ __html: data.page_character }} />


                                            </Col>
                                            <Col sm={12} md={7} xl={8}>

                                                <div dangerouslySetInnerHTML={{ __html: data.page_info }} />

                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="contact__map">
                                        <YMaps>
                                            <Map defaultState={data.yandexMapData} width={"100%"} height="390px">

                                                <Placemark geometry={data.yandexMapData.center} />
                                                {/* {data.yandexMapData.center.map((coordinate, key) => (
                                                    <Placemark key={key} geometry={coordinate} />
                                                ))} */}
                                                <ZoomControl options={{ float: "right" }} />
                                                <FullscreenControl options={{ float: "right" }} />
                                                <GeolocationControl options={{ float: "left" }} />
                                                <RouteButton options={{ float: "left" }} />
                                                <TrafficControl options={{ float: "right" }} />
                                                <TypeSelector options={{ float: "right" }} />
                                            </Map>
                                        </YMaps>
                                    </div>

                                    {
                                        data?.content &&
                                        <RenderBlocks data={data.content} />
                                    }
                                    {/* <div className="contact__gallery">
 
                                        <h4>Фотогалерея</h4>
                                        {
                                            isIterableArray(data.images) &&
                                            <Carousel
                                                className='contact__gallery-slider'
                                                nextIcon={<span className="arrow-icon next" />}
                                                prevIcon={<span className="arrow-icon prev" />}
                                                interval={null}
                                                indicators={false}
                                            >
                                                {
                                                    data.images.map((banner, index) => {
                                                        return (
                                                            <Carousel.Item key={index}>
                                                                <img src={banner} alt={''} />
                                                            </Carousel.Item>
                                                        )
                                                    })
                                                }
                                            </Carousel >
                                        }
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Container>
        </main>
    )
}

export default OnceContactsPage
