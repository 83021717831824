import React, { useContext } from 'react'
import { Accordion, AccordionContext, useAccordionToggle, Card, Tab, Tabs } from 'react-bootstrap'
import { isIterableArray } from '../../block-slider/utils'

import RenderBlocks from './RenderBlocks'


const TabsAccordion = ({ data }) => {

    function CustomToggle({ children, eventKey, callback }) {

        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => { 
                callback && callback(eventKey)
            },
        );

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <div
                onClick={decoratedOnClick}
                className={`accordion_btn ${isCurrentEventKey ? "active" : 'rotated'}`}
            >
                {children}
            </div>
        );
    }

    return (
        <div className='buildedPage-type_7'>
            {data.title && <div className='title' dangerouslySetInnerHTML={{ __html: data.title }} />}
            {
                data.setting.tabs === 'true' ?
                    <div className='buildedPage-type_7__tabs'>
                        {
                            isIterableArray(data.items) &&
                            <Tabs defaultActiveKey={`tab-0`} id="tab-type7" >
                                {
                                    data.items.map((tab, key) => (
                                        <Tab
                                            eventKey={`tab-${key}`}
                                            title={<div className='tab-title' dangerouslySetInnerHTML={{ __html: tab.title }} />}
                                            key={key}
                                        >
                                            <RenderBlocks data={tab.items} />
                                        </Tab>
                                    ))
                                }
                            </Tabs>
                        }
                    </div>
                    :
                    <>
                        {
                            isIterableArray(data.items) &&
                            <Accordion defaultActiveKey={data.setting.isOpenDefault ? `acc-0` : ''}>
                                {
                                    data.items.map((acc, key) => (
                                        <Card key={key}>
                                            {/* <Accordion.Toggle
                                            
                                                as={Card.Header}
                                                eventKey={`acc-${key}`}
                                            >
                                                <div dangerouslySetInnerHTML={{ __html: acc.title }} />
                                            </Accordion.Toggle> */}
                                            <Card.Header>
                                                <CustomToggle eventKey={`acc-${key}`}>
                                                    <div dangerouslySetInnerHTML={{ __html: acc.title }} />
                                                    <div className='arrow-icon'><i className="i-arrow next" /></div>
                                                </CustomToggle>

                                            </Card.Header>
                                            <Accordion.Collapse eventKey={`acc-${key}`}>
                                                <Card.Body className={`card-acc-${key}`}>
                                                    <RenderBlocks data={acc.items} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    ))
                                }
                            </Accordion>
                        }
                    </>
            }
            {data.text && <div className='text' dangerouslySetInnerHTML={{ __html: data.text }} />}
        </div>

    )
}

export default TabsAccordion
