//React
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MoreProducts from '../main/ReadMore';
import Badges from '../main/Badges';
import WishList from "../main/ModalWishlist";
import { Button } from 'react-bootstrap';

import CartCtx from '../CartCtx'
import { isIterableArray } from '../block-slider/utils';
import DropdownProductsNewProductCard from './DropdownProductsNewProductCard';

class DropdownProductsNew extends Component {
  static contextType = CartCtx;

  getLink(linkObj) {
    if (typeof linkObj === 'string') {
      if (linkObj.indexOf("https://galaktika.me") !== -1)
        return linkObj.replace("https://galaktika.me/", '/')
      if (linkObj.indexOf("https://avoidrandomusers99.galaktika.me/") !== -1)
        return linkObj.replace("https://avoidrandomusers99.galaktika.me/", '/')
      return '/' + linkObj
    }

    if (linkObj.type === 'link') {
      return linkObj.url
      // if (linkObj.url.indexOf("https://galaktika.me") !== -1)
      //   return linkObj.url.replace("https://galaktika.me/", '/')
      // if (linkObj.url.indexOf("https://avoidrandomusers99.galaktika.me/") !== -1)
      //   return linkObj.url.replace("https://avoidrandomusers99.galaktika.me/", '/')
      // return '/' + linkObj.url
    }

    if (!linkObj || (linkObj.type !== 'prod' && linkObj.type !== 'cat')) return '/'

    if (linkObj.type === 'prod') return '/product/' + linkObj.name_slug
    if (linkObj.type === 'cat' && linkObj.type_cat === 'have_cat') return '/category/' + linkObj.name_slug
    if (linkObj.type === 'cat' && linkObj.type_cat === 'no_have_cat') return '/catalog/' + linkObj.name_slug
  }

  render() {
    const linkObj = this.props.items.link;
    const link = this.props.items.link1;

    const isProductsHasVariable = isIterableArray(this.props.items.prod.filter(item => (item.color !== null)))

    // если есть props.iconUrl то это новый блок с новым маршрутом для иконки
    const iconUrl = this.props.iconUrl ? this.props.iconUrl : `${process.env.REACT_APP_BACKEND}/site_image/blocks_icons/`

    return (
      linkObj ?
        <div className="products-slider" style={{ order: this.props.items.order }}>
          <h2 className="products-slider__title">
            <Link
              to={linkObj.name_slug ? (linkObj.type === "have_cat" ? "/category/" + linkObj.name_slug : "/catalog/" + linkObj.name_slug) : "/"}>
              {/* старый блок с icon_url */}
              {
                this.props.items.icon_url &&
                <img src={`${`${process.env.REACT_APP_BACKEND}/site_image/blocks_icons/`}${this.props.items.icon_url}`} alt="" />
              }
              {/* в новом блоке поле icon */}
              {
                this.props.items.icon &&
                <img src={`${'/blocksnew/'}${this.props.items.icon}`} alt="" />
              }
              <span dangerouslySetInnerHTML={{ __html: this.props.items.name }} />
            </Link>
          </h2>
          <div className="products-dropdown">
            <MoreProducts
              initialHeight={isProductsHasVariable ? 381 : 338}
              overhangSize={110}
              readMore={props => (
                <div className='products-dropdown__btn'>
                  <div className={props.open ? 'open' : ''}
                    onClick={props.onClick}>{props.open ? 'Свернуть' : 'Показать больше'}<i className="i-arrow"></i>
                  </div>
                </div>
              )}
            >
              <div className="products-dropdown__wrap">
                {this.props.items.prod.map((product, index) => {
                  return (
                    <DropdownProductsNewProductCard
                      key={index}
                      product={product}
                      isProductsHasVariable={isProductsHasVariable}
                      context={this.context}
                    />
                    // <div className="products-dropdown__item" key={index}>
                    //   <div className="products__wrap" key={index}>
                    //     <div className="btn-toolbar">
                    //       <WishList productId={product.id} productCount={product.count} />
                    //     </div>
                    //     <div className="products-image">
                    //       <Link className="products-image__link" to={`/product/${product.name_slug}`}>
                    //         <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + product.photo_url}
                    //           alt={("Изображение товара " + product.name)} />
                    //       </Link>
                    //     </div>

                    //     {/* <CatalogColorChar
                    //       character={product.color}
                    //       handleSelectChar={handleSelectChar}
                    //       isLoadingCard={isLoadingCard}
                    //       isProductsHasVariable={isProductsHasVariable}
                    //     /> */}
                    //     <div className="products-colors">
                    //       <div className={("products-colors__wrap " + product.colors)}>
                    //         {/*        <Link to="#" className="products-colors__item active">*/}
                    //         {/*            <span style={{ background: '#3A50AE' }}></span>*/}
                    //         {/*        </Link>*/}
                    //         {/*        <Link to="#" className="products-colors__item">*/}
                    //         {/*            <span style={{ background: '#000' }}></span>*/}
                    //         {/*        </Link>*/}
                    //         {/*        <Link to="#" className="products-colors__item">*/}
                    //         {/*            <span style={{ background: '#ddd' }}></span>*/}
                    //         {/*        </Link>*/}
                    //         {/*        <Link to="#" className="products-colors__item">*/}
                    //         {/*            <span style={{ background: '#E51414' }}></span>*/}
                    //         {/*        </Link>*/}
                    //       </div>
                    //     </div>

                    //     <Link className="products-title" to={`/product/${product.name_slug}`} title={product.name}>
                    //       {product.name}
                    //     </Link>

                    //     {/*<Link to="/SingleProduct" className="products-reviews">*/}
                    //     {/*    /!* Классы no-rating / no-reviews для products-reviews, для добавления иконки, когда нет звездочек *!/*/}
                    //     {/*    <StarRatings*/}
                    //     {/*        rating={product.rating}*/}
                    //     {/*        starDimension="13px"*/}
                    //     {/*        starRatedColor="#FFB800"*/}
                    //     {/*        starSpacing="1px"*/}
                    //     {/*    />*/}
                    //     {/*    <i className="i-reviews"></i>*/}
                    //     {/*    <p className="products-reviews__link">{product.reviews}</p>*/}
                    //     {/*</Link>*/}

                    //     <div className={("products-price " + product.sale + " " + product.stockStatus)}>
                    //       <div className="producst-price__wrap">
                    //         <div className="products-price__old">
                    //           {product.priceBadgeStock.price.discountPrice &&
                    //             <span
                    //               className="price i-rub d-inline-block">{product.priceBadgeStock.price.price.toLocaleString('ru')}</span>
                    //           }
                    //         </div>
                    //         <div className="products-price__main">
                    //           <span className="price i-rub">
                    //             {product.priceBadgeStock.price.discountPrice &&
                    //               product.priceBadgeStock.price.discountPrice.toLocaleString('ru')
                    //             }
                    //             {!product.priceBadgeStock.price.discountPrice &&
                    //               product.price.toLocaleString('ru')
                    //             }

                    //           </span>
                    //         </div>
                    //       </div>
                    //       {process.env.REACT_APP_TEST === 1 &&
                    //         <div className="products-cart-sm">
                    //           {product.count > 0 &&
                    //             <>
                    //               {!this.context.isInCart(product.id) &&
                    //                 <CartCtx.Consumer>
                    //                   {({ cart, addProduct }) => (
                    //                     <Button variant="simple" className="add-to-cart"
                    //                       onClick={() => addProduct(product.id)}><i className="i-cart"></i></Button>
                    //                   )}
                    //                 </CartCtx.Consumer>
                    //               }
                    //               {this.context.isInCart(product.id) &&
                    //                 <CartCtx.Consumer>
                    //                   {({ cart, addProduct }) => (
                    //                     <Button variant="simple" className="add-to-cart added" disabled><i
                    //                       className="i-cart"></i></Button>
                    //                   )}
                    //                 </CartCtx.Consumer>
                    //               }
                    //             </>
                    //           }
                    //         </div>
                    //       }
                    //     </div>

                    //     <div className="products-stock">
                    //       {product.count > 0 &&
                    //         <p className='stock-in'>
                    //           В наличии
                    //         </p>
                    //       }
                    //       {product.count < 1 &&
                    //         <p className='stock-out'>
                    //           Нет в наличии
                    //         </p>
                    //       }
                    //     </div>
                    //     {
                    //       product.priceBadgeStock.bages.length > 0 &&
                    //       <Badges items={product.priceBadgeStock} />
                    //     }

                    //     {product.gift && (
                    //       <Link to={`/product/${product.gift.name_slug}`} className="products-gift contents"
                    //         style={{
                    //           color: '#000',
                    //           textDecoration: 'none',
                    //           background: 'rgba(255,255,255,.5)',
                    //           backdropFilter: 'blur(20px)'
                    //         }}
                    //       >
                    //         <span className="f7 fw500">Подарок</span>
                    //         <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + product.gift.photo_url}
                    //           alt="" className="img" />
                    //       </Link>
                    //     )}

                    //   </div>
                    // </div>
                  )
                }
                )}
                {linkObj.name_slug &&

                  <Link className="products-dropdown__btn to-category"
                    rel={this.props.items?.nofollow ? 'nofollow' : ''}
                    to={linkObj.type === "have_cat" ? "/category/" + linkObj.name_slug : "/catalog/" + linkObj.name_slug}>
                    <div className="">Посмотреть все</div>
                  </Link>
                }
                {link &&
                  <Link
                    rel={this.props.items?.nofollow ? 'nofollow' : ''}
                    className="products-dropdown__btn to-category"
                    to={{ pathname: this.getLink(link) }}
                    target={link?.type === 'link' ? "_blank" : '_self'}
                  >
                    <div className="">
                      Посмотреть все товары

                      <span style={{ display: 'flex' }}>
                        <i className="i-arrow next"
                          style={{
                            display: 'inline-block',
                            transform: 'rotate(-90deg) translateY(-5px)'
                          }}
                        />
                        <i className="i-arrow next"
                          style={{
                            display: 'inline-block',
                            transform: 'rotate(-90deg) translateY(-26px)'
                          }}
                        />
                      </span>
                    </div>
                  </Link>
                }
              </div>
            </MoreProducts>
          </div>
        </div>
        :
        ''
    );
  }
}

export default DropdownProductsNew;
