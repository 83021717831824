// React 
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button, Nav, Spinner } from 'react-bootstrap';

//My components
import HeaderCatalogMobile from "../main/HeaderCatalogMobile";
import logo from "../../img/logo.svg";

//SCSS
import '../../scss/components/main/header.scss';
import axios from "axios";
import HeaderTopMenuMobile from './menus/HeaderTopMenuMobile';
import { isIterableArray } from '../block-slider/utils';
import SelectButton from './centerSelect/SelectButton';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: window.innerHeight,
      menuToggleOn: true,
      isToggleOnMobile: true,
      // items: [],
      goback: false,
      userData: {}
    }
    this.navMenuClick = this.navMenuClick.bind(this);
    this.navCatalogMenuClick = this.navCatalogMenuClick.bind(this);
    this.navCatalogMenuClickBack = this.navCatalogMenuClickBack.bind(this);
    this.closeMenu = this.closeMenu.bind(this)
  }

  closeMenu() {
    document.querySelector('.nav-mobile__overlay').click();
  }

  navMenuClick() {
    document.body.classList.toggle('bfix');
    this.setState(prevState => ({
      menuToggleOn: !prevState.menuToggleOn
    }));
  }

  navCatalogMenuClick() {
    document.body.classList.add('bfix');
    this.setState(prevState => ({
      menuToggleOn: !prevState.menuToggleOn,
      isToggleOnMobile: !prevState.isToggleOnMobile
    }));
  }

  navCatalogMenuClickBack() {
    document.body.classList.remove('bfix');
    this.setState(prevState => ({
      isToggleOnMobile: !prevState.isToggleOnMobile
    }));
  }

  logout(e) {
    e.preventDefault()
    localStorage.removeItem('galaxy-token')
    window.location.href = '/'
    // this.setState({
    //     goback: true
    // })
    // axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/logout`, {
    //     jwt: localStorage.getItem('galaxy-token')
    // }).then(response => {
    //     localStorage.removeItem('galaxy-token')
    //     this.setState({
    //         goback: true
    //     })
    // }).catch(err => {
    //     console.error(err)
    // })
  }

  componentDidMount() {
    // this.setState({
    //   items: this.props.items
    // })
    if (localStorage.getItem('galaxy-token')) {
      axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/profile`, {
        jwt: localStorage.getItem('galaxy-token')
      }).then(response => {
        this.setState({
          userData: response.data.data.info
        })
      }).catch(err => {
        console.error(err)
      })
    }
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.setState({ windowHeight: window.innerHeight })
  }

  render() {
    return (
      <div className='z2'>
        <button className="navbar-toggler" onClick={this.navMenuClick}>
          <div className="navbar-toggler-icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <div className={`nav-mobile ${this.state.menuToggleOn ? '' : 'open'}`}>
          <div className="nav-mobile__overlay" onClick={this.navMenuClick}></div>
          <div className="nav-mobile__wrap">
            <div className="nav-mobile__header flex">
              <div className="header-logo">
                <Link to="/" className="header-logo__link" onClick={this.navMenuClick}>
                  <img src={logo} alt="" />
                </Link>

                <SelectButton
                  handleOpenMainModal={this.props.handleOpenMainModal}
                />

              </div>
              <Button variant="link" className="nav-mobile__close" onClick={this.navMenuClick}><i
                className="i-close"></i></Button>
            </div>
            <div className="catalog__btn">
              <Button className='catalog-btn' onClick={this.navCatalogMenuClick}>
                <h2 style={{ fontSize: 'inherit', margin: 0 }}>
                  Каталог товаров
                </h2>
              </Button>
            </div>
            <div className="nav-mobile__menu">
              {
                localStorage.getItem('galaxy-token') &&
                <ul className="menu-list">
                  <div className="user_info">
                    <div className="avatar">
                      <img src="https://sellik.com/images/default.png" alt="" />
                    </div>
                    {this.state.userData &&
                      <h5>
                        {this.state.userData.name && this.state.userData.name}
                        &nbsp;
                        {this.state.userData.surname && this.state.userData.surname}
                      </h5>
                    }
                  </div>
                  <li className="item" onClick={this.closeMenu}>
                    <NavLink to="/profile" className="nav-link" activeClassName='active'>Профиль</NavLink>
                  </li>
                  <li className="item" onClick={this.closeMenu}>
                    <NavLink to="/profile/orders" className="nav-link" activeClassName='active'>Мои заказы</NavLink>
                  </li>
                  <li className="item" onClick={this.closeMenu}>
                    <NavLink to="/profile/wishlist" className="nav-link" activeClassName='active'>Список желаний</NavLink>
                  </li>
                  <li className="item" onClick={this.closeMenu}>
                    <NavLink to="/profile/waiting-list" className="nav-link" activeClassName='active'>Лист
                      ожидания</NavLink>
                  </li>
                  <li className="item" onClick={this.closeMenu}>
                    <NavLink to="/profile/viewed-products" className="nav-link" activeClassName='active'>Просмотренные
                      товары</NavLink>
                  </li>
                  <li className="item" onClick={this.closeMenu}>
                    <NavLink to='#' onClick={this.logout}>Выйти</NavLink>
                  </li>
                </ul>
              }

              {
                // this.props.headerData &&
                <HeaderTopMenuMobile
                  headerData={this.props.headerData}
                  footerData={this.props.footerData}
                  closeMenu={this.closeMenu}
                  navMenuClick={this.navMenuClick}
                />
              }

              <div className="social">
                <p className='text-muted'>
                  {
                    this.props.footerData?.type8?.text_socials
                  }
                </p>
                <div className="social-list">
                  {
                    isIterableArray(this.props.footerData?.type8?.socials) &&
                    this.props.footerData.type8.socials.map((item, key) => (
                      <div className="social-list__item" key={key}>
                        <Nav.Link href={item.url} target="_blank">
                          <img src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + item.icon} alt={item.alt} />
                        </Nav.Link>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="catalog-menu">
          <div className={`header-catalog header-catalog__mobile ${this.state.isToggleOnMobile ? '' : 'open'}`}
            style={{ height: this.state.windowHeight }}>

            <div className="menu__hidden-content__header">
              <Button id={'menu-btn'} variant="link" className="hCatalog_m_toggle"
                onClick={this.navCatalogMenuClickBack}><i className="i-arrow"></i>Каталог товаров</Button>
            </div>
            {
              isIterableArray(this.props.items) ?
                <HeaderCatalogMobile items={this.props.items} />
                :
                <div className='d-flex justify-content-center py-5 '>
                  <Spinner animation='border' style={{ borderColor: "#bf2362", borderRightColor: 'transparent' }} />
                </div>
            }
          </div>
        </div>
        {/* <div className={`overlay_main_m ${this.state.isToggleOnMobile ? '' : 'open'}`}
          onClick={this.navCatalogMenuClickBack}></div> */}
      </div>
    )
  }
}

export default Header;
