import React, { useContext } from 'react'
import { Accordion, AccordionContext, Container, Nav, useAccordionToggle } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { isIterableArray } from '../../block-slider/utils'

import { ReactComponent as IconHome } from '../../../img/i-home.svg'
import iconDiscount from '../../../img/slider-discount.svg'
import { getIsFollow, HeaderTopMenuLink, MenuImages } from './linkUtils'

const HeaderTopMenuMobile = ({ headerData, footerData, closeMenu, navMenuClick }) => {

    function CustomToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <div
                onClick={decoratedOnClick}
                className={`menu-item__btn ${isCurrentEventKey ? "" : 'rotated'}`}
            >
                {children}
            </div>
        );
    }


    return (
        <div className='header-top-menu-mobile'>
            <div className='header-top-menu-mobile__content'>

                <div className="home-link"  >
                    <Link className="nav-link" to="/" onClick={() => {
                        navMenuClick()
                        closeMenu()
                    }}
                    >
                        <IconHome />
                        Главная
                    </Link>
                </div>
                {
                    headerData?.stock &&
                    <div className="menu-item">
                        <div className="menu-item__btn">
                            <Link to="/promo" className="nav-link stock-link" onClick={closeMenu}>
                                <img className="header-top-menu__icon stock-icon" src={iconDiscount} />
                                {
                                    headerData.stock?.item?.name
                                }
                            </Link>
                        </div>
                        <div className="menu-item__wrap">
                            <div className="menu-item__inner">
                                {
                                    isIterableArray(headerData.stock.subItems) && headerData.stock.subItems.map(item => (
                                        <Link
                                            key={item.id}
                                            {...getIsFollow(item.link.follow)}
                                            target={item.link.notGalaxy ? "_blank" : '_self'}
                                            to={{ pathname: item.link.url }}
                                            onClick={closeMenu}
                                        >
                                            {
                                                item.icon &&
                                                <img className="header-top-menu__icon stock-icon" src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + item.icon} />
                                            }
                                            {item.name}
                                        </Link>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                }

                {/* тут мы показываем только первый элемент из массива картинок-меню */}
                {
                    isIterableArray(footerData?.type2) &&
                    <MenuImages items={footerData?.type2.slice(0, 1)} />
                }
                {/* // активный ключ только для первых двух элементов  */}
                <Accordion
                    // defaultActiveKey={key > 1 ? 'nokey' : `menu-mobile-${key}`}
                    defaultActiveKey={`menu-mobile-0`}
                // className={key > 0 ? 'nokey' : `${key}`}
                >
                    {
                        isIterableArray(headerData?.items) &&
                        headerData.items.map((item, key) => (
                            <div className="menu-item" key={key} >
                                {/* <div className="menu-item__btn"> */}
                                <CustomToggle eventKey={`menu-mobile-${key}`}>
                                    <div >
                                        {
                                            item.icon &&
                                            <img className="header-top-menu__icon" src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + item.icon} />
                                        }
                                        {
                                            item.name
                                        }
                                    </div>
                                    <div className='arrow-icon'><i className="i-arrow next" /></div>
                                </CustomToggle>
                                {/* </div> */}

                                <Accordion.Collapse eventKey={`menu-mobile-${key}`}>
                                    <div className="menu-item__wrap">
                                        <div className="menu-item__inner">
                                            {
                                                isIterableArray(item.subItems) && item.subItems.map(item => (
                                                    <HeaderTopMenuLink key={item.id} itemLink={item} onClick={closeMenu} />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </Accordion.Collapse>
                            </div>
                        ))
                    }

                </Accordion>

                {/* тут мы показываем остальные элементы из массива картинок-меню (кроме первого) */}
                {
                    isIterableArray(footerData?.type2) &&
                    <MenuImages items={footerData?.type2.slice(1)} />
                }

            </div>
        </div>
    )
}

export default HeaderTopMenuMobile
