import React from "react";

import BlockSliderType1 from "./BlockSliderType1";
import BlockSliderType2 from "./BlockSliderType2";
import DropdownProductsNew from "../main/DropdownProductsNew";

import { isIterableArray } from "./utils";

import "./css/type1-blocks.css";
import "./css/type2-blocks.css";

const SliderWrapper = (props) => {

  const { data } = props

  return (
    <div className="slider-page-container">
      {
        isIterableArray(data) &&
        data.map((item, index) => {
          if (item.type_blocks === 1) return (
            <BlockSliderType1 data={item} key={index} />
          )
          if (item.type_blocks === 2) return (
            item.type === 3 ?
              <DropdownProductsNew items={item} iconUrl={'/blocksnew/'} key={index} />
              :
              <BlockSliderType2 data={item} key={index} />
          )
          if (item.type_blocks === 3) return (
            <DropdownProductsNew items={item} iconUrl={'/blocksnew/'} key={index} />
          )
        })
      }
    </div>
  );
};

export default SliderWrapper;
