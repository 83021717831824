import React, { useState, useEffect } from 'react'

import { isIterableArray } from '../../../block-slider/utils'
import CharListCommon from '../CharListCommon';


// const OFFSET_FOR_HIDE = [45, 65]

const ProductChartList = ({ character, handleSelectChar, isLoadingCard, openInfoModal }) => {

    const [offsetForHide, setOffsetForHide] = useState(0);

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        };
    }, []);

    const handleResize = () => {
        const windowWidth = window.innerWidth
        // console.log(windowWidth)
        if (windowWidth > 992) {
            setOffsetForHide(45)
        }
        else {
            setOffsetForHide(65)
        }
    }

    return (

        <div className='char-list product'>
            {
                isIterableArray(character) && character.map((char_el, key) => (
                    <CharListCommon
                        openInfoModal={openInfoModal}
                        handleSelectChar={handleSelectChar}
                        isLoadingCard={isLoadingCard}
                        key={key}
                        char_el={char_el}
                        offsetForHide={offsetForHide}
                    />
                ))
            }
        </div>
    )
}

export default ProductChartList