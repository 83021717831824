import React, { useEffect, useRef, useState } from "react";

import ProductSlider from "./blocks-type2/ProductSlider";
import BlocksCountdown from "./blocks-type2/BlocksCountdown";
import BlocksInfo from "./blocks-type2/BlocksInfo";
import { slidersIntervals } from "./utils";
import "@aarsteinmedia/dotlottie-player";


const BlockSliderType2 = ({ data }) => {


  const playerRef = useRef(null)
  const [animationURL, setAnimationURL] = useState(null);

  useEffect(() => {
    if (data?.animation_background) handleLottieAnimationLoad()
  }, [data]);

  const handleLottieAnimationLoad = () => {
    const lottieFileURL = data?.animation_background ? `${'/blocksnew/'}${data.animation_background.url}` : null;
    setAnimationURL(lottieFileURL);
  }

  useEffect(() => {
    if (playerRef.current && animationURL) {
      waitForLottieAnimationLoaded().catch((err) => {
        console.log('lottie err', err)
        setAnimationURL(null)
      })
    }
  }, [playerRef.current, animationURL]);

  const waitForLottieAnimationLoaded = () => {
    return new Promise(() => {
      if (!playerRef?.current?.getLottie()) {
        throw new Error('animation error');
      }
    });
  }

  const isBg =
    (!!data.picture && data.picture !== null && data.picture !== 'null' && data.picture !== 'none' && data.picture !== "transparent")
    ||
    (!!data.background && data.background !== null && data.background !== 'null' && data.background !== 'none' && data.background !== "transparent")
    ||
    (!!data.background_gradient && data.background_gradient !== null && data.background_gradient !== 'null' && data.background_gradient !== 'none')


  const sliderFullscreen = [
    {
      breakpoint: 4000,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 8,
      },
    },
    {
      breakpoint: 2200,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 7,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      },
    },
    {
      breakpoint: 1450,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 1260,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ]
  const sliderWithBlock = [
    {
      breakpoint: 2200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ]
  return (
    data ?
      <div className={`blocks-wrapper ${isBg ? `${data.background}-${data.background_gradient}` : 'without-bg'}`}>
        <div
          className="blocks-wrapper__bg-layer"
          style={{
            backgroundColor: data.background,
            backgroundImage: data.background_gradient ? data.background_gradient : 'none',
            zIndex: 0,
          }}
        />
        {
          data.picture &&
          <div
            className="blocks-wrapper__bg-layer bg-picture-right-mobile"
            style={{
              // backgroundImage: `url(${process.env.REACT_APP_BACKEND}/files/blocksnew/${data.picture})`,
              backgroundImage: `url(${'/blocksnew/'}${data.picture})`,
              // backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              zIndex: 1,

              backgroundPosition: 'right top',
              // backgroundSize: 'inherit'
            }}
          />
        }
        <div
          className="blocks-wrapper__animation-layer"
          style={{ zIndex: 2 }}
        >
          {
            data.animation_background &&
            <dotlottie-player
              ref={playerRef}
              src={animationURL}
              background="transparent"
              speed={data.animation_background.speed}
              direction="1"
              mode="normal"
              loop
              autoplay
            ></dotlottie-player>
          }
        </div>
        {
          (data.icon || data.name) ?
            <h2 className="products-slider__title">
              {
                data.icon &&
                <>
                  <img
                    src={'/blocksnew/' + data.icon}
                    alt=""
                  />
                  {" "}
                </>
              }
              <span dangerouslySetInnerHTML={{ __html: data.name }} />
            </h2>
            :
            ''
        }

        <div className="blocks-slider__content">
          <div className={`slick-wrapper ${data.type === 1 ? 'w-100' : ''}`}>
            <ProductSlider
              linkViewAll={data.link1}
              products={data.prod}
              id={data.id}
              animation_product={data.animation_product}
              sliderConfig={{
                responsive: data.type === 1 ? sliderFullscreen : sliderWithBlock,
                autoplay: data.autoscroll,
                autoplaySpeed: data.autoscroll ? slidersIntervals.autoscroll : false, // e.g. 5000
              }} />
          </div>
          {
            // {data.type_info === 1 ? (
            data.type_info === 1 ?
              <BlocksCountdown info={data.info} block_info={data.block_info} url_gif={data.url_gif} />
              :
              data.type_info === 2 ?
                <BlocksInfo info={data.info} block_info={data.block_info} url_gif={data.url_gif} />
                :
                ''
          }
        </div>
      </div>
      :
      ''
  );
};

export default BlockSliderType2;
