import React from 'react'


// тип 2
const Type2 = ({ data }) => {
    return (
        data ?
            <div className='buildedPage-type_2'>
                {data.title && <div className='title' dangerouslySetInnerHTML={{ __html: data.title }} />}
                {data.text && <div className='text' dangerouslySetInnerHTML={{ __html: data.text }} />}
            </div>

            :
            ''
    )
}

export default Type2
