import React from 'react'
import { Breadcrumb, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AnimationContainer from './components/AnimationContainer'
import Journal from "./components/Journal";

const PromoTypeNewspaper = (props) => {

    const { background_lottie, newspapers, bottom_description } = props
    // newspapers = {
    //     id: '',
    //     pages: ''
    // }


    return (
        <div className="promo-content promo-newspaper-page">
            <Container>
                <Breadcrumb style={{ position: 'relative', zIndex: 1 }}>
                    <li className={'breadcrumb-item'}>
                        <Link to={`/`}>
                            <i className="i-home" />
                        </Link>
                    </li>
                    <li className={'breadcrumb-item'}>
                        <Link to={`/promo`}>
                            Все акции
                        </Link>
                    </li>
                    <li className={'breadcrumb-item'}>
                        {newspapers?.name}
                    </li>
                </Breadcrumb>
                <h1 className='page-title'>
                    {newspapers?.name}
                </h1>
                <AnimationContainer animation_background={background_lottie}>
                    {
                        newspapers &&
                        <div className='d-flex flex-center justify-content-center'>
                            <Journal
                                close={false}
                                id={newspapers.id}
                                count={newspapers.pages}
                                marginTop={'0px'}
                            />
                        </div>
                    }
                </AnimationContainer>
                <div className="promo-inner">
                    <div className="cat-desc">
                        {bottom_description && bottom_description !== 'null' && (
                            <div className="cat-desc__wrap"
                                dangerouslySetInnerHTML={{ __html: bottom_description }} />
                        )}
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default PromoTypeNewspaper
