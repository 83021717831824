// React
import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel'
import { Link } from 'react-router-dom';

//SCSS
import '../../scss/components/pages/home2/sliderLine1.scss';

class SliderCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interval: 4000,
            nextIcon: <span className="arrow-icon next"></span>,
            prevIcon: <span className="arrow-icon prev"></span>
        }
    }

    handleSelect = (selectedIndex, e) => {
        this.setState({
            index: selectedIndex,
            direction: e.direction
        });
    }

    getRelTag(tags) {
        if (tags?.follow === 'yes') return {
            rel: 'follow'
        }
        if (tags?.follow === 'no') return {
            rel: 'nofollow'
        }
        if (tags?.follow === 'null') return {}
    }

    render() {
        const { nextIcon, prevIcon, interval } = this.state;

        return (

            <Carousel id='sliderCategory' nextIcon={nextIcon} prevIcon={prevIcon} interval={interval} >
                {this.props.items.map((banner, index) => {
                    return (
                        <Carousel.Item key={index}>
                            {
                                banner.baner_link ?
                                    <Link
                                        to={{ pathname: banner.baner_link.url }}
                                        target="_blank"
                                        // href={banner.baner_link.url}
                                        {...this.getRelTag(banner.baner_link)}
                                    >
                                        <img src={process.env.REACT_APP_BACKEND + 'uploads/categories_banners/' + banner.baner_url} alt={banner.name} />
                                    </Link>
                                    :
                                    <img src={process.env.REACT_APP_BACKEND + 'uploads/categories_banners/' + banner.baner_url} alt={banner.name} />
                            }

                        </Carousel.Item>
                    )
                })}
            </Carousel>
        )
    }
}

export default SliderCategory;
