import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import { isIterableArray } from '../block-slider/utils';
import SideMenu from '../builderPage/SideMenu';
import './vacancy.scss'
import SkeletonVacancy from './SkeletonVacancy';
import { Breadcrumb, Spinner } from 'react-bootstrap';

import { ReactComponent as IconSearch } from '../../img/i-search.svg'
import RenderBlocks from '../builderPage/blocks/RenderBlocks';
import OnceVacancy from './OnceVacancy';
import SkeletonMenu from '../builderPage/SkeletonMenu';
import { setMetaTags } from '../builderPage/BuilderPage';
import { getIsFollow } from '../main/menus/linkUtils';

const VacancyPage = () => {

    const history = useHistory()
    const { vacancy_slug } = useParams()


    const [isLoading, setIsLoading] = useState(true);

    const [isLoadingVacancy, setIsLoadingVacancy] = useState(false);
    // const [isShowVacancy, setIsShowVacancy] = useState(false); // если true - то показываем описание вакансии вместо списка
    const [vacancyData, setVacancyData] = useState(null);

    const [data, setData] = useState(null); // данные для списка вакансий  

    const [listData, setListData] = useState([]);
    const [menuData, setMenuData] = useState(null) // данные для сайдбара

    const [blocksData, setBlocksData] = useState([]); // данные для блоков из конструктора
    const [bannerSrc, setBannerSrc] = useState(null); // блок-баннер картинка вверху

    const [searchValue, setSearchValue] = useState('');
    const [selectedCityId, setSelectedCityId] = useState(0);
    const [selectedDirectionId, setSelectedDirectionId] = useState(0);

    const [selectedPage, setSelectedPage] = useState(1);

    const [pagination, setPagination] = useState({});


    const [isShowVacancy, setIsShowVacancy] = useState(!!vacancy_slug); // если true - то показываем описание вакансии вместо списка

    useEffect(() => {
        setIsShowVacancy(!!vacancy_slug)
    }, [vacancy_slug]);

    useEffect(() => {
        if (isShowVacancy) {
            fetchDataVacancy()
        }
        else {
            fetchDataList()
        }
    }, [isShowVacancy, selectedCityId, selectedDirectionId, selectedPage]);




    const fetchDataList = (newForm = {}) => {

        setBlocksData([])

        setIsShowVacancy(false)
        setVacancyData(null)

        // setData(null) 

        const form = {
            str: searchValue,
            page: selectedPage, // || 1 - если первый раз посещаем страницу или выбрана 1 страница
            cityId: Number(selectedCityId), // || 0 - если выбраны все города или первый раз посещаем страницу
            directionId: Number(selectedDirectionId) // || 0 - если выбраны все направления или первый раз посещаем страницу
        }

        const sendForm = { ...form, ...newForm }

        console.log('fetch!', sendForm)

        setIsLoading(true)
        axios.post(`${process.env.REACT_APP_API_BASE}/pages/vacancy-filter`, sendForm)
            .then((response) => {
                console.log('vacancy data response', response)

                if (response.data.res.meta_tags) setMetaTags(response.data.res.meta_tags)

                setListData(response.data.res.items)
                setData(response.data.res)
                setPagination(response.data.res.pages)
                if (response.data?.res?.sidebar) setMenuData(response.data.res.sidebar)

                if (response.data?.res?.content?.[0]?.blocks?.type === 1) {
                    // проверяю чтобы в первом блоке был тип 1 (Картинка)
                    if (isIterableArray(response.data.res.content[0]?.blocks?.images)) setBannerSrc(response.data.res?.content?.[0]?.blocks?.images[0].src)

                    const newArr = response.data?.res?.content.slice(1, response.data?.res?.content.length)
                    // const shifted = newArr.shift() 
                    setBlocksData(newArr)
                }
                else {
                    setBannerSrc(null)
                    setBlocksData(response.data?.res?.content)
                }

            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const fetchDataVacancy = () => {

        setBlocksData([])

        setIsShowVacancy(true)
        setIsLoadingVacancy(true)

        axios.get(`${process.env.REACT_APP_API_BASE}/pages/vacancy-by-slug/${vacancy_slug}`)
            .then((response) => {
                console.log('once vacancy data response', response)

                if (response.data.item.meta_tags) setMetaTags(response.data.item.meta_tags)

                setVacancyData(response.data.item)
                setData(response.data.item)

                if (response.data.item.sidebar) setMenuData(response.data.item.sidebar)


                if (response.data?.item?.content?.[0]?.blocks?.type === 1) {
                    // проверяю чтобы в первом блоке был тип 1 (Картинка)
                    if (isIterableArray(response.data.item.content[0]?.blocks?.images)) setBannerSrc(response.data.item?.content?.[0]?.blocks?.images[0].src)

                    const newArr = response.data?.item?.content.slice(1, response.data?.item?.content.length)
                    // const shifted = newArr.shift() 
                    setBlocksData(newArr)
                }
                else {
                    setBannerSrc(null)
                    setBlocksData(response.data?.item?.content)
                }


            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setIsLoadingVacancy(false)
                setIsLoading(false)
            })
    }

    const changePage = num => {

        setSelectedPage(num)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

    }

    return (
        <div className='vacancy-page'>
            {
                bannerSrc &&
                <div
                    className='vacancy-page__banner'
                    style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND + '/uploads/pages/blocks/' + bannerSrc})` }}
                />
            }

            <div className={`${bannerSrc ? 'withBanner' : ''} vacancy-page__row buildedPage-container `}>

                <div className='vacancy-page__row-aside buildedPage-container-sidemenu'>

                    <Breadcrumb>
                        <Breadcrumb.Item href={process.env.REACT_APP_CLIENT}><i className="i-home"></i></Breadcrumb.Item>

                        <Breadcrumb.Item href="/info">
                            {'Информация'}
                        </Breadcrumb.Item>

                        {
                            vacancyData ?
                                <>
                                    <li className='breadcrumb-item'>
                                        <Link to='/vacancy'>
                                            {'Вакансии'}
                                        </Link>
                                    </li>
                                    <li className='breadcrumb-item'>
                                        {vacancyData.name}
                                    </li>
                                </>
                                :
                                <li className='breadcrumb-item'>
                                    Вакансии
                                </li>
                        }
                    </Breadcrumb>

                    {
                        menuData ?
                            <SideMenu menuData={menuData} />
                            :
                            <SkeletonMenu />
                    }
                </div>
                {
                    data &&
                    <div className={`${bannerSrc ? '' : 'mt-3'} vacancy-page__row-content buildedPage-container-content`}>

                        <div className='vacancy-page__row-content--header'>
                            <h1>
                                {data?.title}
                            </h1>
                            <div className='search'>
                                <div className='search-input'>
                                    <input

                                        name='search'
                                        className='form-control'
                                        placeholder='Поиск вакансий'
                                        value={searchValue}
                                        onChange={(e) => { setSearchValue(e.target.value) }}
                                        onKeyUp={e => {
                                            if (e.keyCode === 13) {
                                                history.push('/vacancy')
                                                fetchDataList()
                                            }
                                        }}
                                    />
                                    <button
                                        className='clear-btn'
                                        disabled={searchValue.length === 0}
                                        onClick={() => {
                                            setSearchValue('')
                                            fetchDataList({ str: '' })
                                        }}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <button
                                    className='search-btn btn btn-primary '
                                    onClick={() => {
                                        history.push('/vacancy')
                                        fetchDataList()
                                    }}
                                    disabled={isLoading}
                                >
                                    {
                                        isLoading ?
                                            <Spinner animation='border' variant='secondary' size='sm' />
                                            :
                                            <IconSearch />
                                    }
                                </button>
                            </div>
                            <div className='row'>
                                <div className='col-6'>

                                    <div className="catalog-view__sort">
                                        <select
                                            disabled={isLoading}
                                            className="select-custom"
                                            placeholder='Выберите город'
                                            value={selectedCityId}
                                            onChange={e => {
                                                history.push('/vacancy')
                                                setIsShowVacancy(false)
                                                setSelectedPage(1)
                                                setSelectedCityId(e.target.value)
                                            }}
                                        >
                                            {
                                                isIterableArray(data?.cities) &&
                                                data.cities.map(city => (
                                                    <option key={city.id} value={city.id}>{city.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <select
                                        disabled={isLoading}
                                        className="select-custom"
                                        placeholder='Выберите направление'
                                        value={selectedDirectionId}
                                        onChange={e => {
                                            history.push('/vacancy')
                                            setIsShowVacancy(false)
                                            setSelectedPage(1)
                                            setSelectedDirectionId(e.target.value)
                                        }}
                                    >
                                        {
                                            isIterableArray(data?.direction) &&
                                            data.direction.map(dir => (
                                                <option key={dir.id} value={dir.id}>{dir.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {
                                vacancyData &&
                                <NavLink to='/vacancy' className={'link-to-back'} onClick={() => {
                                    setIsLoading(true)
                                }}>
                                    <i className='icon-arrow-left2 mr-2' />
                                    Назад к списку
                                </NavLink>
                            }
                        </div>
                        {
                            isShowVacancy ?
                                isLoadingVacancy ?
                                    <div className='loading-vacancy'>
                                        <Spinner animation='border' />
                                    </div>
                                    :
                                    <OnceVacancy data={vacancyData} />
                                :
                                <div className='vacancy-page__row-content--list'>
                                    {
                                        (isLoading && !isIterableArray(data?.items)) ?
                                            <SkeletonVacancy />
                                            :
                                            isIterableArray(data?.items) ?
                                                <>
                                                    <div style={{
                                                        opacity: isLoading ? .4 : 1,
                                                        pointerEvents: isLoading ? 'none' : 'all'
                                                    }}>
                                                        {
                                                            data.items.map(item => (
                                                                <NavLink
                                                                    key={item.id}
                                                                    {...getIsFollow(item?.meta_tags?.robots_follow)}
                                                                    to={`/vacancy/${item.slug}`}
                                                                    className={`vacancy-item ${item.important ? 'important' : ''}`}
                                                                    onClick={() => {
                                                                        setIsLoadingVacancy(true)
                                                                    }}
                                                                >

                                                                    {
                                                                        item.important ?
                                                                            // <img src={iconFire} className='d-sm-none' />
                                                                            <img
                                                                                className='d-sm-none'
                                                                                src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + data.important_icon}
                                                                                alt=''
                                                                            />
                                                                            : ''
                                                                    }
                                                                    <div className='text-content'>
                                                                        <div className='left-block'>
                                                                            {
                                                                                item.important ?
                                                                                    // <img src={iconFire} className='d-none d-sm-block' />
                                                                                    <img
                                                                                        className='d-none d-sm-block'
                                                                                        src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + data.important_icon}
                                                                                        alt=''
                                                                                    />
                                                                                    : ''
                                                                            }
                                                                            <div className='left-block-text'>
                                                                                <span className='address'>
                                                                                    {
                                                                                        item.address
                                                                                    }
                                                                                </span>
                                                                                {
                                                                                    item.date ?
                                                                                        <span className='text-muted'>
                                                                                            от {item.date}
                                                                                        </span>
                                                                                        :
                                                                                        ''
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='name-block'>
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        data.text_link &&
                                                                        <div className='text-link'>
                                                                            {data.text_link}
                                                                        </div>
                                                                    }
                                                                </NavLink>
                                                            ))
                                                        }
                                                    </div>


                                                    <div className="catalog-list__navigation" style={{
                                                        transition: 'opacity .4s ease',
                                                        opacity: isLoading ? 0 : 1,
                                                        pointerEvents: isLoading ? 'none' : 'auto'
                                                    }}>
                                                        {
                                                            pagination.lastPage > 1 &&
                                                            <div className="pagination">
                                                                <ul className="pagination__wrap">
                                                                    <ul className="pag_arrows">
                                                                        <li className="pagination__item pag_arrows__item bt_pprev">
                                                                            {pagination.page === 1 &&
                                                                                <span style={{ opacity: .15 }}>
                                                                                    <i className="i-arrow prev" style={{
                                                                                        display: 'inline-block',
                                                                                        transform: 'rotate(90deg) translateY(-14px)'
                                                                                    }}></i>
                                                                                    <i className="i-arrow prev"
                                                                                        style={{ display: 'inline-block' }}></i>
                                                                                </span>
                                                                            }
                                                                            {pagination.page !== 1 &&
                                                                                <Link className={`link`} to={`/vacancy`} onClick={() => {
                                                                                    changePage(1)
                                                                                }}>
                                                                                    <i className="i-arrow prev"
                                                                                        style={{ display: 'inline-block', transform: 'rotate(90deg) translateY(-14px)' }}></i>
                                                                                    <i className="i-arrow prev" style={{ display: 'inline-block' }}></i>
                                                                                </Link>
                                                                            }
                                                                        </li>
                                                                        <li className="pagination__item pag_arrows__item bt_prev">
                                                                            {pagination.page === 1 &&
                                                                                <span style={{ opacity: .15 }}>
                                                                                    <i className="i-arrow prev"><span></span></i>
                                                                                </span>
                                                                            }
                                                                            {pagination.page !== 1 &&
                                                                                <Link className={`link`} to={`/vacancy`} onClick={() => {
                                                                                    changePage(pagination.page > 1 ? pagination.page - 1 : 1)
                                                                                }}>
                                                                                    <i className="i-arrow prev"><span></span></i>
                                                                                </Link>
                                                                            }
                                                                        </li>
                                                                    </ul>
                                                                    <ul className="pag_list">
                                                                        {pagination.page > 2 && pagination.lastPage > 3 &&
                                                                            <li className="pagination__item">
                                                                                <Link className="link" to={`/vacancy`} onClick={() => {
                                                                                    changePage(1)
                                                                                }}>
                                                                                    <b>1</b>
                                                                                </Link>
                                                                            </li>
                                                                        }

                                                                        {pagination.page > 3 && pagination.lastPage > 4 &&
                                                                            <li className="pagination__item"><b>...</b></li>
                                                                        }


                                                                        {pagination.page === pagination.lastPage && pagination.lastPage > 2 &&
                                                                            <li className="pagination__item">
                                                                                <Link className="link" to={`/vacancy`} onClick={() => {
                                                                                    changePage(pagination.page - 2)
                                                                                }}>
                                                                                    <b>{pagination.page * 1 - 2}</b>
                                                                                </Link>
                                                                            </li>
                                                                        }
                                                                        {pagination.page > 1 &&
                                                                            <li className="pagination__item">
                                                                                <Link className="link" to={`/vacancy`} onClick={() => {
                                                                                    changePage(pagination.page * 1 - 1)
                                                                                }}>
                                                                                    <b>{pagination.page * 1 - 1}</b>
                                                                                </Link>
                                                                            </li>
                                                                        }
                                                                        <li className="pagination__item active">
                                                                            <span className="link">
                                                                                <b>{pagination.page}</b>
                                                                            </span>
                                                                        </li>
                                                                        {pagination.page < pagination.lastPage &&
                                                                            <li className="pagination__item">
                                                                                <Link className="link" to={`/vacancy`} onClick={() => {
                                                                                    changePage(pagination.page * 1 + 1)
                                                                                }}>
                                                                                    <b>{pagination.page * 1 + 1}</b>
                                                                                </Link>
                                                                            </li>
                                                                        }
                                                                        {pagination.page === 1 && pagination.lastPage > 2 &&
                                                                            <li className="pagination__item">
                                                                                <Link className="link" to={`/vacancy`} onClick={() => {
                                                                                    changePage(pagination.page * 1 + 2)
                                                                                }}>
                                                                                    <b>{pagination.page * 1 + 2}</b>
                                                                                </Link>
                                                                            </li>
                                                                        }

                                                                        {pagination.page < pagination.lastPage * 1 - 2 && pagination.lastPage > 4 &&
                                                                            <li className="pagination__item"><b>...</b></li>
                                                                        }

                                                                        {pagination.page < pagination.lastPage * 1 - 1 && pagination.lastPage > 3 &&
                                                                            <li className="pagination__item">
                                                                                <Link className="link" to={`/vacancy`} onClick={() => {
                                                                                    changePage(pagination.lastPage)
                                                                                }}>
                                                                                    <b>{pagination.lastPage}</b>
                                                                                </Link>
                                                                            </li>
                                                                        }

                                                                    </ul>
                                                                    <ul className="pag_arrows">
                                                                        <li className="pagination__item pag_arrows__item bt_next">
                                                                            {pagination.page !== pagination.lastPage &&
                                                                                <Link className={`link`} to={`/vacancy`} onClick={() => {
                                                                                    changePage(pagination.page < pagination.lastPage ? pagination.page * 1 + 1 : pagination.lastPage)
                                                                                }}>
                                                                                    <i className="i-arrow next"><span></span></i>
                                                                                </Link>
                                                                            }
                                                                            {pagination.page === pagination.lastPage &&
                                                                                <span className={`link`} style={{ opacity: .15 }}>
                                                                                    <i className="i-arrow next"><span></span></i>
                                                                                </span>
                                                                            }
                                                                        </li>
                                                                        <li className="pagination__item pag_arrows__item bt_nnext">
                                                                            {pagination.page !== pagination.lastPage &&
                                                                                <Link className={`link`} to={`/vacancy`} onClick={() => {
                                                                                    changePage(pagination.lastPage)
                                                                                }}>
                                                                                    <i className="i-arrow next" style={{ display: 'inline-block' }}></i>
                                                                                    <i className="i-arrow next"
                                                                                        style={{ display: 'inline-block', transform: 'rotate(-90deg) translateY(-14px)' }}></i>
                                                                                </Link>
                                                                            }
                                                                            {pagination.page === pagination.lastPage &&
                                                                                <span className={`link`} style={{ opacity: .15 }}>
                                                                                    <i className="i-arrow next"
                                                                                        style={{ display: 'inline-block' }}></i>
                                                                                    <i className="i-arrow next" style={{
                                                                                        display: 'inline-block',
                                                                                        transform: 'rotate(-90deg) translateY(-14px)'
                                                                                    }}></i>
                                                                                </span>
                                                                            }
                                                                        </li>
                                                                    </ul>
                                                                </ul>
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <div className='empty-search-text'>
                                                    Вакансии не найдены
                                                </div>
                                    }
                                </div>
                        }

                        <RenderBlocks data={blocksData} />
                    </div>
                }
            </div>

        </div>
    )
}

export default VacancyPage
