import React, { useEffect, useState } from "react";
import "@aarsteinmedia/dotlottie-player";
import { Link } from "react-router-dom";
import Badges from '../../main/Badges'
import { useRef } from "react";
import { pathToLottieAnimation } from "../utils";

const SmallBlock = ({ data, url_list }) => {
  const animationBg = data.animation ? data.animation.background : 0; // 0 

  const playerRef = useRef(null)
  const [animationURL, setAnimationURL] = useState(null);

  useEffect(() => {
    if (data?.animation) handleLottieAnimationLoad()
  }, [data?.animation]);

  const handleLottieAnimationLoad = () => {
    // const lottieFileURL = data?.animation ? `/lottie/${data.animation.url}` : null;
    const lottieFileURL = data?.animation ? `${pathToLottieAnimation}${data.animation.url}` : null;
    setAnimationURL(lottieFileURL);
  }

  useEffect(() => {
    if (playerRef.current) {
      waitForLottieAnimationLoaded().catch((err) => {
        setAnimationURL(null)
      })
    }
  }, [playerRef.current]);

  const waitForLottieAnimationLoaded = () => {
    return new Promise(() => {
      if (!playerRef?.current?.getLottie()) {
        throw new Error('animation error');
      }
    });
  }


  return (
    <Link to={data.link ? data.link.replace("https://galaktika.me", "") : '#'} className="small-block">
      {data.picture && <img className="small-block__bg" src={url_list?.picture + data.picture} alt={data.alt} />}
      {data.gif && <img className="small-block__gif" src={url_list?.gif + data.gif} alt="" />}
      <div className="small-block__overlay" style={{ backgroundColor: data.background ? data.background : 'inherit' }}>
        <div className={`block7-animation  ${animationBg === 0 ? "fg" : "bg"}`}>
          {
            data.animation && animationURL !== null &&
            <dotlottie-player
              ref={playerRef}
              src={animationURL}
              background="transparent"
              speed={data.animation.speed}
              direction="1"
              mode="normal"
              loop
              autoplay
            ></dotlottie-player>
          }
        </div>
        <h3 className={`small-block__category ${data.plashka ? 'with-bg' : ''}`} >
          {data.text}
        </h3>
        {
          data.badges &&
          <div className="block-badges">
            <Badges items={{ bages: data.badges }} />
          </div>
        } 
      </div>
    </Link>
  );
};

export default SmallBlock;
